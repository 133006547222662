// sessions-api/page-view/count

import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useVisits = (enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useVisits`,
    url: `${params.API_URL}/sessions-api/page-view/count`,
    method: "GET",
    enabled,
  });
};
