export enum ESessionStorage {
  FIRST_TOPIC = "FIRST_TOPIC",
}

export function saveToSessionStorage(key: ESessionStorage, value: string) {
  sessionStorage.setItem(key, value);
}

export function fetchFromSessionStorage(key: ESessionStorage): string | null {
  return sessionStorage.getItem(key);
}
