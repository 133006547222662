import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useGetTicket = (ticketId: string, enabled: boolean = true) => {
  return useAuthorizedQuery({
    queryKey: "ticket",
    url: `${params.API_URL}/platform/dashboard/tickets/${ticketId}`,
    method: "GET",
    enabled,
  });
};
