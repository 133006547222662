import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useCommonQuestions = (topicId: string, enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: "common-questions" + topicId,
    url: `${params.API_URL}/platform/auto-activate/dashboard?topicId=${topicId}`,
    method: "GET",
    enabled,
  });
};
export const useGetAutoConfig = (
  commonQuestionId: number,
  enabled: boolean
) => {
  return useAuthorizedQuery({
    queryKey: "common-question-auto-config" + commonQuestionId,
    url: `${params.API_URL}/platform/auto-activate/common-question-auto-config/${commonQuestionId}`,
    method: "GET",
    enabled,
  });
};

export const useCommonQuestionInfo = (
  commonQuestionId: string,
  enabled: boolean
) => {
  return useAuthorizedQuery({
    queryKey: "common-question-info" + commonQuestionId,
    url: `${params.API_URL}/platform/auto-activate/common-question-info/${commonQuestionId}`,
    method: "GET",
    enabled,
  });
};

export const useAskFollowUp = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/answer`,
    method: "POST",
  });
};

export const useAskTryAgain = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/answer`,
    method: "POST",
  });
};

export const useCommonQuestionToggleEnable = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/toggle-common-question-enabled`,
    method: "PUT",
  });
};

export const useUpdateAutoConfig = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/common-question-auto-config`,
    method: "PUT",
  });
};

export const useUndoAutoConfig = (commonQuestionId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/common-questions/${commonQuestionId}/common-question-auto-config/drafts`,
    method: "DELETE",
  });
};

export const useUndoAnswer = (commonQuestionId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/auto-activate/common-questions/${commonQuestionId}/answers?followup=true`,
    method: "DELETE",
  });
};
