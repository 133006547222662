import React from "react";
import cx from "classnames";
import { Card } from "@geist-ui/core";
import { ChevronDown, Eye } from "@geist-ui/icons";
import dayjs from "dayjs";

import { ETicketStatus, TTicketInfo } from "../../assets/types";
import { TicketInfo } from "../ticketInfo";

import styles from "./styles.module.scss";
import {
  getTicketSystemName,
  getUrlForTicketSystem,
} from "../../assets/functions/ticketSystems";
import { GeistBadge } from "../atoms/geistBadge";

const columnsTable = [
  "Ticket",
  "Channel",
  "Tags",
  "Creation Date",
  "Last Update",
  "See ticket",
];

export const GDashboardTopicView = ({
  ticketsArray,
  isThereNextPage,
  showMore,
  handlerChangePage,
  handlerShowMore,
}: {
  ticketsArray: TTicketInfo[];
  isThereNextPage: boolean;
  showMore: boolean;
  handlerChangePage: () => void;
  handlerShowMore: (isUsedShowMore: boolean) => void;
}) => {
  return (
    <Card
      style={{
        borderRadius: "12px",
        width: "100%",
      }}
      hoverable
      id="tableCard"
      className={styles.dashboardTopicViewCard}
    >
      <div className={styles.cardContent}>
        <table className={styles.gtable}>
          <thead>
            <tr>
              {columnsTable.map((column, index) => (
                <th
                  key={column}
                  className={cx(
                    { [styles.topic]: index === 0 },
                    { [styles.tags]: column === "Tags" }
                  )}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={styles.divider}>
              <td colSpan={columnsTable.length} />
            </tr>
            {ticketsArray?.map((ticket) => (
              <tr key={ticket.id} className={cx(styles.tr)}>
                <td className={styles.data}>
                  <TicketInfo
                    ticketNumber={
                      ticket?.displayId ??
                      (ticket?.id && ticket?.id.split("::")[1]) ??
                      ""
                    }
                    subjectTicket={ticket?.subject}
                    statusTicket={ticket?.status as ETicketStatus | undefined}
                  ></TicketInfo>
                </td>
                <td className={styles.data}>{ticket.channel}</td>

                <td className={cx(styles.data, styles.tags)}>
                  {ticket.tags.map((tag) => (
                    <GeistBadge>{tag}</GeistBadge>
                  ))}
                </td>
                <td className={styles.data}>
                  {dayjs(ticket.createdAt).format("DD MMM hh:mm a")}
                </td>
                <td className={styles.data}>
                  {dayjs(ticket.updatedAt).format("DD MMM hh:mm a")}
                </td>
                <td className={styles.data}>
                  <button
                    className={styles.linkToTicket}
                    onClick={() => {
                      window.open(getUrlForTicketSystem(ticket.id), "_blank");
                    }}
                  >
                    <Eye />
                    View in {getTicketSystemName(ticket.id)}
                  </button>
                </td>
              </tr>
            ))}
            {ticketsArray.length >= 15 && isThereNextPage && (
              <tr className={styles.showMore}>
                <td colSpan={columnsTable.length}>
                  <div>
                    <span />
                    <button
                      onClick={() => {
                        if (isThereNextPage) {
                          handlerShowMore(true);
                          handlerChangePage();
                        } else console.log("No more tickets");
                      }}
                      className={cx({ [styles.showMore]: !showMore })}
                    >
                      Show {showMore ? "Less" : "More"}
                      <ChevronDown />
                    </button>
                    <span />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
