import React from "react";
import styles from "./styles.module.scss";
import { ETicketStatus, TTicketView } from "../../../assets/types";
import { TicketInfo } from "../../../components/ticketInfo";
import { Card, useTheme } from "@geist-ui/core";
import dayjs from "dayjs";

export const AssesmentTicketById = ({
  ticket,
}: {
  ticket: TTicketView | undefined;
}) => {
  const theme = useTheme();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainHeader}>
        <Card
          style={{
            background: theme.palette.accents_4,
            borderRadius: "12px",
            width: "100%",
          }}
          hoverable
          id="tableCard"
        >
          {
            <TicketInfo
              ticketNumber={ticket?.displayId ?? ""}
              subjectTicket={ticket?.subject ?? ""}
              statusTicket={ticket?.status as ETicketStatus | undefined}
            ></TicketInfo>
          }
        </Card>
      </div>
      <div className={styles.optionsBar}>
        <div className={styles.leftOptionsBar}>
          <Card
            style={{
              background: theme.palette.accents_4,
              borderRadius: "12px",
              width: "100%",
            }}
            hoverable
            id="tableCard"
          >
            <div className={styles.ticketDescription}>
              <div className={styles.createdAt}>
                <span>{dayjs(ticket?.createdAt).format("DD MMM hh:mm")}</span>
                <span>Ticket Created</span>
              </div>
              <div className={styles.footer}>
                <span>{ticket?.description}</span>
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.rightOptionsBar}>
          <Card
            style={{
              background: theme.palette.accents_4,
              borderRadius: "12px",
              width: "100%",
            }}
            hoverable
            id="tableCard"
          >
            <div className={styles.ticketDescription}>
              <div className={styles.createdAt}>
                <span>{dayjs(ticket?.updatedAt).format("DD MMM hh:mm")}</span>
                <span>Ticket Updated</span>
              </div>
              <div className={styles.footer}>
                <span>{ticket?.description}</span>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
