import { useEffect, useState } from "react";
import cx from "classnames";

import { Integration } from "../../../../accountSetup/types";
import { useLoginZendesk } from "../../../../accountSetup/api/useIntegrations";

import styles from "./styles.module.scss";

export const TicketSystem = ({
  token,
  meta,
  integration,
}: {
  token: string;
  meta?: any;
  integration?: Integration;
}) => {
  const { mutate: loginZendesk, data: redirectZendesk } = useLoginZendesk();

  const handleChoose = async (ticketSystem: string) => {
    if (ticketSystem === "zendesk") {
      loginZendesk({});
    }
  };

  useEffect(() => {
    if (redirectZendesk) {
      const { url } = redirectZendesk;
      window.location.href = url;
    }
  }, [redirectZendesk]);

  return (
    <div className={cx(styles.container)}>
      <>
        <main>
          <IntegrationButton
            text="Zendesk"
            imgSrc="/zendesk.png"
            onClick={() => {
              handleChoose("zendesk");
            }}
            isActive={integration?.name === "zendesk"}
          />
          {/* <IntegrationButton
            text="Salesforce"
            imgSrc="/salesforce.png"
            onClick={() => {
              handleChoose("salesforce");
            }}
            isActive={meta?.integrationType === "salesforce"}
          /> */}
        </main>
      </>
    </div>
  );
};

const IntegrationButton = ({
  text,
  imgSrc,
  isActive = false,
  onClick,
}: {
  text: string;
  imgSrc: string;
  isActive?: boolean;
  onClick: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);

  const loadingToggle = () => setLoading((prev) => !prev);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        loadingToggle();
      }, 2000);
    }
  }, [isLoading]);

  return (
    <button
      className={cx(styles.integrationButton, { [styles.active]: isActive })}
      onClick={() => {
        loadingToggle();
        onClick();
      }}
    >
      {isLoading ? (
        "loading.."
      ) : (
        <>
          <img src={imgSrc} alt="integration" />
          {text}
        </>
      )}
    </button>
  );
};
