import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useReports = (page: number = 0, enabled: boolean = true) => {
  return useAuthorizedQuery({
    queryKey: `reports-${page}`,
    url: `${params.API_URL}/sessions-api/reports?page=${page}`,
    method: "GET",
    enabled,
  });
};
