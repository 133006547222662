import React, { useState } from "react";

import cx from "classnames";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
import { useFeatureFlag } from "configcat-react";
import { Button, Input, Modal } from "@geist-ui/core";
import { createToast } from "vercel-toast";

import Nango from "@nangohq/frontend";

const nango = new Nango({ publicKey: "7d3dd047-cdc9-4f26-ae76-a1a75565600a" });

const integrationDescriptions: { [key: string]: string } = {
  zendesk: "/zendesk.png",
  document360:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrNGqfsZAIfNDkuUBexVfhCsKluYexGJg8HV7RwrdRuJxOgxod2SVY2-tawvIhjKpps50&usqp=CAU",
  jira: "https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png",
  salesforce:
    "https://static-00.iconduck.com/assets.00/salesforce-icon-2048x1434-jxt80iiu.png",
  gitbook:
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Ficonduck.com%2Ficons%2F108465%2Fgitbook&psig=AOvVaw2uRUO-4h2dEEX-LIqwow3T&ust=1725486013734000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCKiA-bjep4gDFQAAAAAdAAAAABAR",
};

const instructions: { [key: string]: string } = {
  zendesk:
    "https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API#topic_tcb_fk1_2yb",
  document360: "https://apidocs.document360.com/apidocs/api-token",
  freshdesk:
    "https://support.freshdesk.com/support/solutions/articles/215517-how-to-find-your-api-key",
  gitbook: "https://developer.gitbook.com/gitbook-api/authentication",
  mixpanel: "https://developer.mixpanel.com/reference/service-accounts",
};

const tokenTypes = [
  "zendesk",
  "document360",
  "freshdesk",
  "gitbook",
  "mixpanel",
];

export const IntegrationsInvite = () => {
  const [isOpen, setOpen] = useState(false);
  const [apiToken, setAPIToken] = useState("");
  const [searchParams] = useSearchParams();

  const [integrationConfig, setIntegrationConfig] = useState("");
  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState("");

  const token = searchParams.get("t") ?? "";

  const { value } = useFeatureFlag("integrations", "nothing:nothing", {
    identifier: token ?? "",
    email: "test@quack.ai",
    custom: {
      t: token,
    },
  });

  const tenant = value.split(":")?.[0];
  const integrations = value.split(":")?.[1]?.split(",") ?? [];

  if (!token || tenant === "none") {
    return <div>nothing here</div>;
  }

  return (
    <div className={styles.container}>
      <div className="space-y-6 text-center">
        <h1 className={styles.title}>Connect Your Integrations</h1>
        <p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed">
          To get started, connect the following integrations to your account.
          <br />
          And let's Quack it!
        </p>
      </div>
      <div className={styles.grid}>
        {integrations.map((integration: string) => {
          return (
            <Button
              style={{
                height: "200px",
                border: localStorage.getItem(
                  `quack-integrations-${integration}`
                )
                  ? "1px solid green"
                  : "",
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
              onClick={() => {
                if (tokenTypes.includes(integration)) {
                  setIntegrationConfig(integration);
                  setOpen(true);
                } else {
                  nango
                    .auth(integration, tenant)
                    .then(
                      async (result: {
                        providerConfigKey: string;
                        connectionId: string;
                      }) => {
                        createToast(
                          `Integration to ${integration} created Successfully`,
                          { type: "success" }
                        );
                        localStorage.setItem(
                          `quack-integrations-${result.providerConfigKey}`,
                          JSON.stringify({
                            integrationType: result.providerConfigKey,
                            integrationId: result.connectionId,
                          })
                        );

                        await fetch(
                          `https://hooks.slack.com/services/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`,
                          {
                            method: "POST",
                            headers: {},
                            body: JSON.stringify({
                              blocks: [
                                {
                                  type: "section",
                                  text: {
                                    type: "mrkdwn",
                                    text: `New Integration 🎉 \n *Tenant*: ${tenant} \n *Integration*: ${integration} \n *Method*:Nango`,
                                  },
                                },
                              ],
                            }),
                          }
                        );
                      }
                    )
                    .catch((err: { message: string; type: string }) => {
                      // handle error
                      createToast(`Something went wrong ${err}`, {
                        type: "error",
                      });
                    });
                }
              }}
            >
              <div className={cx(styles.card)} key={integration}>
                <div>
                  <img
                    src={
                      integrationDescriptions[integration] ??
                      `https://app.nango.dev//images/template-logos/${integration}.svg`
                    }
                    alt=""
                  />
                  <h3>{integration}</h3>
                </div>
              </div>
            </Button>
          );
        })}
      </div>
      <Modal onClose={() => setOpen(false)} visible={isOpen}>
        <Modal.Title>{integrationConfig}</Modal.Title>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {integrationConfig === "zendesk" && (
              <>
                <Input
                  style={{ width: "280px" }}
                  placeholder="Your Subdomain"
                  crossOrigin={""}
                  value={subdomain}
                  onChange={(e) => {
                    setSubdomain(e.target.value);
                  }}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                <br />
                <Input
                  style={{ width: "280px" }}
                  placeholder="Your Email"
                  crossOrigin={""}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              </>
            )}
            {integrationConfig === "freshdesk" && (
              <>
                <Input
                  style={{ width: "280px" }}
                  placeholder="Your Subdomain"
                  crossOrigin={""}
                  value={subdomain}
                  onChange={(e) => {
                    setSubdomain(e.target.value);
                  }}
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              </>
            )}
            {integrationConfig === "mixpanel" && (
              <>
                <Input
                  style={{ width: "280px" }}
                  placeholder="Your Project Id"
                  crossOrigin={""}
                  value={subdomain}
                  onChange={(e) => {
                    setSubdomain(e.target.value);
                  }}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                <br />
                <Input
                  style={{ width: "280px" }}
                  placeholder="Service account username"
                  crossOrigin={""}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              </>
            )}
            <p>Paste here your API token:</p>
            <Input
              style={{ width: "280px" }}
              placeholder="Your API Token"
              crossOrigin={""}
              value={apiToken}
              onChange={(e) => {
                setAPIToken(e.target.value);
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />

            <p style={{ color: "#949494" }}>
              Can't find your token:
              <a
                style={{
                  color: "#949494",
                  textDecoration: "underline",
                  marginLeft: "6px",
                }}
                target="_blank"
                rel="noreferrer"
                href={instructions[integrationConfig]}
              >
                generate token here
              </a>
            </p>
          </div>
        </Modal.Content>
        <Modal.Action
          passive
          onClick={() => setOpen(false)}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          Cancel
        </Modal.Action>
        <Modal.Action
          disabled={!apiToken}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          onClick={async () => {
            localStorage.setItem(
              `quack-integrations-${integrationConfig}`,
              JSON.stringify({
                set: true,
              })
            );
            await fetch(
              `https://hooks.slack.com/services/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`,
              {
                method: "POST",
                headers: {},
                body: JSON.stringify({
                  blocks: [
                    {
                      type: "section",
                      text: {
                        type: "mrkdwn",
                        text: `New Integration 🎉 \n *Tenant*: ${tenant} \n *Integration*: ${integrationConfig} \n*Token*: ${apiToken} ${
                          email ? `\n*Email*:${email}` : ""
                        } ${subdomain ? `\n*Subdomain*:${subdomain}` : ""} `,
                      },
                    },
                  ],
                }),
              }
            );
            setAPIToken("");
            setOpen(false);
            createToast(
              `Integration to ${integrationConfig} created Successfully`,
              { type: "success" }
            );
          }}
        >
          Submit
        </Modal.Action>
      </Modal>
    </div>
  );
};
