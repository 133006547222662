import React, { useMemo, useState } from "react";
import { Badge, Breadcrumbs, Dot, Drawer, Tabs, Text } from "@geist-ui/core";
import cx from "classnames";
import { X, Globe, Copy } from "@geist-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";

import {
  Opportunity,
  OpportunityStatus,
  useChangeStatusToHidden,
  useChangeStatusToVerify,
  useOpportunities,
  useResolvedOpportunities,
} from "../../../api/useOpportunities";
import { useGetArticlesByIds } from "../../../api/useAccuracySources";
import { TArticle } from "../../activationTopic/components/actionPanel/configurationTools.types";
import { mixTrackEvent, OpportunitiesEvents } from "../../../assets/mixpanel";

import styles from "./styles.module.scss";
import { Collapsable } from "../../../components/collapsable";

enum OpportunitiesStatuses {
  POTENTIAL = "potentnial",
  RESOLVED = "resolved",
}

export enum OppImpacts {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  HIDDEN = "hidden",
}

export const Opportunities = () => {
  const [tab, setSelected] = useState<string>(OpportunitiesStatuses.POTENTIAL);
  const { data: all, isLoading } = useOpportunities({ enabled: true });
  const data = all as Opportunity[];

  const { data: resolvedD, isLoading: resolvedLoading } =
    useResolvedOpportunities({
      enabled: true,
    });
  const resolved = resolvedD as Opportunity[];

  const potentialData = useMemo(() => {
    if (!data || !Array.isArray(data) || isLoading)
      return {
        [OppImpacts.HIGH]: [],
        [OppImpacts.MEDIUM]: [],
        [OppImpacts.LOW]: [],
        [OppImpacts.HIDDEN]: [],
      };
    const groupedData: { [key in OppImpacts]: Opportunity[] } = {
      [OppImpacts.HIGH]: data.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length > 10
      ),
      [OppImpacts.MEDIUM]: data.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length > 6 &&
          item.transaction.tickets.length <= 10
      ),
      [OppImpacts.LOW]: data.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length <= 6
      ),
      [OppImpacts.HIDDEN]: data.filter(
        (item) => item.status === OpportunityStatus.HIDDEN
      ),
    };

    return groupedData;
  }, [data, isLoading]);

  const resolvedData = useMemo(() => {
    if (!resolved || !Array.isArray(resolved) || resolvedLoading)
      return {
        [OppImpacts.HIGH]: [],
        [OppImpacts.MEDIUM]: [],
        [OppImpacts.LOW]: [],
        [OppImpacts.HIDDEN]: [],
      };
    const groupedData: { [key in OppImpacts]: Opportunity[] } = {
      [OppImpacts.HIGH]: resolved.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length > 10
      ),
      [OppImpacts.MEDIUM]: resolved.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length > 6 &&
          item.transaction.tickets.length <= 10
      ),
      [OppImpacts.LOW]: resolved.filter(
        (item) =>
          item.status !== OpportunityStatus.HIDDEN &&
          item.transaction.tickets.length <= 6
      ),
      [OppImpacts.HIDDEN]: resolved.filter(
        (item) => item.status === OpportunityStatus.HIDDEN
      ),
    };

    return groupedData;
  }, [resolved, resolvedLoading]);

  return (
    <div className={styles.container}>
      <div className={styles.leftPanel}>
        <Tabs
          value={tab}
          className={styles.tabs}
          onChange={(value) => {
            setSelected(value);
          }}
        >
          <Tabs.Item
            value={OpportunitiesStatuses.POTENTIAL}
            label={
              <div style={{ width: "140px" }}>Potential Opportunities</div>
            }
          ></Tabs.Item>
          <Tabs.Item
            value={OpportunitiesStatuses.RESOLVED}
            label={<div style={{ width: "140px" }}>Resolved</div>}
          ></Tabs.Item>
        </Tabs>
      </div>
      <div className={styles.rightPanel}>
        <Breadcrumbs scale={1.2} separator=">">
          <Breadcrumbs.Item>Opportunities</Breadcrumbs.Item>
          <Breadcrumbs.Item>
            {tab === OpportunitiesStatuses.POTENTIAL
              ? "Potential Opportunities"
              : "Resolved"}
          </Breadcrumbs.Item>
        </Breadcrumbs>
        {tab === OpportunitiesStatuses.POTENTIAL && (
          <>
            <Text h3>Potential Opportunities</Text>
            <OpportunitiesSections data={potentialData} loading={isLoading} />
          </>
        )}
        {tab === OpportunitiesStatuses.RESOLVED && (
          <>
            <Text h3>Resolved Opportunities</Text>
            <OpportunitiesSections
              data={resolvedData}
              hideHidden
              loading={resolvedLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

const subTypeMapper = {
  content_coverage: "/Increase content coverage",
  conflicts: "/Resolve Content Conflicts",
  overlap: "",
};

type Type = "high" | "medium" | "low" | "hidden";

const textMapper = {
  high: "High impact",
  medium: "Medium impact",
  low: "Low impact",
  hidden: "Hidden",
};

const Section = ({ items, type }: { type: Type; items: Opportunity[] }) => {
  return (
    <>
      <Collapsable
        initialCollapsedValue={type !== "high"}
        title={
          <Dot
            className={cx(styles.dot, {
              [styles.red]: type === "high",
              [styles.orange]: type === "medium",
              [styles.green]: type === "low",
              [styles.hidden]: type === "hidden",
            })}
          >
            {textMapper[type]}
            <Badge style={{ backgroundColor: "#2E2E2E" }}>{items.length}</Badge>
          </Dot>
        }
      >
        <div className={styles.itemsContainer}>
          {items.length === 0 && (
            <div className={styles.emptyItem}>{"No Items Here :)"}</div>
          )}

          {items.map((item) => {
            return <OpportunityItem item={item} type={type} key={item.id} />;
          })}
        </div>
      </Collapsable>
    </>
  );
};

const OpportunityItem = ({ item, type }: { item: Opportunity; type: Type }) => {
  const [isOpen, setOpen] = useState(false);
  const [message, setMessage] = useState<"" | "hidden" | "verify">("");
  const [copyPressed, setCopyPressed] = useState(false);

  const { user } = useAuth0();

  const { mutateAsync: changeStatusToVerify, isLoading: loadingVerify } =
    useChangeStatusToVerify({
      opportunityId: item.id,
    });

  const { mutateAsync: changeStatusToHidden, isLoading: loadingHidden } =
    useChangeStatusToHidden({
      opportunityId: item.id,
    });

  const {
    data: articlesData,
    // isLoading: loadingArticles
  } = useGetArticlesByIds(
    item?.transaction?.commonTransactionEval?.relatedArticlesIds &&
      Array.isArray(item.transaction.commonTransactionEval.relatedArticlesIds)
      ? item.transaction.commonTransactionEval.relatedArticlesIds.slice(0, 3)
      : [],
    isOpen &&
      item.transaction.commonTransactionEval.relatedArticlesIds &&
      Array.isArray(item.transaction.commonTransactionEval.relatedArticlesIds)
  );
  const articles = (articlesData?.articles || []) as TArticle[];

  return (
    <>
      <div
        key={item.id}
        className={styles.item}
        onClick={() => {
          mixTrackEvent(OpportunitiesEvents.CLICK_ON_OPPORTUNITY, {
            opportunityId: item.id,
            label: item.label,
          });
          setOpen((prev) => !prev);
        }}
      >
        <div className={styles.badge}>
          <Badge
            scale={0.9}
            style={{
              backgroundColor: "#2E2E2E",
              color: "#EDEDED",
              textTransform: "capitalize",
            }}
          >
            {item.type.toString()}
          </Badge>
        </div>
        <div className={styles.label}>
          <Text>{item.label}</Text>
        </div>
        <div className={styles.subType}>
          <Text p>{subTypeMapper.content_coverage}</Text>
        </div>
      </div>
      {isOpen && (
        <Drawer
          visible={isOpen}
          onClose={() => setOpen(false)}
          style={{ padding: "0px" }}
          className={cx(styles.drawer, "dark_mode")}
        >
          <div className={styles.drawer}>
            <div className={styles.drawerHeader}>
              <X onClick={() => setOpen(false)} />
            </div>
            <div className={cx(styles.drawerContent)}>
              <div className={styles.header}>
                <Badge
                  scale={0.9}
                  style={{
                    backgroundColor: "#2E2E2E",
                    color: "#EDEDED",
                    textTransform: "capitalize",
                  }}
                >
                  {item.type.toString()}
                </Badge>
                <div className={styles.headerDetails}>
                  <h3>{item.label}</h3>
                  <div className={styles.line}>
                    {type && (
                      <Dot
                        className={cx(styles.dot, {
                          [styles.red]: type === "high",
                          [styles.orange]: type === "medium",
                          [styles.green]: type === "low",
                          [styles.hidden]: type === "hidden",
                        })}
                      >
                        {textMapper[type]}
                      </Dot>
                    )}
                    <Text p>{subTypeMapper.content_coverage}</Text>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.top}>
                  <p>Missing content</p>
                  <p>Based on {item.transaction.tickets.length} tickets</p>
                </div>
                <div className={styles.transaction}>
                  <div
                    className={cx(styles.copy, {
                      [styles.copied]: copyPressed,
                    })}
                    onClick={() => {
                      setCopyPressed(true);
                      mixTrackEvent(
                        OpportunitiesEvents.COPY_OPPORTUNITY_TRANSACTION,
                        {
                          opportunityId: item.id,
                          transactionId: item.transaction.id,
                          label: item.label,
                          copiedText:
                            item.transaction.userContent +
                            "\n" +
                            item.transaction.agentContent,
                        }
                      );
                      createToast("Copied to clipboard 😉", {});
                      navigator.clipboard.writeText(
                        item.transaction.userContent +
                          "\n" +
                          item.transaction.agentContent
                      );
                    }}
                  >
                    <Copy /> Copy
                  </div>
                  <h5>{item.transaction.userContent}</h5>
                  <h5>{item.transaction.agentContent}</h5>
                </div>
                <div className={styles.buttons}>
                  <button
                    className={cx(styles.hide, {
                      [styles.loading]: loadingHidden,
                    })}
                    disabled={!!message}
                    onClick={async () => {
                      mixTrackEvent(
                        OpportunitiesEvents.HIDE_OPPORTUNITY_TRANSACTION,
                        {
                          opportunityId: item.id,
                          label: item.label,
                          transactionId: item.transaction.id,
                        }
                      );
                      await changeStatusToHidden({
                        status: OpportunityStatus.HIDDEN,
                        userEmail: user?.email,
                      });
                      setMessage("hidden");
                    }}
                  >
                    {message === "hidden" ? "Hidden" : "Hide for now"}
                  </button>
                  <button
                    className={cx(styles.verify, {
                      [styles.loading]: loadingVerify,
                      [styles.verified]: message === "verify",
                    })}
                    disabled={!!message}
                    onClick={async () => {
                      mixTrackEvent(
                        OpportunitiesEvents.VERIFY_OPPORTUNITY_TRANSACTION,
                        {
                          opportunityId: item.id,
                          label: item.label,
                          transactionId: item.transaction.id,
                        }
                      );
                      await changeStatusToVerify({
                        status: OpportunityStatus.VERIFY,
                        userEmail: user?.email,
                      });
                      setMessage("verify");
                    }}
                  >
                    {message === "verify"
                      ? "Verification Waiting"
                      : "Verify & Resolves"}
                  </button>
                  {message === "verify" && <span>usually takes 1 day</span>}
                </div>
                <div className={styles.articles}>
                  {articles &&
                    articles.map((article) => (
                      <div
                        key={article.articleId}
                        className={styles.article}
                        onClick={() => {
                          mixTrackEvent(
                            OpportunitiesEvents.CLICK_ON_OPPORTUNITY_ARTICLE_LINK,
                            {
                              opportunityId: item.id,
                              label: item.label,
                              articleId: article.articleId,
                              articleUrl: article.url,
                            }
                          );
                          window.open(article.url, "_blank");
                        }}
                      >
                        <Globe />
                        <span>/</span>
                        {article.title}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export const OpportunitiesSections = ({
  data,
  hideHidden,
  loading = false,
}: {
  data: {
    [key in OppImpacts]: Opportunity[];
  };
  loading?: boolean;
  hideHidden?: boolean;
}) => {
  if (loading) {
    return (
      <div className={cx(styles.loading, styles.itemsContainer)}>
        <div className={styles.emptyItem}>{"No Items Here :)"}</div>
      </div>
    );
  }
  return (
    <>
      <Section items={data[OppImpacts.HIGH]} type={OppImpacts.HIGH} />
      <Section items={data[OppImpacts.MEDIUM]} type={OppImpacts.MEDIUM} />
      <Section items={data[OppImpacts.LOW]} type={OppImpacts.LOW} />
      {!hideHidden && (
        <Section items={data[OppImpacts.HIDDEN]} type={OppImpacts.HIDDEN} />
      )}
    </>
  );
};
