import React, { createContext, useContext, useEffect, useState } from "react";
import { Instruction } from "../types";
import { useReleases } from "./release";
import { params } from "../../../params";

interface InstructionsContextType {
  instructions: Instruction[];
  setInstructions: React.Dispatch<React.SetStateAction<Instruction[]>>;
  simulatorInstructions: Instruction[];
  setSimulatorInstructions: React.Dispatch<React.SetStateAction<Instruction[]>>;
}

const InstructionsContext = createContext<InstructionsContextType | undefined>(
  undefined
);

export const useInstructions = (): {
  instructions: Instruction[];
  simulatorInstructions: Instruction[];
  generalInstructions: Instruction[];
  manualInstructions: Instruction[];
  deleteInstruction: (instruction: Instruction, index: number) => void;
  handleInstruction: (instruction: Instruction) => void;
  handleInstructionsOptions: (instruction: string) => Promise<string[]>;
  handleGeneralInstruction: (instruction: Instruction[]) => void;
  handleSimulatorInstructions: (instruction: Instruction[]) => void;
} => {
  const { handleUpdateReleaseInstructions } = useReleases();
  const context = useContext(InstructionsContext);
  if (!context) {
    throw new Error(
      "useInstructions must be used within an InstructionsProvider"
    );
  }

  const handleGeneralInstruction = async (i: any) => {
    const newInstructions = [
      ...context.instructions.filter((x) => x.type === "manual"),
      ...i,
    ];

    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  const handleInstructionsOptions = async (instruction: string) => {
    const response = await fetch(`${params.AI_URL}/api/instructify`, {
      method: "POST",
      body: JSON.stringify({
        instruction: {
          content: instruction,
        },
      }),
      headers: {
        "x-quack-token": params.AI_TOKEN,
      },
    });
    const data = await response.json();

    return data.options;
  };

  const handleInstruction = async (instruction: Instruction) => {
    // const response = await fetch(`${params.AI_URL}/api/instructify`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     instruction,
    //   }),
    //   headers: {
    //     "x-quack-token": params.AI_TOKEN,
    //   },
    // });
    // const data = await response.json();

    const newInstructions = [...context.instructions, { ...instruction }];

    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  const deleteInstruction = (instruction: Instruction, index: number) => {
    const newInstructions = context.instructions.filter(
      (inst) => inst.content !== instruction.content
    );
    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  return {
    instructions: context.instructions,
    generalInstructions: context.instructions.filter(
      (i) => i.type === "general"
    ),
    manualInstructions: context.instructions.filter((i) => i.type === "manual"),
    deleteInstruction,
    handleInstruction,
    handleGeneralInstruction,
    handleInstructionsOptions,
    simulatorInstructions: context.simulatorInstructions,
    handleSimulatorInstructions: (instruct) =>
      context.setSimulatorInstructions(instruct),
  };
};

export const InstructionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { relevantRelease } = useReleases();

  const [instructions, setInstructions] = useState<Instruction[]>([]);
  const [simulatorInstructions, setSimulatorInstructions] = useState<
    Instruction[]
  >([]);

  useEffect(() => {
    if (relevantRelease && relevantRelease?.releasePolicy) {
      const policy = JSON.parse(relevantRelease.releasePolicy);
      if (JSON.stringify(policy) !== JSON.stringify(instructions)) {
        setInstructions([...policy]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantRelease]);

  return (
    <InstructionsContext.Provider
      value={{
        instructions: instructions,
        setInstructions,
        simulatorInstructions,
        setSimulatorInstructions,
      }}
    >
      {children}
    </InstructionsContext.Provider>
  );
};
