import React from "react";
import styles from "./styles.module.scss";
import { Logo } from "../../assets/logo";

export const InstallSuccess = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.headLine}>
        Extension installed <Logo />
      </h1>
      <h2 className={styles.subHeadLine}>Support customers, not tickets.</h2>
      <div className={styles.gifContainer}>
        <img
          className={styles.gif}
          alt=""
          src="https://quack-assets.s3.amazonaws.com/company/installation-guide.gif"
        />
      </div>
      <h2 className={styles.subHeadLine}>How to use Quack</h2>
      <p>
        <ol>
          <li>
            Go to your ticketing system (Zendesk / Salesforce) and login to your
            workspace.
          </li>
          <li>
            Quack's Co-pilot will appear in the bottom right corner (Yellow duck
            icon).
          </li>
          <li>
            Login to Quack’s account and start enjoying the power of Quack's
            Co-pilot.
          </li>
        </ol>
      </p>
      <p>Quack is available only to registered users.</p>
      <p>
        If you don’t have an account - please visit our{" "}
        <a href="https://www.thequack.ai">website</a> to book a demo.
      </p>
    </div>
  );
};

// How to use Quack:
//
//
//

// Quack is available only to registered users.
// If you don’t have an account - please visit our website to book a demo.
