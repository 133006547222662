import { ReactNode } from "react";
import { Card, Text, Tooltip } from "@geist-ui/core";
import cx from "classnames";
import { IoInformationCircleSharp } from "react-icons/io5";

import { EBadgeMode, GeistBadge } from "../atoms/geistBadge";

import styles from "./styles.module.scss";

export const InfoCard = ({
  children,
  title,
  footer,
  tooltipText,
  footerTooltip,
  percentage,
  lessIsGood = false,
  loading = false,
}: {
  children: ReactNode;
  title?: string;
  footer?: string;
  tooltipText?: string;
  footerTooltip?: string;
  percentage?: number | string;
  lessIsGood?: boolean;
  loading?: boolean;
}) => {
  return (
    <Card
      hoverable
      style={{
        width: "100%",
        borderRadius: "12px",
      }}
      className={cx({ [styles.skeletonCard]: loading }, styles.infoCard)}
    >
      {!loading ? (
        <div className={styles.infoCardContainer}>
          <div className={styles.topContainer}>
            {title ? <Text className={styles.cardTitle}>{title}</Text> : <></>}
            {tooltipText ? (
              <Tooltip text={tooltipText}>
                <IoInformationCircleSharp className={styles.informationFill} />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.bottomContainer}>
            <Text h3 className={styles.mainText}>
              {children}
            </Text>
            {percentage !== undefined && (
              <GeistBadge
                mode={
                  typeof percentage === "number"
                    ? !lessIsGood
                      ? percentage > 0
                        ? EBadgeMode.GREEN
                        : EBadgeMode.RED
                      : percentage < 0
                      ? EBadgeMode.GREEN
                      : EBadgeMode.RED
                    : EBadgeMode.DEFAULT
                }
              >
                {typeof percentage === "number"
                  ? percentage > 0
                    ? "+"
                    : ""
                  : ""}
                {typeof percentage === "number" ? percentage : percentage}%
              </GeistBadge>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.infoCardContainer}>
          <div className={styles.topContainer}>
            <Text
              className={styles.cardTitle}
              style={{ color: "transparent", height: "24px" }}
            >
              Mock
            </Text>
          </div>
          <div className={styles.bottomContainer}>
            <Text
              h3
              className={styles.mainText}
              style={{ color: "transparent" }}
            >
              26 days 16 hours hours
            </Text>
          </div>
        </div>
      )}
    </Card>
  );
};
