import { graphql } from "../../../gql/gql";
import { useAuthorizedMutation, useGraphQLAuthorizedQuery } from "../../../api";
import { params } from "../../../params";

const getTicketsQueryDocument = graphql(/* GraphQL */ `
  query ticketsByIdAndCreatedAt(
    $owner: String!
    $limit: Int
    $nextToken: String
  ) {
    ticketsByOwnerAndCreatedAt(
      owner: $owner
      limit: $limit
      sortDirection: DESC
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        priority
        email
        subject
        organization
        description
        createdAt
        userRaw
        status
        insights {
          items {
            similar_docs
            historical_suggestions
            ai_suggestions {
              priority
              priority_explanation
              reply
              reply_explanation
              tags
              sentiment
              sentiment_explanation
              urgency
              resolution_time
              doc_id
            }
            rankings {
              items {
                id
                rank
                author
                max
                min
              }
            }
          }
        }
      }
    }
  }
`);

export const useTickets = (
  owner: string,
  enabled: boolean,
  limit: number,
  nextToken: string | null
) => {
  return useGraphQLAuthorizedQuery({
    queryKey: ["getTickets", owner],
    url: `${params.GRAPHQL_URL}/graphql/`,
    document: getTicketsQueryDocument,
    variables: { owner, limit, nextToken },
    enabled,
    cacheTime: 0,
  });
};

export const useRegenerateInsight = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/insights/regenerate/`,
  });
};
