import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DashboardCard } from "../dashboardCard";
import { useKnowledgeCoverageStatus } from "../../../../api/useKnowledgeCoverageStatus";
import { useGetArticlesCount } from "../../../../api/useGetArticlesCount";
import { BarDataRow } from "../../../../components/barDataRow";
import { ETimesFilter } from "../../../../assets/types";
import { CoverageLevelType } from "../../../knowledgeManagement/index.types";

import styles from "./styles.module.scss";

export const CoverageLevelTypeLables = {
  [CoverageLevelType.FULL]: "Covered",
  [CoverageLevelType.PARTIAL]: "Partially Covered",
  [CoverageLevelType.NO_COVERAGE]: "Uncovered",
};

export const KnowledgeCoverage = ({
  timeFilter,
}: {
  timeFilter: {
    value: ETimesFilter;
    label: string;
  };
}) => {
  const navigate = useNavigate();

  const { data: knowledgeStatus, isLoading: knowledgeStatusLoading } =
    useKnowledgeCoverageStatus(timeFilter.value, !!timeFilter);
  const coverageStatus = knowledgeStatus as Record<CoverageLevelType, number>;

  const { data: articlesCount, isLoading: articlesCountLoading } =
    useGetArticlesCount(true);

  const navigateToKnowledge = () => {
    navigate(`/knowledge-management`);
  };

  const load = useMemo(
    () =>
      knowledgeStatusLoading ||
      articlesCountLoading ||
      coverageStatus === undefined,
    [knowledgeStatusLoading, articlesCountLoading, coverageStatus]
  );

  const sum: number = useMemo(() => {
    return (
      (coverageStatus !== undefined &&
        Object.values(coverageStatus).reduce((acc, val) => acc + val, 0)) ||
      100000
    );
  }, [coverageStatus]);

  const Bars = useCallback(() => {
    return (
      <>
        {Object.values(CoverageLevelType).map((coverageLevel, index) => (
          <BarDataRow
            key={coverageLevel}
            percentage={Math.max(
              0,
              Math.round((coverageStatus[coverageLevel] * 100) / sum)
            )}
            label={CoverageLevelTypeLables[coverageLevel]}
            placement={index}
          />
        ))}
      </>
    );
  }, [coverageStatus, sum]);

  const articlesNum = useMemo(() => {
    const articlesCountData: number = articlesCount?.articlesCount;
    return articlesCountData || 0;
  }, [articlesCount]);

  return (
    <DashboardCard
      smallHead="Knowledge coverage status"
      bigHead={`${articlesNum} Knowledge base articles`}
      grow={4}
      tooltip="how well the knowledge base articles cover the answers given by agents"
      expand={navigateToKnowledge}
      loading={load}
      loadingClass="knowledgeStatus"
      maxWidthPer={50}
    >
      {!load && (
        <div className={styles.knowledgeCoverageContainer}>
          <Bars />
        </div>
      )}
    </DashboardCard>
  );
};
