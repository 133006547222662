// Performance.tsx
import React, { useState } from "react";
import { Tabs } from "@geist-ui/core";

import { ETimesFilter } from "../../assets/types";
import { useGetTopics } from "../../api/useGetTopics";
import { BigSelect } from "../../components/geistSelect";

import styles from "./styles.module.scss";
import { Topics } from "./topics";
import { Agents } from "./agents";

const timeOptions = [
  { id: ETimesFilter.ONE_WEEK, name: "Last 7 days" },
  { id: ETimesFilter.TWO_WEEKS, name: "Last 14 days" },
  { id: ETimesFilter.ONE_MONTH, name: "Last 1 month" },
];

export const Performance = () => {
  const [timeFilter, setTimeFilter] = useState<ETimesFilter>(
    ETimesFilter.ONE_WEEK
  );
  const [topicsFilter, setTopicsFilter] = useState<string[]>([]);
  const { data: topics, isLoading: loadTopics } = useGetTopics();

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        Performance of{" "}
        {/* <Select
          className={styles.select}
          placeholder="Choose one"
          value="1"
          onChange={(e) => console.log(e)}
        >
          <Select.Option value="1">All Teams</Select.Option>
          <Select.Option value="2">Tier 1</Select.Option>
          <Select.Option value="3">Tier 2</Select.Option>
          <Select.Option value="4">Tier 1 eu</Select.Option>
        </Select> */}
        all agents across{" "}
        <BigSelect
          filter={topicsFilter}
          setFilter={(value) => setTopicsFilter(value as string[])}
          options={topics}
          isLoading={loadTopics}
          multiple={"All topics"}
        />
        Over the
        <BigSelect
          filter={timeFilter}
          setFilter={(value) => setTimeFilter(value as ETimesFilter)}
          options={timeOptions}
          isLoading={false}
        />
      </div>
      <Tabs initialValue="agents" leftSpace={"40px"} className={"samplingTabs"}>
        {" "}
        {/* <Tabs.Item label="Overview" value={"overview"} disabled>
          <Overview />
        </Tabs.Item> */}
        <Tabs.Item label="Agents" value="agents">
          <Agents timeFilter={timeFilter} topics={topicsFilter} />
        </Tabs.Item>
        <Tabs.Item label="Topics" value="topics">
          <Topics timeFilter={timeFilter} topics={topicsFilter} />
        </Tabs.Item>
      </Tabs>
    </div>
  );
};
