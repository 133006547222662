export enum ECategories {
  Bug = "bug",
  Feature_Request = "feature_request",
  Feedback = "feedback",
  TroubleShooting = "troubleshooting",
  Requests = "general_request",
  Information_Education = "information_or_education",
}

export interface IVocTicket {
  id: string;
  displayId: string | null;
  description: string;
  subdomain: string;
  subject: string;
  topic: ITopic;
  ticketMetrics: ITicketMetrics;
  sourceID: string;
}

export interface ITopic {
  id: string;
  topic: string;
  name: string;
}

export interface ITicketMetrics {
  fullResolutionTimeCalendar: string;
  requesterWaitTimeCalendar: string;
}
