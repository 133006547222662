import React, { useEffect, useState } from "react";
import { IoSparklesSharp } from "react-icons/io5";
import { X, Plus, ArrowUp } from "@geist-ui/icons";

import { TCommonQuestion } from "../../index.types";
import { IMessage } from "../messages";
import { ActivationEvents, mixTrackEvent } from "../../../../assets/mixpanel";

import styles from "./styles.module.scss";
import { ConfigurationTools } from "./configurationTools";
import { BrainTool } from "./brainTool";
import { TAccuracyConfig } from "./configurationTools.types";

enum ChosenAction {
  TRY_AGAIN = "try_again",
  FOLLOWUP = "followup",
}

export const ActionPanel = ({
  config,
  messages,
  question,
  askFollowup,
  askTryAgain,
  questionId,
  isAnswerLoading = false,
  handleResize = () => {},
}: {
  config: TAccuracyConfig;
  messages: IMessage[];
  question: TCommonQuestion;
  askFollowup: (message: string) => void;
  askTryAgain: (message: string) => void;
  questionId: number;
  isAnswerLoading?: boolean;
  handleResize?: () => void;
}) => {
  const [chosen, setChosen] = useState<ChosenAction | null>(null);
  const [followup, setFollowup] = useState<string>("");
  const [tryAgain, setTryAgain] = useState<string>("");

  const handleAskFollowup = (message: string) => {
    askFollowup(message);
    setFollowup("");
    mixTrackEvent(ActivationEvents.ASK_FOLLOW_UP_QUESTION, {
      question: message,
    });
    setChosen(null);
  };

  const handleTryAgain = (message: string) => {
    askTryAgain(message);
    setTryAgain("");
    mixTrackEvent(ActivationEvents.SEND_TRY_AGAIN, {
      instructions: message,
    });
    setChosen(null);
  };

  useEffect(() => {
    handleResize();
  }, [chosen, handleResize]);

  useEffect(() => {
    setTryAgain(config.customInstructions ?? "");
  }, [config]);

  if (isAnswerLoading) return <div className={styles.container} />;

  return (
    <div className={styles.container}>
      {chosen ? (
        <>
          {chosen === ChosenAction.TRY_AGAIN && (
            <div className={styles.tryAgainContainer}>
              <div className={styles.tryAgain}>
                <div className={styles.inputTryAgain}>
                  <IoSparklesSharp />
                  <input
                    placeholder="Tell AI your instructions "
                    autoFocus
                    value={tryAgain}
                    onChange={(e) => setTryAgain(e.currentTarget.value)}
                    onKeyUp={(e) => {
                      e.key === "Enter" && handleTryAgain(tryAgain);
                      e.key === "Escape" && setChosen(null);
                    }}
                  />
                  <button onClick={() => handleTryAgain(tryAgain)}>
                    <ArrowUp />
                  </button>
                </div>
                <div className={styles.bottomTryAgain}>
                  For example “replace numbers to bullets”, keep empty for
                  default configuation
                </div>
              </div>
              <div className={styles.closeContainer}>
                <button
                  className={styles.close}
                  onClick={() => setChosen(null)}
                >
                  <X />
                </button>
              </div>
            </div>
          )}
          {chosen === ChosenAction.FOLLOWUP && (
            <div className={styles.tryAgainContainer}>
              <div className={styles.tryAgain}>
                <div className={styles.inputTryAgain}>
                  <IoSparklesSharp />
                  <textarea
                    placeholder="Ask a follow up "
                    autoFocus
                    value={followup}
                    onChange={(e) => setFollowup(e.currentTarget.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        if (e.shiftKey) {
                          e.preventDefault();
                        } else {
                          handleAskFollowup(followup);
                        }
                      }
                      e.key === "Escape" && setChosen(null);
                    }}
                  />
                  <button onClick={() => handleAskFollowup(followup)}>
                    <ArrowUp />
                  </button>
                </div>
                <div className={styles.bottomTryAgain}>
                  For example “xxxx", keep empty for auto generate
                </div>
              </div>
              <div className={styles.closeContainer}>
                <button
                  className={styles.close}
                  onClick={() => setChosen(null)}
                >
                  <X />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <button
            onClick={() => {
              setChosen(ChosenAction.FOLLOWUP);
              mixTrackEvent(ActivationEvents.OPEN_TEXT_EDITOR, {
                texterea: "followup",
              });
            }}
          >
            <Plus /> Ask a followup
          </button>
          <button
            onClick={() => {
              setChosen(ChosenAction.TRY_AGAIN);
              mixTrackEvent(ActivationEvents.OPEN_TEXT_EDITOR, {
                texterea: "tryagain",
              });
            }}
          >
            <IoSparklesSharp />
            Try again
          </button>
          <ConfigurationTools
            messages={messages}
            questionId={questionId}
            question={question}
            askTryAgain={handleTryAgain}
            config={config}
          />
          <BrainTool config={config} />
        </>
      )}
    </div>
  );
};
