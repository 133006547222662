export type TProcessedArticlesTopic = {
  id: number;
  name: string | null;
  topic: string;
  articles: string[];
};

export enum CoverageLevelType {
  FULL = "FULL",
  PARTIAL = "PARTIAL",
  NO_COVERAGE = "NO_COVERAGE",
}

export type TTicketCoverage = {
  [key in CoverageLevelType]: {
    id: string;
    topic: { id: number; topic: string };
  }[];
};
