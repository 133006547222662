import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Tooltip } from "@geist-ui/core";

import { TCommonQuestion } from "../../index.types";
import { Tag } from "../enabledTag";

import styles from "./styles.module.scss";

export interface ICommonQuestion {
  name: string;
  enabled: boolean;
  potential: string;
}

export const LoadingCommonQuestion = () => {
  return <div className={styles.skeletonCommonQuestion}>mock mock</div>;
};

export const CommonQuestion = ({
  state,
  index,
  isExpanded,
  onClick,
  active,
}: {
  state: TCommonQuestion & { potential: number };
  index: number;
  isExpanded: boolean;
  onClick: (index: number) => void;
  active?: boolean;
}) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsEllipsis(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [textRef, isExpanded]);

  return (
    <div
      className={cx(styles.container, { [styles.active]: active })}
      onClick={() => onClick(index)}
    >
      <div className={styles.left}>
        <Tag
          isEnabled={state.autoResponseEnabled}
          isPublish={state.publishEnabled}
          isExpanded={isExpanded}
        />
        {isExpanded ? (
          <div ref={textRef} className={styles.label}>
            {isEllipsis ? (
              <Tooltip text={state.name} placement="topStart">
                {state.name}
              </Tooltip>
            ) : (
              state.name
            )}
          </div>
        ) : (
          <div className={cx(styles.label, styles.subLabel)}>#{index}</div>
        )}
      </div>
      <div className={styles.potential}>{Math.round(state.potential)}%</div>
    </div>
  );
};
