import React from "react";
import cx from "classnames";
import { Tooltip } from "@geist-ui/core";

import styles from "./styles.module.scss";

export const AiAccuracy = ({ accuracy }: { accuracy: number }) => {
  const getLineOpacity = (index: number) => {
    const threshold = 25 * (index + 1);
    if (accuracy >= threshold) {
      return 1;
    } else if (accuracy > threshold - 25) {
      return (accuracy - (threshold - 25)) / 25;
    } else {
      return 0;
    }
  };

  return (
    <Tooltip text={`${accuracy}% AI Accuracy`} style={{ width: "100%" }}>
      <div className={styles.aiAccuracy}>
        <>
          {[0, 1, 2, 3].map((index) => {
            const opacity = getLineOpacity(index);
            return (
              <div
                key={index}
                className={cx(styles.line, {
                  [styles.colored]: opacity > 0,
                })}
                style={{
                  opacity: opacity > 0 ? opacity : 0.5,
                }}
              />
            );
          })}
        </>
      </div>
    </Tooltip>
  );
};
