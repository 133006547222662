import { graphql } from "../../../gql/gql";
import { useAuthorizedMutation, useGraphQLAuthorizedQuery } from "../../../api";
import { params } from "../../../params";

const getIntegrationsByOwnerQueryDocument = graphql(/* GraphQL */ `
  query listIntegrationsByOwner {
    listIntegrations {
      nextToken
      items {
        id
        name
        metadata
        status
        type
      }
    }
  }
`);

export const useIntegrations = (owner: string, enabled: boolean) => {
  return useGraphQLAuthorizedQuery({
    queryKey: ["getIntegrations", owner],
    url: `${params.GRAPHQL_URL}/graphql/`,
    document: getIntegrationsByOwnerQueryDocument,
    variables: { owner },
    enabled,
  });
};

export const useParagonAuthenticator = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth/paragon-authenticate/`,
  });
};

export const useAddIntegration = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth/integrations/`,
  });
};

export const useDeleteIntegration = (id: string) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth/integrations/${id}/`,
    method: "DELETE",
  });
};

export const useLoginZendesk = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth-gateway/zendesk-login`,
    method: "GET",
  });
};
