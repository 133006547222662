import React from "react";
import { BsLightbulb } from "react-icons/bs";
import { Text } from "@geist-ui/core";
import { useNavigate } from "react-router-dom";

import { GeistSelect } from "../../../components/geistSelect";
import { TimeFilter, ETimesFilter, TTicketInfo } from "../../../assets/types";
import { GDashboardTopicView } from "../../../components/geistTableTopicView";
import { EBadgeMode, GeistBadge } from "../../../components/atoms/geistBadge";

import styles from "./styles.module.scss";

export const AssesmentTopicById = ({
  timeFilter,
  ticketsByTopic,
  topicId,
  isThereNextPage,
  showMore,
  handleChangeTimeFilter,
  handlerChangePage,
  handlerShowMore,
}: {
  timeFilter: {
    value: ETimesFilter;
    label: string;
  };
  ticketsByTopic: TTicketInfo[];
  topicId: string;
  isThereNextPage: boolean;
  showMore: boolean;
  handleChangeTimeFilter: (value: string | string[]) => void;
  handlerChangePage: () => void;
  handlerShowMore: (isUsedShowMore: boolean) => void;
}) => {
  const navigate = useNavigate();

  const handleActivizeTopic = (topicId: string) => {
    navigate(`/activation/${topicId}`);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainHeader}>
        <Text h3 style={{ color: "#EDEDED" }}>
          Topics
        </Text>
        <GeistBadge
          mode={EBadgeMode.PRETTY}
          onClick={() => handleActivizeTopic(topicId)}
        >
          <BsLightbulb />
          Activate this topic
        </GeistBadge>
      </div>
      <div className={styles.optionsBar}>
        <div className={styles.optionsBarLeft}></div>
        <GeistSelect
          options={TimeFilter}
          handleChange={handleChangeTimeFilter}
          initialValue={timeFilter}
        />
      </div>
      <div className={styles.footer}>
        <GDashboardTopicView
          ticketsArray={ticketsByTopic}
          showMore={showMore}
          isThereNextPage={isThereNextPage}
          handlerChangePage={handlerChangePage}
          handlerShowMore={handlerShowMore}
        />
      </div>
    </div>
  );
};
