import { IBlock } from "../../assets/types";
import { Typography } from "../typography";

export const DisplayWrapper = ({ state }: { state: any }) => {
  const blocks = state.blocks as IBlock[];
  const isRtl = state.isRtl;
  return (
    <div dir={isRtl ? "rtl" : "ltr"} className="flex flex-col gap-3 p-5">
      {blocks
        ?.sort((a, b) => a.order - b.order)
        .map((b) => {
          return (
            <div key={b.key} className=" flex flex-col gap-1">
              <Typography type="headline" theme="disabled">
                <span className=" ">{b.label}:</span>
              </Typography>
              {b.type === "list" ? (
                <ol className=" px-4 flex flex-col gap-1">
                  {state?.[b.key]?.map((v: string) => (
                    <Typography key={v} type="headline" theme="primary">
                      <li className=" list-decimal">{v}</li>
                    </Typography>
                  ))}
                </ol>
              ) : (
                <Typography type="headline" theme="primary">
                  {state?.[b.key]}
                </Typography>
              )}
            </div>
          );
        })}
    </div>
  );
};
