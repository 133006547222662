import React, { ReactNode } from "react";
import cx from "classnames";
import { Drawer } from "@geist-ui/core";
import { X } from "@geist-ui/icons";

import styles from "./styles.module.scss";

export const GeistDrawer = ({
  openDrawer,
  onDrawerClosed,
  children,
  headline,
}: {
  openDrawer: boolean;
  onDrawerClosed: () => void;
  children: ReactNode;
  headline: ReactNode;
}) => {
  return (
    <Drawer
      className={cx(styles.drawer, "dark_mode")}
      visible={openDrawer}
      onClose={() => onDrawerClosed()}
      style={{ zIndex: 100000 }}
    >
      <div className={styles.header}>
        <button onClick={() => onDrawerClosed()}>
          <X />
        </button>
        <h3>{headline}</h3>
      </div>
      {children}
    </Drawer>
  );
};
