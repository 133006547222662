import { useAuthorizedQuery } from "..";
import { ETimesFilter, getFilteredDate } from "../../assets/types";
import { params } from "../../params";

export const usePerformance = ({
  enabled,
  timeFilter,
  topics,
  groupBy = "common-question",
}: {
  enabled: boolean;
  timeFilter: ETimesFilter;
  topics?: string[];
  groupBy?: "common-question" | "agent";
}) => {
  const date = getFilteredDate(timeFilter);
  const filterTopic = topics?.join("&topic[]=");

  return useAuthorizedQuery({
    queryKey: `useAgentPerformance-${topics?.join(
      "filterTopic-"
    )}-${timeFilter}-${groupBy}`,
    url: `${
      params.API_URL
    }/platform/agent-performance?groupBy=${groupBy}&timeFilter=${date}${
      filterTopic ? `&topic[]=${filterTopic}` : ""
    }`,
    method: "GET",
    enabled,
  });
};
