import {
  ELengthAnswer,
  EPersonalCustom,
  EToneOfVoice,
  TAccuracyConfig,
} from "./components/actionPanel/configurationTools.types";
import { EMessageType, ERole } from "./components/messages";

export type TAnswer = {
  id: number;
  commonQuestionId: number;
  createdAt: string;
  updatedAt: string;
  version: number;
  question: string;
  answer: string;
  score: number;
  isFollowUpQuestion: boolean;
};

export type TCommonQuestion = {
  id: number;
  name: string;
  tenant: string;
  topicId: number;
  autoResponseEnabled: boolean;
  activeLastUpdated: string;
  readyAutoResponse: boolean;
  publishEnabled: boolean;
  publishLastUpdated: string;
  answers: TAnswer[];
  similarTicketId: string;
  similarTicket: {
    id: string;
    subject: string;
    description: string;
  };
  _count: {
    tickets: number;
  };
};

export type TQuestionsArray = TCommonQuestion[];

export type TAutoResponseData = {
  commonQuestions: TQuestionsArray;
  topicName: string;
  totalTickets: number;
  readyTickets: number;
  enabledTickets: number;
  totalTicketsPercentage: number;
  readyPercentage: number;
  enabledPercentage: number;
  thumbsUpThumbsDown: {
    [key: string]: {
      thumbsUp: number;
      thumbsDown: number;
      commonQuestionId: number;
    };
  };
};

export type TCommonQuestionInfo = {
  countTicketsLastMonth: number;
  countTotalTicketsCommonQuestion: number;
  rateOfChange: number;
  precentageOfClosedOrSolvedTickets: number;
  ticketDetails: {
    id: string;
    subject: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    solvedAt: string | null;
    displayId: string | null;
  }[];
  avgTimeToResolve: number;
  thumbsUpThumbsDownByTickets: {
    [key: string]: {
      thumbsUp: number;
      thumbsDown: number;
    };
  };
};

export type TDisplayedMessage = {
  content: string;
  role: ERole;
  type: EMessageType;
};

export const defaultConfig: TAccuracyConfig = {
  id: 0,
  tenantId: "",
  personalCustom: EPersonalCustom.MELLOW,
  toneOfVoice: EToneOfVoice.TECHNICAL,
  includeArticle: false,
  lengthAnswer: ELengthAnswer.MEDIUM,
  commonQuestionId: 0,
  updatedAt: "",
  isDraft: true,
};
