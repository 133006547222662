import { useCallback } from "react";
import cx from "classnames";

import { IntegrationType, Integration } from "../../../accountSetup/types";

import styles from "./styles.module.scss";
import { TicketSystem } from "./ticketSystem";

import { Chat } from "./chat";
import { Other } from "./Other";

export const SetupCard = ({
  show,
  selectedCardType,
  token,
  integrations,
  refetch,
}: {
  show: boolean;
  selectedCardType: IntegrationType | null;
  token?: string;
  integrations: Integration[];
  refetch: () => void;
}) => {
  const Integration = useCallback(
    ({ token }: { token: string }) => {
      switch (selectedCardType) {
        case IntegrationType.TICKET_SYSTEM:
          const existingIntegration = integrations.find(
            (i) => i.type === IntegrationType.TICKET_SYSTEM
          );

          let meta;
          if (typeof existingIntegration?.metadata === "string") {
            meta = JSON.parse(existingIntegration.metadata);
          } else {
            meta = existingIntegration?.metadata || null;
          }

          return (
            <TicketSystem
              token={token}
              meta={meta}
              integration={existingIntegration}
            />
          );
        case IntegrationType.OTHER:
          const otherIntegrations = integrations
            .filter((i) =>
              [
                IntegrationType.CRM,
                IntegrationType.OBSERVABILITY,
                IntegrationType.KNOWLEDGE_BASE,
                IntegrationType.PRODUCT_ANALYTICS,
              ].includes(i.type)
            )
            .map((item) => {
              let parsedMetadata = item.metadata;
              if (typeof item.metadata === "string") {
                parsedMetadata = JSON.parse(item.metadata);
              }
              return { ...item, metadata: parsedMetadata };
            });

          return (
            <Other
              meta={otherIntegrations.map((i) => i.metadata)}
              integrations={otherIntegrations}
              token={token}
              refetch={refetch}
            />
          );
        case IntegrationType.CHAT:
          const chatIntegration = integrations.find(
            (i) => i.type === IntegrationType.CHAT
          );

          let chatMeta = chatIntegration?.metadata;
          if (typeof chatMeta === "string") {
            chatMeta = JSON.parse(chatMeta);
          }

          return (
            <Chat
              token={token}
              meta={chatMeta}
              integration={chatIntegration}
              refetch={refetch}
            />
          );
        case IntegrationType.SDK:
        case IntegrationType.CRM:
        case IntegrationType.OBSERVABILITY:
        case IntegrationType.PRODUCT_ANALYTICS:
          return <div>not supported yet</div>;
        default:
          return <div>Unknown integration type</div>;
      }
    },
    [selectedCardType, integrations, refetch]
  );

  return (
    <div
      className={cx(styles.container, {
        [styles.hide]: !show,
      })}
    >
      <Integration token={token ?? ""} />
    </div>
  );
};
