import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export enum EPerformanceType {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
}

export const PerformenceBar = ({
  value,
}: {
  value: { percentage: number; performance: EPerformanceType }[];
}) => {
  return (
    <div className={styles.performance}>
      {value.map(({ percentage, performance }, index) => {
        return (
          <span
            key={index}
            style={{ width: `${percentage}%` }}
            className={cx(styles.performanceItem, {
              [styles.red]: performance === EPerformanceType.RED,
              [styles.yellow]: performance === EPerformanceType.YELLOW,
              [styles.green]: performance === EPerformanceType.GREEN,
            })}
          />
        );
      })}
    </div>
  );
};
