import React from "react";
import { Link2 } from "@geist-ui/icons";

import styles from "./styles.module.scss";
import {
  GeistBadge,
  EBadgeMode,
} from "../../../../components/atoms/geistBadge";

export const SourceItem = ({
  title,
  source,
  url,
  updatedAt,
  precentage,
  isActive,
}: {
  title: string;
  source: string;
  url: string;
  updatedAt: string;
  precentage: number;
  isActive: boolean;
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div>
          {title}
          <GeistBadge
            mode={
              source === "Website Article"
                ? EBadgeMode.PURPLE
                : EBadgeMode.GREEN
            }
          >
            {source}
          </GeistBadge>
          <a href={url} target="blank">
            <Link2 />
          </a>
        </div>
        <div className={styles.time}>Last updated: {updatedAt}</div>
      </div>
      <div className={styles.right}></div>
    </div>
  );
};
