import React from "react";
import cx from "classnames";

import { Toggle } from "../toggle";
import { Typography } from "../typography";
import { mixTrackEvent, ReportEvents } from "../../assets/mixpanel";

import styles from "./styles.module.scss";

export enum ETabs {
  FORM = "Custom Form",
  CONSOLE = "Console",
  NETWORK = "Network",
  SYSTEM_INFO = "System Info",
}

export const Tabs = ({
  activeTab,
  handleChooseTab,
  isPlayMode,
  isForm = false,
  setPlayMode,
}: {
  activeTab: ETabs;
  handleChooseTab: (v: ETabs) => void;
  isPlayMode: boolean;
  isForm?: boolean;
  setPlayMode: (v: boolean) => void;
}) => {
  return (
    <div className={styles.tabsContainer}>
      <span>
        {Object.values(ETabs)
          .filter((tab) => {
            if (!isForm && tab === ETabs.FORM) {
              return false;
            }
            return true;
          })
          .map((tab) => {
            return (
              <Tab
                key={tab}
                label={tab}
                onClick={handleChooseTab}
                isActive={activeTab === tab}
              />
            );
          })}
      </span>
      {[ETabs.CONSOLE, ETabs.NETWORK].includes(activeTab) && (
        <div className={styles.syncPlayCss}>
          <Typography theme="secondary" type="table_body">
            Stream logs in sync
          </Typography>
          <Toggle
            checked={isPlayMode}
            setPressed={(v: boolean) => {
              mixTrackEvent(ReportEvents.REPORT_STREAM_LOGS_IN_SYNC, {
                is_play_mode: v,
              });
              setPlayMode(v);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const Tab = ({
  label,
  isActive = false,
  isNotification = false,
  onClick,
}: {
  label: ETabs;
  isActive?: boolean;
  isNotification?: boolean;
  onClick: (value: ETabs) => void;
}) => {
  return (
    <button
      className={cx(styles.tab, { [styles.active]: isActive })}
      onClick={() => onClick(label)}
    >
      <Typography theme={isActive ? "primary" : "disabled"} type="headline">
        {label}
      </Typography>
      {isNotification && <div className="dotCss" />}
    </button>
  );
};
