import React, { useMemo, useState } from "react";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Text } from "@geist-ui/core";
import { useNavigate } from "react-router-dom";

import { ETimesFilter, TTopicMetrics, TimeFilter } from "../../assets/types";
import { GeistSelect } from "../../components/geistSelect";
import { GDashboardTable } from "../../components/geistTableDashboard";
import { EColumnTypes } from "../../components/geistTableDashboard/index.types";
import { useTicketMetrics } from "../../api/useAssessment";

import styles from "./styles.module.scss";

const topicsColumns = [
  { label: "Topic", value: "name", type: EColumnTypes.NAME },
  {
    label: "Tickets",
    value: "tickets",
    type: EColumnTypes.DEFAULT,
  },
  { label: "Solved", value: "solved", type: EColumnTypes.DEFAULT },
  { label: "Trend", value: "trend", type: EColumnTypes.TREND },
  { label: "Avg. TTR", value: "avgTTR", type: EColumnTypes.TIME },
  { label: "Avg Han. Time", value: "avgHandTime", type: EColumnTypes.TIME },
  {
    label: "Agents Involved",
    value: "agents",
    type: EColumnTypes.DEFAULT,
    dataOptions: { round: true },
  },
];

export const Analysis = () => {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const [timeFilter, setTimeFilter] = useState<{
    value: ETimesFilter;
    label: string;
  }>({
    value: ETimesFilter.ONE_MONTH,
    label: "1 Month",
  });
  const { data, isLoading } = useTicketMetrics(
    timeFilter.value,
    "topics",
    !!timeFilter.value
  );
  const topicResponse = data as TTopicMetrics[];

  const handleChangeFilter = (value: string | string[]) => {
    const tf = TimeFilter.find((tf) => tf.value === value);
    tf && setTimeFilter(tf);
  };

  const topicsArray = useMemo(() => {
    return topicResponse && Array.isArray(topicResponse)
      ? topicResponse
          .map((topic) => ({
            id: topic.topicId.toString(),
            name: topic.topicName,
            tickets: topic.ticketCount.toString(),
            solved: topic.ticketsSolved.toString(),
            trend: topic.compareTicketCount
              ? (
                  (topic.ticketCount - topic.compareTicketCount) /
                  topic.compareTicketCount
                ).toString()
              : "",
            avgTTR: topic.avgResolutionTime.toString(),
            avgHandTime: topic.avgRequesterWaitTime.toString(),
            agents: topic.avgReplies.toString(),
          }))
          .sort((a, b) => parseInt(b.tickets) - parseInt(a.tickets))
      : [];
  }, [topicResponse]);

  return (
    <div className={styles.mainContainer}>
      {!(isLoading || !isAuthenticated) ? (
        <div className={styles.mainHeader}>
          <Text h3>
            <>
              Hi,{" "}
              {user?.nickname
                ? user?.nickname.charAt(0).toUpperCase() +
                  user?.nickname.slice(1)
                : ""}
            </>
          </Text>
          <Text p>Here is a full topics assessment report</Text>
          <GeistSelect
            options={TimeFilter}
            handleChange={handleChangeFilter}
            initialValue={timeFilter}
          />
        </div>
      ) : (
        <div className={styles.mainHeader}>
          <Text
            h3
            style={{
              color: "transparent",
              width: "12%",
              borderRadius: "6px",
            }}
            className={styles.loadHead}
          >
            MOCK
          </Text>
          <Text
            p
            style={{
              color: "transparent",
              width: "20%",
              borderRadius: "6px",
            }}
            className={styles.loadSubhead}
          >
            Here is an assessment report
          </Text>
          <Card className={cx(styles.selectTimeFilter, styles.loadSelect)}>
            MCO
          </Card>
        </div>
      )}
      <GDashboardTable
        dataArray={topicsArray}
        columns={topicsColumns}
        handleRowClick={(id) => navigate(`/assessment/${id}`)}
        selectedTopic={0}
        disableSelected
        loading={isLoading || !isAuthenticated}
        limit={8}
      />
    </div>
  );
};
