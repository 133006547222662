import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import cx from "classnames";
import {
  IoCaretDownOutline,
  IoCheckmark,
  IoCopyOutline,
} from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import ReactJson from "react-json-view";
import Split from "react-split";

import { useWindowSize } from "../../hooks/useWindowSize";
import { Typography } from "../typography";
import { Button } from "../button";
import { RequestParser } from "../network/requestParser";
import { ResponseParser } from "../network/responseParser";

import styles from "./styles.module.scss";

const networkHeader = [
  {
    label: "Log",
    isMain: true,
  },
  // {
  //   label: 'Time',
  //   isMain: false,
  // },
  {
    label: "Status",
    isMain: false,
  },
  {
    label: "Method",
    isMain: false,
  },
  {
    label: "cURL",
    isMain: false,
  },
];

const consoleHeader = [
  {
    label: "Log",
    isMain: true,
  },
  {
    label: "Time",
    isMain: false,
  },
  {
    label: "Src",
    isMain: false,
  },
];

export const Table = ({
  data,
  type,
  isPlayMode = false,
  time,
  newVersion = false,
  startTime,
}: {
  data: any;
  type: "network" | "console";
  isPlayMode?: boolean;
  time: number;
  newVersion?: boolean;
  startTime: number;
}) => {
  const { isMobile } = useWindowSize();

  const columns =
    type === "network"
      ? isMobile
        ? networkHeader.filter((c) => c.label !== "cURL")
        : networkHeader
      : consoleHeader;

  return (
    <Fragment>
      <table className="quack-table">
        <thead>
          <tr className="headerRow">
            {type === "network" && (
              <th style={{ width: "20px", padding: "16px 4px" }} />
            )}
            {columns.map((col, idx) => (
              <th
                key={idx}
                style={{
                  width: col.isMain ? "200px" : "35px",
                  padding: col.isMain ? "16px" : "16px 8px",
                }}
              >
                <Typography type="tab" theme="disabled">
                  {col.label}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr>
              <td colSpan={type === "network" ? 5 : columns?.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Typography type="page_headline" theme="secondary">
                    Seems like no records here
                  </Typography>
                </div>
              </td>
            </tr>
          )}
          {data &&
            typeof data === "object" &&
            data
              ?.sort((a: any, b: any) => {
                if (newVersion) {
                  if (type === "network") {
                    return a.request?.time - b.request?.time;
                  } else {
                    return (
                      dayjs(a.datetime).valueOf() - dayjs(b.datetime).valueOf()
                    );
                  }
                } else {
                  return a.relativeTime - b.relativeTime;
                }
              })
              .filter((r: any) => {
                if (isPlayMode) {
                  if (newVersion) {
                    if (type === "network") {
                      if (r.request.time < startTime + time) {
                        return true;
                      }
                    } else {
                      if (dayjs(r.datetime).valueOf() < startTime + time) {
                        return true;
                      }
                    }
                  } else {
                    if (r.relativeTime - 800 < time * 1000) {
                      return true;
                    }
                  }
                  return false;
                } else {
                  return true;
                }
              })
              .map((row: any, index: number) => {
                return type === "network" ? (
                  <TableRowNetwork
                    isPlayMode={isPlayMode}
                    state={row}
                    key={index}
                  />
                ) : (
                  <TableRowConsole
                    isPlayMode={isPlayMode}
                    state={row}
                    key={index}
                  />
                );
              })}
        </tbody>
      </table>
    </Fragment>
  );
};

export const TableRowNetwork = ({
  state,
  isPlayMode = false,
}: {
  state: any;
  isPlayMode?: boolean;
}) => {
  const [isCopied, setCopied] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const ref = useRef(null);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 800);
    }
  }, [isCopied]);

  useEffect(() => {
    if (rowRef.current && isPlayMode) {
      rowRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const l = JSON.stringify(state).length;

  const handleClick = (log: any) => {
    const url = log.url || log.uri;
    const method = log.method || log.verb;

    let curlText = "curl ";
    curlText += "-X " + method;
    curlText += " '" + url + "'";

    Object.keys(log.headers).forEach((el) => {
      curlText += " -H '" + el + ":" + log.headers[el] + "'";
    });

    if (log.body) {
      const body =
        typeof log.body !== "object" ? JSON.parse(log.body) : log.body;

      curlText += " --data-raw '{";
      Object.keys(body).forEach((key, index) => {
        if (index !== 0) {
          curlText += ",";
        }
        curlText += `"${key}":${JSON.stringify(body[key])}`;
      });
      curlText += "}'";
    }
    curlText = curlText + " --compressed";

    navigator.clipboard.writeText(curlText);
    setCopied(true);
  };

  const [preUrl, url] = useMemo(() => {
    let pre = "";
    let relevantUrl = state?.request?.uri;

    try {
      const { pathname, origin } = new URL(state?.request?.uri);

      relevantUrl = pathname;
      pre = origin;
    } catch (e) {}

    return [pre, relevantUrl];
  }, [state]);

  return (
    <Fragment>
      <tr
        ref={rowRef}
        className={cx("row", { "row-expanded": isExpanded })}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <td className="arrow">
          <IoCaretDownOutline />
        </td>
        <td className="main">
          <label>
            <span style={{ color: "gray" }}>{preUrl}</span>
            <span className={styles.textSecondary} ref={ref}>
              {url}
            </span>
          </label>
        </td>
        <td
          className={`status ${
            state.response.status >= 400 ? "error" : "success"
          }`}
        >
          {state?.response?.status}
        </td>
        <td className="method">{state?.request?.verb}</td>
        <td>
          {isCopied ? (
            <Button size="S" theme="success" previousStyle="outline_secondary">
              <IoCheckmark />
            </Button>
          ) : (
            <>
              <Button
                size="S"
                theme="outline_secondary"
                data-tip
                data-for={state?.request?.uri}
                onClick={() => {
                  handleClick(state.request);
                }}
              >
                <IoCopyOutline />
              </Button>
              <Tooltip place="top" id={state?.request?.uri}>
                <Typography type="table_body" theme="primary">
                  Copy cURL to reporduce it in postman / terminal
                </Typography>
              </Tooltip>
            </>
          )}
        </td>
      </tr>
      <tr
        className={cx("expandableRow", {
          "expandableRow-expanded": isExpanded,
        })}
      >
        <td colSpan={5}>
          {isExpanded && (
            <>
              {l <= 10000 ? (
                <div className="split">
                  <Split
                    sizes={[50, 50]}
                    minSize={50}
                    expandToMin={false}
                    gutterSize={3}
                    gutterAlign="center"
                    snapOffset={30}
                    dragInterval={1}
                    className="split"
                    direction="horizontal"
                    cursor="col-resize"
                  >
                    <CollapsableSection title="Request:">
                      <RequestParser data={state?.request} />
                    </CollapsableSection>
                    <CollapsableSection title="Preview:">
                      <ResponseParser data={state?.response} />
                    </CollapsableSection>
                  </Split>
                </div>
              ) : (
                <div className="flex justify-center">
                  <Typography theme="secondary" type="tab">
                    Sorry size is too big, try to copy the cURL
                  </Typography>
                </div>
              )}
            </>
          )}
        </td>
      </tr>
    </Fragment>
  );
};

export const TableRowConsole = ({
  state,
  isPlayMode,
}: {
  state: any;
  isPlayMode?: boolean;
}) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (rowRef.current && isPlayMode) {
      rowRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr
      ref={rowRef}
      className={cx("consoleRow", {
        warn: state.type === "warn",
        log: state.type === "log",
        error: state.type === "exception" || state.type === "error",
        info: !state.type,
      })}
    >
      <td className="main">
        {typeof state?.value === "string" ? (
          state?.value
        ) : (
          <Fragment>
            {state?.value?.map((v: any, idx: number) => {
              if (typeof v === "object") {
                return (
                  <ReactJson
                    key={idx}
                    src={v}
                    theme="google"
                    indentWidth={4}
                    name={null}
                    style={{ backgroundColor: "unset" }}
                    displayDataTypes={false}
                    collapsed={0}
                  />
                );
              }
              return v;
            })}
          </Fragment>
        )}
      </td>
      <td>{dayjs(state?.datetime).format("HH:mm:ss")}</td>
      <td>{state?.short_url}</td>
    </tr>
  );
};

export const CollapsableSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className={styles.collapsable}>
      <h4>{title}</h4>
      <div>{children}</div>
    </div>
  );
};
