import React, { useEffect, useMemo } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import cx from "classnames";
import { Tabs } from "@geist-ui/core";
import { Link } from "@geist-ui/icons";
import dayjs from "dayjs";
import { createToast } from "vercel-toast";
import { PiThumbsDown, PiThumbsUp } from "react-icons/pi";

import { GeistDrawer } from "../../../../components/geistDrawer";
import {
  EBadgeMode,
  GeistBadge,
} from "../../../../components/atoms/geistBadge";
import { useCommonQuestionInfo } from "../../../../api/useCommonQuestions";
import { TCommonQuestion, TCommonQuestionInfo } from "../../index.types";
import { toCamelCaseWithFirstLetterCaps } from "../../../../assets/functions";
import { EAllTicketsStatus } from "../../../../assets/types";

import styles from "./styles.module.scss";
import { getUrlForTicketSystem } from "../../../../assets/functions/ticketSystems";

export const InfoDrawer = ({
  openDrawer,
  onDrawerClosed,
  questionId,
  question,
}: {
  openDrawer: boolean;
  onDrawerClosed: () => void;
  questionId: number;
  question: TCommonQuestion;
}) => {
  const { data, isLoading, error } = useCommonQuestionInfo(
    String(questionId),
    !!questionId
  );
  const cqInfo = data as TCommonQuestionInfo;

  useEffect(() => {
    if (data?.error || error)
      createToast(`An error occurred while fetching ${question?.name}`, {
        type: "error",
        timeout: 5000,
      });
  }, [data, error, isLoading, question]);

  const load = useMemo(
    () => isLoading || data === undefined,
    [isLoading, data]
  );

  return (
    <GeistDrawer
      headline={
        <>
          <IoInformationCircleOutline />
          Expression
        </>
      }
      openDrawer={openDrawer}
      onDrawerClosed={onDrawerClosed}
    >
      {!load ? (
        <div className={cx(styles.container, "dark_mode")}>
          <div className={styles.upper}>
            <div className={styles.header}>
              <h3>{question?.name}</h3>
              <GeistBadge
                mode={
                  question?.autoResponseEnabled
                    ? EBadgeMode.PRETTY
                    : EBadgeMode.DEFAULT
                }
              >
                {question?.autoResponseEnabled ? "Enabled" : "Disabled"}
              </GeistBadge>
            </div>
            <div className={styles.details}>
              <div className={styles.detail}>
                <h5>Tickets rate</h5>
                <div className={styles.dataRow}>
                  <h4>{cqInfo?.countTicketsLastMonth} </h4>
                  <h5>/month</h5>
                  <h5
                    className={cx(
                      styles.trend,
                      { [styles.green]: cqInfo?.rateOfChange > 0 },
                      { [styles.red]: cqInfo?.rateOfChange < 0 }
                    )}
                  >
                    {cqInfo?.rateOfChange > 0 && "+"}
                    {Math.floor(cqInfo?.rateOfChange)}%
                  </h5>
                </div>
              </div>
              <div className={styles.detail}>
                <h5>Resolution rate</h5>
                <div className={styles.dataRow}>
                  <h4
                    className={cx(
                      {
                        [styles.green]:
                          cqInfo?.precentageOfClosedOrSolvedTickets > 0,
                      },
                      {
                        [styles.red]:
                          cqInfo?.precentageOfClosedOrSolvedTickets < 0,
                      }
                    )}
                  >
                    {cqInfo?.precentageOfClosedOrSolvedTickets > 0 && "+"}
                    {Math.floor(cqInfo?.precentageOfClosedOrSolvedTickets)}%
                  </h4>
                </div>
              </div>
              <div className={styles.detail}>
                <h5>Avg. time to resolve</h5>
                <div className={styles.dataRow}>
                  <h4>
                    {cqInfo?.avgTimeToResolve > 60 * 24
                      ? dayjs
                          .duration(cqInfo?.avgTimeToResolve, "minutes")
                          .format("D[D] H[H]")
                      : dayjs
                          .duration(cqInfo?.avgTimeToResolve, "minutes")
                          .format("H[H] m[M]")}
                  </h4>
                </div>
              </div>
              <div className={styles.detail}>
                <h5>Agents feedback summary</h5>
                <div className={styles.dataRow}>
                  <h4>
                    {cqInfo?.thumbsUpThumbsDownByTickets && (
                      <div className={styles.feedbackRating}>
                        <div className={styles.feedbackRatingItem}>
                          <PiThumbsUp />
                          <span>
                            {
                              Object.values(
                                cqInfo.thumbsUpThumbsDownByTickets
                              ).reduce(
                                (acc, feedback) => {
                                  acc.thumbsUp += feedback.thumbsUp;
                                  acc.thumbsDown += feedback.thumbsDown;
                                  return acc;
                                },
                                { thumbsUp: 0, thumbsDown: 0 }
                              ).thumbsUp
                            }
                          </span>
                        </div>
                        <div
                          className={cx(styles.feedbackRatingItem, styles.down)}
                        >
                          <PiThumbsDown />
                          <span>
                            {
                              Object.values(
                                cqInfo.thumbsUpThumbsDownByTickets
                              ).reduce(
                                (acc, feedback) => {
                                  acc.thumbsUp += feedback.thumbsUp;
                                  acc.thumbsDown += feedback.thumbsDown;
                                  return acc;
                                },
                                { thumbsUp: 0, thumbsDown: 0 }
                              ).thumbsDown
                            }
                          </span>
                        </div>
                      </div>
                    )}
                  </h4>
                </div>
              </div>
              {question?.similarTicket?.description &&
                question?.similarTicket?.subject && (
                  <div className={styles.callout}>
                    <h4>Similar Ticket Subject</h4>
                    <p>{question?.similarTicket?.subject}</p>
                    <h4>Similar Ticket Description</h4>
                    <p>{question?.similarTicket?.description}</p>
                  </div>
                )}
            </div>
          </div>
          <div className={styles.downer}>
            <Tabs initialValue="tickets" className={styles.tabs}>
              <Tabs.Item label="Tickets" value={"tickets"}>
                <div className={styles.cardsContainer}>
                  <h5>
                    Expression is based on {cqInfo?.ticketDetails.length}{" "}
                    tickets
                  </h5>
                  {cqInfo?.ticketDetails?.map((ticket) => {
                    const status = toCamelCaseWithFirstLetterCaps(
                      ticket.status
                    );
                    const mode =
                      status === EAllTicketsStatus.CLOSED ||
                      status === EAllTicketsStatus.SOLVED
                        ? EBadgeMode.GREEN
                        : status === EAllTicketsStatus.DELETE
                        ? EBadgeMode.RED
                        : EBadgeMode.DEFAULT;
                    return (
                      <div className={styles.card} key={ticket.id}>
                        <div className={styles.left}>
                          <div
                            className={styles.cardHead}
                            onClick={() => {
                              const url = getUrlForTicketSystem(ticket.id);
                              window.open(url, "_blank");
                            }}
                          >
                            <span>
                              #{ticket.displayId ?? ticket.id.split("::")[1]}
                            </span>
                            <GeistBadge mode={mode}>{status}</GeistBadge>
                            <Link />
                          </div>
                          <h5>
                            Last updated:{" "}
                            {dayjs(ticket.updatedAt).format("MMMM DD, YYYY")}
                          </h5>
                        </div>
                        {cqInfo.thumbsUpThumbsDownByTickets?.[ticket.id] && (
                          <div className={styles.feedbackRating}>
                            <div className={styles.feedbackRatingItem}>
                              <PiThumbsUp />
                              <span>
                                {
                                  cqInfo.thumbsUpThumbsDownByTickets?.[
                                    ticket.id
                                  ]?.thumbsUp
                                }
                              </span>
                            </div>
                            <div
                              className={cx(
                                styles.feedbackRatingItem,
                                styles.down
                              )}
                            >
                              <PiThumbsDown />
                              <span>
                                {
                                  cqInfo.thumbsUpThumbsDownByTickets?.[
                                    ticket.id
                                  ]?.thumbsDown
                                }
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Tabs.Item>
            </Tabs>
          </div>
        </div>
      ) : (
        <div className={cx(styles.container, styles.loading, "dark_mode")} />
      )}
    </GeistDrawer>
  );
};
