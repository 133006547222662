import React from 'react';

import styles from './styles.module.scss';

export const InfoLine = ({ label, value }: { label: string; value: string }) => {
  return (
    <span className={styles.container}>
      <b>{label}</b>
      <label>{value}</label>
    </span>
  );
};
