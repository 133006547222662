/* eslint-disable no-empty */
/* eslint-disable import/default */

import React, { Fragment } from "react";
import dayjs from "dayjs";
import ReactJson from "react-json-view";

import { IRequest } from "./types";
import styles from "./styles.module.scss";

export const ResponseParser = ({ data }: { data: IRequest }) => {
  let body: any = {};
  let headers: any = {};
  try {
    body =
      typeof data?.body == "string"
        ? data.body
          ? JSON.parse(data?.body)
          : {}
        : data.body;
    headers =
      typeof data?.headers == "string"
        ? data?.headers
          ? JSON.parse(data?.headers as unknown as string)
          : {}
        : data.headers;
  } catch (e) {}

  return (
    <div className={styles.container}>
      <span>
        <b>time:</b>
        <label>{dayjs(data.time).format("HH:mm:ss - MM/DD/YYYY")}</label>
      </span>
      <div className={styles.divider} />
      {headers &&
        Object.keys(headers)?.length === 0 &&
        typeof data.headers === "string" && <span>{data.headers}</span>}
      {headers &&
        Object.keys(headers).map((key) => {
          return (
            <span key={key}>
              <b>{key}:</b>
              <label className={styles.customLabel}>{headers[key]}</label>
            </span>
          );
        })}
      {data.body && (
        <Fragment>
          <div className={styles.divider} />
          <div>
            <ReactJson
              src={body as any}
              theme="google"
              indentWidth={4}
              name={null}
              style={{ backgroundColor: "unset" }}
              displayDataTypes={false}
              collapsed={1}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
