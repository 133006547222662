import { useAuthorizedQuery } from ".";

import { ETimesFilter, getFilteredDate } from "../assets/types";
import { params } from "../params";

export const useKnowledgeCoverageStatus = (
  timeFilter: ETimesFilter,
  enabled: boolean
) => {
  const date = getFilteredDate(timeFilter);

  return useAuthorizedQuery({
    queryKey: `useKnowledgeCoverageStatus-${timeFilter}`,
    url: `${params.API_URL}/platform/dashboard/tickets/coverage-level/count?timeFilter=${date}`,
    method: "GET",
    enabled,
  });
};
