const cleanEmailThread = (text: string) => {
  const emailThreadRegex = /-{2,} Forwarded message -{2,}[\s\S]*$/;
  return text.replace(emailThreadRegex, "").trim();
};

const cleanEmailThreadByDate = (text: string) => {
  const emailThreadByDateRegex =
    /On\s\w{3},\s\w{3}\s\d{1,2},\s\d{4}\sat\s\d{1,2}:\d{2}\s[APM]{2}\s[\s\S]*?wrote:/g;
  const match = text.match(emailThreadByDateRegex);
  return match ? text.substring(0, text.indexOf(match[0])).trim() : text.trim();
};

const splitByEmailHeaders = (text: string) => {
  const emailHeaderRegex = /From:.*\nSent:.*\nTo:.*\nSubject:.*\n\n/;
  const parts = text.split(emailHeaderRegex);
  return parts.length > 0 ? parts[0].trim() : text.trim();
};

const splitBySeparator = (text: string) => {
  const separatorRegex = /-{18,}/;
  const parts = text.split(separatorRegex);
  return parts.length > 0 ? parts[0].trim() : text.trim();
};

const splitByUnderline = (text: string) => {
  const separatorRegex = /_{18,}/;
  const parts = text.split(separatorRegex);
  return parts.length > 0 ? parts[0].trim() : text.trim();
};

const splitByRegards = (text: string) => {
  const regardsRegex = /\bRegards\b/i;
  const parts = text.split(regardsRegex);
  return parts.length > 0 ? parts[0].trim() : text.trim();
};

const removeConfidentialityNotice = (text: string) => {
  const confidentialityNoticeRegex =
    /This email is a confidential communication \./g;
  return text.replace(confidentialityNoticeRegex, "").trim();
};

const cleanChain = [
  cleanEmailThread,
  cleanEmailThreadByDate,
  splitByEmailHeaders,
  splitBySeparator,
  splitByUnderline,
  splitByRegards,
  removeConfidentialityNotice,
];

export const cleanTickets = (description: string) => {
  cleanChain.forEach((cleanFunc) => {
    description = cleanFunc(description);
  });
  return description;
};
