import React, { useEffect, useState } from "react";
import { Card, Text } from "@geist-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import cx from "classnames";
import dayjs from "dayjs";

import { InfoCard } from "../../../components/infoCard";
import {
  TimeFilter,
  ETimesFilter,
  TDashboardTicketMetrics,
} from "../../../assets/types";
import { GeistSelect } from "../../../components/geistSelect";
import { AutomationStatus } from "../../assessment/components/automationStatus";
import { KnowledgeCoverage } from "../../assessment/components/knowledgeConerage";
// import { TopKnowledgeGaps } from "../../assessment/components/topKnowledgeGaps";

import useMetrics from "./useMetrics";
import styles from "./styles.module.scss";
import { useFeatureFlag } from "configcat-react";

interface AssessmentDetailsProps {
  metrics: TDashboardTicketMetrics;
  timeFilter: {
    value: ETimesFilter;
    label: string;
  };
  handleChangeFilter: (value: string | string[]) => void;
  loadingMetrics: boolean;
}

export const AssessmentDetails: React.FC<AssessmentDetailsProps> = ({
  metrics,
  timeFilter,
  handleChangeFilter,
  loadingMetrics,
}) => {
  const { user } = useAuth0();

  const { value: dashboardComponents } = useFeatureFlag(
    "dashboardComponents",
    "",
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const componentsToPresent = dashboardComponents.split(",");

  const {
    ticketCount,
    compareTicketCount,
    ttr,
    compareTtr,
    handTime,
    compareHandtime,
    backAndForth,
    compareBackAndForth,
  } = useMetrics(metrics, loadingMetrics);

  const [barsCardHeight, setBarsCardHeight] = useState(0);

  useEffect(() => {
    if (barsCardHeight !== 0) return;

    let attempts = 0;
    const interval = setInterval(() => {
      const element = document.getElementById("tableCard");
      if (element?.clientHeight || attempts >= 5) {
        clearInterval(interval);
        setBarsCardHeight(element?.clientHeight || 0);
      } else {
        attempts++;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [barsCardHeight]);

  return (
    <div className={styles.mainContainer}>
      {!user?.nickname ? (
        <div className={styles.mainHeader}>
          <Text
            h3
            style={{
              color: "transparent",
              width: "12%",
              borderRadius: "6px",
            }}
            className={styles.loadHead}
          >
            MOCK
          </Text>
          <Text
            p
            style={{
              color: "transparent",
              width: "20%",
              borderRadius: "6px",
            }}
            className={styles.loadSubhead}
          >
            Here is an assessment report
          </Text>
          <Card className={cx(styles.selectTimeFilter, styles.loadSelect)}>
            MCO
          </Card>
        </div>
      ) : (
        <div className={styles.mainHeader}>
          <Text h3 style={{ color: "white" }}>
            <>
              Hi,{" "}
              {user?.nickname
                ? user?.nickname.charAt(0).toUpperCase() +
                  user?.nickname.slice(1)
                : ""}
            </>
          </Text>
          <Text p style={{ color: "white" }}>
            Here is an assessment report
          </Text>
          <GeistSelect
            options={TimeFilter}
            handleChange={handleChangeFilter}
            initialValue={timeFilter}
          />
        </div>
      )}

      {(componentsToPresent.includes("overview") ||
        componentsToPresent.includes("autonomous")) && (
        <div className={styles.container}>
          {componentsToPresent.includes("overview") && (
            <div className={styles.cardsContainer}>
              <InfoCard
                loading={loadingMetrics}
                title="Tickets"
                tooltipText={`tickets in the time period selected`}
                percentage={compareTicketCount}
              >
                {ticketCount}
              </InfoCard>
              <InfoCard
                loading={loadingMetrics}
                title="Avg. TTR"
                tooltipText="time from ticket creation to resolution"
                percentage={compareTtr}
                lessIsGood
              >
                {ttr > 60
                  ? dayjs.duration(ttr, "minutes").format("D [days] H [hours]")
                  : dayjs
                      .duration(ttr, "minutes")
                      .format("H [hours] m [minutes]")}
              </InfoCard>
              <InfoCard
                loading={loadingMetrics}
                title="Avg. handling time"
                tooltipText="time user waited for responses from agent"
                percentage={compareHandtime}
                lessIsGood
              >
                {handTime > 60 * 24
                  ? dayjs
                      .duration(handTime, "minutes")
                      .format("D [days] H [hours]")
                  : dayjs
                      .duration(handTime, "minutes")
                      .format("H [hours] m [minutes]")}
              </InfoCard>
              <InfoCard
                loading={loadingMetrics}
                title="Avg. back and forth"
                tooltipText="the number of interactions between user and agent"
                percentage={compareBackAndForth}
                lessIsGood
              >
                {backAndForth.toFixed(2)}
              </InfoCard>
            </div>
          )}
          {componentsToPresent.includes("autonomous") && (
            <AutomationStatus timeFilter={timeFilter} />
          )}
        </div>
      )}
      {componentsToPresent.includes("knowledge") && (
        <div className={styles.container}>
          <KnowledgeCoverage timeFilter={timeFilter} />
          {/* <TopKnowledgeGaps /> */}
        </div>
      )}
    </div>
  );
};
