import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import {
  PinRightIcon,
  PinLeftIcon,
  FileTextIcon,
  PersonIcon,
  TimerIcon,
  Pencil2Icon,
  MagicWandIcon,
} from "@radix-ui/react-icons";

import { CiFaceSmile, CiFaceMeh, CiFaceFrown } from "react-icons/ci";

import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../api/useOrganizations";
import { ChevronLeft } from "@geist-ui/icons";
import { Organization } from "./types";
import { Text, IconButton, Card, Table, Skeleton } from "@radix-ui/themes";

import { Chart } from "./trend";
import styles from "./styles.module.scss";

const CircularProgress = ({ progress }: { progress: number }) => {
  const radius = 14;
  const stroke = 2;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = (progress / 100) * circumference - circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      className="block mx-auto"
      style={{ transform: "rotate(-90deg)" }}
    >
      <circle
        stroke="currentColor"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        className={cx({
          [styles.green]: progress >= 70,
          [styles.yellow]: progress < 70 && progress >= 30,
          [styles.red]: progress < 30,
        })}
      />
    </svg>
  );
};

export const Customer = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useOrganization({
    enabled: !!organizationId,
    organizationId: organizationId ?? "",
  });

  const [isExpanded, setExplanded] = useState(true);

  const [bouncedValue, setBouncedValue] = useState(isExpanded);
  const [bouncedValue250, setBouncedValue250] = useState(isExpanded);

  useEffect(() => {
    const handler = setTimeout(() => {
      setBouncedValue(isExpanded);
    }, 180);

    return () => {
      clearTimeout(handler);
    };
  }, [isExpanded]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setBouncedValue250(isExpanded);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [isExpanded]);

  const organization: Organization | null = data ? data.organization : null;

  const avgFullResolutionTimeBusiness =
    (organization?.tickets?.reduce((acc, ticket) => {
      if (ticket.ticketMetrics?.fullResolutionTimeBusiness) {
        return acc + ticket.ticketMetrics.fullResolutionTimeBusiness;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);
  const avgHandlingTimeBusiness =
    (organization?.tickets?.reduce((acc, ticket) => {
      if (ticket.ticketMetrics?.requesterWaitTimeBusiness) {
        return acc + ticket.ticketMetrics.requesterWaitTimeBusiness;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);

  const avgReplies =
    (organization?.tickets?.reduce((acc, ticket) => {
      if (ticket.ticketMetrics?.replies) {
        return acc + ticket.ticketMetrics.replies;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);

  const formatTime = (timeInMinutes: number) => {
    const days = Math.floor(timeInMinutes / (60 * 24));
    const hours = Math.floor((timeInMinutes % (60 * 24)) / 60);
    const minutes = Math.floor(timeInMinutes % 60);

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    return parts.join(" ");
  };

  const avgFullResolutionTimeBusinessString = formatTime(
    avgFullResolutionTimeBusiness
  );

  const avgHandlingTimeBussinessString = formatTime(avgHandlingTimeBusiness);

  const closedTickets =
    organization?.tickets.filter((ticket) =>
      ["closed", "solved", "deleted"].includes(ticket.status)
    ).length || 0;

  const agentAssignedCount =
    organization?.tickets.reduce((acc: any, ticket) => {
      if (ticket.agentAssignedId) {
        acc[ticket.agentAssignedId] = (acc[ticket.agentAssignedId] || 0) + 1;
      }
      return acc;
    }, {}) || {};

  const ticketsByCategoryAndTopic =
    organization?.tickets.reduce((acc: any, ticket) => {
      const category = ticket.category?.name || "other";
      const topic = ticket.topic?.name || "other";
      if (!acc[category]) {
        acc[category] = {};
      }
      if (!acc[category][topic]) {
        acc[category][topic] = [];
      }
      acc[category][topic].push(ticket);
      return acc;
    }, {}) || {};

  const totalAgents = Object.keys(agentAssignedCount).length;

  const ticketsByTenantUserId =
    organization?.tickets.reduce((acc: any, ticket) => {
      const tenantUserId = ticket.tenantUserId || "unknown";
      acc[tenantUserId] = (acc[tenantUserId] || 0) + 1;
      return acc;
    }, {}) || {};

  const totalUsers = Object.keys(ticketsByTenantUserId).length;
  const ticketsByMonth = Object.entries(
    organization?.tickets.reduce((acc: any, ticket) => {
      const month = new Date(ticket.createdAt).getMonth() + 1;
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {}) || {}
  ).map(([month, count]) => ({ month: Number(month), count: count as number }));

  const avgSentiment = useMemo(() => {
    if (!organization?.tickets || organization.tickets.length === 0) return 0;
    const totalSentiment = organization.tickets.reduce((acc, ticket) => {
      const ticketSentiment = ticket.insights.reduce((insightAcc, insight) => {
        return insightAcc + insight.sentiment;
      }, 0);
      return acc + ticketSentiment;
    }, 0);
    return totalSentiment / organization.tickets.length;
  }, [organization?.tickets]);

  const avgQAScore = useMemo(() => {
    if (!organization?.tickets || organization.tickets.length === 0) return 0;
    const { totalQAScore, ticketCount } = organization.tickets.reduce(
      (acc, ticket) => {
        if (ticket.ticketScore.length > 0) {
          const ticketQAScore = ticket.ticketScore.reduce((scoreAcc, score) => {
            return scoreAcc + score.autoScore;
          }, 0);
          acc.totalQAScore += ticketQAScore;
          acc.ticketCount += 1;
        }
        return acc;
      },
      { totalQAScore: 0, ticketCount: 0 }
    );
    return ticketCount > 0 ? totalQAScore / ticketCount / 5 : 0;
  }, [organization?.tickets]);

  //   const ticketsByCategory =
  //     organization?.tickets.reduce((acc: any, ticket) => {
  //       const category = ticket.category?.name || "other";
  //       acc[category] = (acc[category] || 0) + 1;
  //       return acc;
  //     }, {}) || {};

  //   const categoryList = Object.entries(ticketsByCategory).map(
  //     ([category, count]) => (
  //       <li key={category}>
  //         {category} - {count as number}
  //       </li>
  //     )
  //   );

  //   const ticketsByTopic =
  //     organization?.tickets.reduce((acc: any, ticket) => {
  //       const topic = ticket.topic?.name || "other";
  //       acc[topic] = (acc[topic] || 0) + 1;
  //       return acc;
  //     }, {}) || {};

  //   const top3Topics = Object.entries(ticketsByTopic)
  //     .sort(([, a]: any, [, b]: any) => b - a)
  //     .slice(0, 3)
  //     .map(([topic, count]) => ({ topic, count: Number(count) }));

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.header}>
          <div>
            <IconButton
              variant="ghost"
              color="gray"
              onClick={() => navigate("/customers")}
            >
              <ChevronLeft />
            </IconButton>
            <Text size="8">
              {isLoading ? (
                <Skeleton>{"organization?.name"}</Skeleton>
              ) : (
                organization?.name
              )}
            </Text>
          </div>
          {!bouncedValue250 && (
            <IconButton
              variant="ghost"
              color="gray"
              onClick={() => setExplanded(true)}
            >
              <PinLeftIcon />
            </IconButton>
          )}
        </div>
        {isLoading && (
          <div className={styles.bodyContainer}>
            <div className={styles.topContainer}>
              <Card>
                <div className={styles.cardHeader}>
                  <Text weight={"bold"} size="2">
                    Tickets resolved
                  </Text>
                  <div>
                    <Skeleton>
                      <CircularProgress progress={20 * 100} />
                      {(20 * 100).toFixed(0)}%
                    </Skeleton>
                  </div>
                </div>
                <div>
                  <Text weight={"bold"} size="2">
                    <Skeleton>{closedTickets}</Skeleton>
                  </Text>
                </div>
                <Text color="gray" size="1">
                  <Skeleton>Out of 20</Skeleton>
                </Text>
              </Card>
              <Card>
                <div className={styles.cardHeader}>
                  <Text weight={"bold"} size="2">
                    Agents evaluated
                  </Text>
                  <div>
                    <Skeleton>
                      <CircularProgress progress={20} />
                      {(2 * 100).toFixed(0)}%
                    </Skeleton>
                  </div>
                </div>
                <div>
                  <Text weight={"bold"} size="2">
                    <Skeleton>{"totalAgents"}</Skeleton>
                  </Text>
                </div>
                <Text color="gray" size="1">
                  <Skeleton>Out of 2</Skeleton>
                </Text>
              </Card>
            </div>

            <div>
              <>
                <Table.Root variant="surface">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>
                        Feature requests
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell># Tickets</Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {Array.from({ length: 5 }).map((_, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.RowHeaderCell>
                            <Skeleton>
                              {"topic topic topic topic topic"}
                            </Skeleton>
                          </Table.RowHeaderCell>
                          <Table.Cell>
                            <Skeleton>{"20"}</Skeleton>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </>
            </div>
            <div>
              <>
                <Table.Root variant="surface">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColumnHeaderCell>
                        Feature requests
                      </Table.ColumnHeaderCell>
                      <Table.ColumnHeaderCell># Tickets</Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {Array.from({ length: 5 }).map((_, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.RowHeaderCell>
                            <Skeleton>
                              {"topic topic topic topic topic"}
                            </Skeleton>
                          </Table.RowHeaderCell>
                          <Table.Cell>
                            <Skeleton>{"20"}</Skeleton>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </>
            </div>
          </div>
        )}
        {organization && (
          <div className={styles.bodyContainer}>
            <div className={styles.topContainer}>
              <Card>
                <div className={styles.cardHeader}>
                  <Text weight={"bold"} size="2">
                    Tickets resolved
                  </Text>
                  <div>
                    <CircularProgress
                      progress={
                        (closedTickets / organization?.tickets.length) * 100
                      }
                    />
                    {(
                      (closedTickets / organization?.tickets.length) *
                      100
                    ).toFixed(0)}
                    %
                  </div>
                </div>
                <div>
                  <Text weight={"bold"} size="2">
                    {closedTickets}
                  </Text>
                </div>
                <Text color="gray" size="1">
                  Out of {organization?.tickets.length}
                </Text>
              </Card>
              <Card>
                <div className={styles.cardHeader}>
                  <Text weight={"bold"} size="2">
                    Agents evaluated
                  </Text>
                  <div>
                    <CircularProgress
                      progress={(totalAgents / totalAgents) * 100}
                    />
                    {((totalAgents / totalAgents) * 100).toFixed(0)}%
                  </div>
                </div>
                <div>
                  <Text weight={"bold"} size="2">
                    {totalAgents}
                  </Text>
                </div>
                <Text color="gray" size="1">
                  Out of {totalAgents}
                </Text>
              </Card>
            </div>

            <div>
              {ticketsByCategoryAndTopic && (
                <>
                  <Table.Root variant="surface">
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeaderCell>
                          Feature requests
                        </Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>
                          # Tickets
                        </Table.ColumnHeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {Object.keys(
                        ticketsByCategoryAndTopic?.feature_request ?? {}
                      )?.map((topic) => {
                        return (
                          <Table.Row>
                            <Table.RowHeaderCell>{topic}</Table.RowHeaderCell>
                            <Table.Cell>
                              {
                                ticketsByCategoryAndTopic.feature_request[topic]
                                  .length
                              }
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table.Root>
                </>
              )}
            </div>
            <div>
              {ticketsByCategoryAndTopic && (
                <>
                  <Table.Root variant="surface">
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeaderCell>Bugs</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>
                          # Tickets
                        </Table.ColumnHeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {Object.keys(ticketsByCategoryAndTopic?.bug ?? {})?.map(
                        (topic) => {
                          return (
                            <Table.Row>
                              <Table.RowHeaderCell>{topic}</Table.RowHeaderCell>
                              <Table.Cell>
                                {ticketsByCategoryAndTopic.bug[topic].length}
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                    </Table.Body>
                  </Table.Root>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className={cx(styles.sidePanel, { [styles.collapsed]: !isExpanded })}
      >
        {bouncedValue && (
          <div className={styles.sidePanelContainer}>
            <div className={styles.sidePanelHeader}>
              <IconButton
                variant="ghost"
                color="gray"
                onClick={() => setExplanded(false)}
              >
                <PinRightIcon />
              </IconButton>
            </div>

            <div className={styles.sidePanelTitle}>
              <Text color="gray">Overview</Text>
            </div>
            <div className={styles.sidePanelGrid}>
              <div className={styles.metricCard}>
                <Text weight="bold">Tickets</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>
                      <FileTextIcon />
                      2000
                    </Skeleton>
                  ) : (
                    <>
                      <FileTextIcon />
                      {organization?.tickets.length}
                    </>
                  )}
                </Text>
              </div>
              <div className={cx(styles.metricCard, styles.no_gap)}>
                <Text weight="bold">Trend</Text>
                {isLoading ? (
                  <Skeleton>chart</Skeleton>
                ) : (
                  <Chart data={ticketsByMonth} />
                )}
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Users involved</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>
                      <PersonIcon />
                      30
                    </Skeleton>
                  ) : (
                    <>
                      <PersonIcon />
                      {totalUsers}
                    </>
                  )}
                </Text>
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Avg. TTR</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>
                      <TimerIcon />
                      20:20:20
                    </Skeleton>
                  ) : (
                    <>
                      <TimerIcon />
                      {avgFullResolutionTimeBusinessString}
                    </>
                  )}
                </Text>
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Avg. handling time</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>
                      <TimerIcon />
                      20:20:20
                    </Skeleton>
                  ) : (
                    <>
                      <TimerIcon />
                      {avgHandlingTimeBussinessString}
                    </>
                  )}
                </Text>
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Avg. QA Score</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  <MagicWandIcon />
                  {isLoading ? (
                    <Skeleton>3.00</Skeleton>
                  ) : (
                    avgQAScore.toFixed(2)
                  )}
                  / 5
                </Text>
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Avg. sentiment</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>All good</Skeleton>
                  ) : (
                    <>
                      {avgSentiment > 0.5 ? (
                        <>
                          <CiFaceSmile />
                          All good
                        </>
                      ) : avgSentiment > -0.3 ? (
                        <>
                          <CiFaceMeh />
                          Could be better
                        </>
                      ) : (
                        <>
                          <CiFaceFrown />
                          Attention required
                        </>
                      )}
                    </>
                  )}
                </Text>
              </div>
              <div className={styles.metricCard}>
                <Text weight="bold">Avg. back and forth</Text>
                <Text
                  color="gray"
                  weight="light"
                  className={styles.textContainer}
                >
                  {isLoading ? (
                    <Skeleton>3.02</Skeleton>
                  ) : (
                    <>
                      <Pencil2Icon />
                      {avgReplies.toFixed(2)}
                    </>
                  )}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
