import { useEffect } from "react";
import { createToast } from "vercel-toast";

export const useFetchingToasts = ({
  error,
  emptyState,
  errorMessage = "An error occurred while fetching data",
  emptyStateMessage = "No data found",
}: {
  error: boolean;
  emptyState: boolean;
  errorMessage?: string;
  emptyStateMessage?: string;
}) => {
  useEffect(() => {
    if (error)
      createToast(errorMessage, {
        type: "error",
        timeout: 5000,
      });
    else if (emptyState)
      createToast(emptyStateMessage, {
        type: "warning",
        timeout: 5000,
      });
  }, [error, emptyState, errorMessage, emptyStateMessage]);

  return { error, emptyState };
};
