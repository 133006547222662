import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import cx from "classnames";
import { MdExpandLess, MdExpandMore, MdRefresh } from "react-icons/md";

import { useInterval } from "../../hooks/useInterval";

import { useRegenerateInsight, useTickets } from "./api/useTickets";
import styles from "./styles.module.scss";

enum EStep {
  PENDING = "pending",
  SUCCESS = "success",
  IN_PROGRESS = "in_progress",
}

const PAGE_LIMIT = 25;

export const Supervision = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [owner, setOwner] = useState("");
  const [tickets, setTickets] = useState<any[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [isLoadMore, setLoadMore] = useState(true);
  const [lastId, setLastId] = useState<string>();

  useEffect(() => {
    (async () => {
      getIdTokenClaims().then((claims) => {
        setOwner(claims?.["owner"]);
      });
    })();
  }, [getIdTokenClaims, user]);

  const { data, refetch, isLoading, isRefetching } = useTickets(
    owner,
    user !== null && owner !== "",
    PAGE_LIMIT,
    isLoadMore ? nextToken : null
  );

  useEffect(() => {
    if (
      data?.ticketsByOwnerAndCreatedAt?.items &&
      data?.ticketsByOwnerAndCreatedAt?.items.length
    ) {
      const ids: string[] = tickets.map((ticket) => ticket.id);
      const newTickets = data?.ticketsByOwnerAndCreatedAt?.items.filter(
        (item: any) => !ids.includes(item.id)
      );
      if (!lastId) {
        setTickets([...tickets, ...newTickets]);
        !data?.ticketsByOwnerAndCreatedAt?.nextToken &&
          setLastId(ids.slice(-1)[0]);
        if (isLoadMore) {
          setNextToken(data?.ticketsByOwnerAndCreatedAt?.nextToken);
        }
      } else {
        let _tickets = [...newTickets, ...tickets];
        const updatedTickets = data?.ticketsByOwnerAndCreatedAt?.items.slice(
          newTickets.length
        );
        let updatedTicketsMap = new Map();
        updatedTickets.forEach((ticket: { id: any }) =>
          updatedTicketsMap.set(ticket.id, ticket)
        );
        _tickets = _tickets.map(
          (ticket) => updatedTicketsMap.get(ticket.id) || ticket
        );
        setTickets(_tickets);
      }
      setLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useInterval(() => !isRefetching && refetch(), 1000 * 15);

  const loadMoreTickets = () => {
    setLoadMore(true);
  };

  useEffect(() => {
    isLoadMore && refetch();
  }, [isLoadMore, refetch]);

  return (
    <div className={styles.container}>
      <label className={styles.label}>
        {isLoading || isRefetching ? "loading..." : "updated"}
      </label>
      <div className={styles.list}>
        {tickets?.map((t: any) => {
          return <Item key={t.id} ticket={t} />;
        })}
        {data?.ticketsByOwnerAndCreatedAt?.nextToken && !lastId && (
          <button
            className={styles.loadMore}
            onClick={loadMoreTickets}
            disabled={isRefetching}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

const InsightCreatedStep = ({
  ticket,
  isExpanded,
}: {
  ticket: any;
  isExpanded: boolean;
}) => {
  const { mutate, isLoading } = useRegenerateInsight();

  const insights = ticket.insights.items?.[0];

  return (
    <div className={styles.insightsStep}>
      {insights ? (
        <div className={styles.insightContianer}>
          <div className={styles.insightMetadata}>
            <span className={styles.tag}>
              {insights.ai_suggestions.priority}
            </span>
            {insights.rankings.items?.[0] && (
              <span>
                Ranked: {insights.rankings.items?.[0].rank} /{" "}
                {insights.rankings.items?.[0].max}
              </span>
            )}
          </div>
          {isExpanded && <div>{insights.ai_suggestions.reply}</div>}
        </div>
      ) : (
        <>
          <button
            disabled={isLoading}
            className={styles.refreshBtn}
            onClick={() => {
              mutate(
                {
                  ticketId: ticket.id,
                },
                {
                  onSuccess: (s) => {
                    console.log({ s });
                  },
                  onError: (err) => {
                    console.log({ err });
                  },
                }
              );
            }}
          >
            <MdRefresh />
          </button>
        </>
      )}
    </div>
  );
};

const TicketCreatedStep = ({
  ticket,
  isExpanded,
}: {
  ticket: any;
  isExpanded: boolean;
}) => {
  return (
    <div className={styles.openStepContainer}>
      #{ticket.platformId}{" "}
      <span className={styles.openStepDate}>
        {dayjs(ticket.createdAt).format("HH:mm, DD/MM")}
      </span>
      {isExpanded && (
        <div>
          <h5>{ticket.subject}</h5>
          <p>{ticket.description}</p>
        </div>
      )}
    </div>
  );
};

// should be id, source/platform and created at
const steps = [
  {
    name: "ticket created",
    status: (_: any) => EStep.SUCCESS,
    component: TicketCreatedStep,
  },
  // should be priority, suggested assignee, ttr
  {
    name: "insight generated",
    status: (ticket: any) => {
      if (ticket.insights.items.length === 0) return EStep.IN_PROGRESS;
      else return EStep.SUCCESS;
    },
    component: InsightCreatedStep,
  },
  // {
  //   name: "user viewed",
  //   status: EStep.PENDING,
  // },
  // {
  //   name: "response sent",
  //   status: EStep.PENDING,
  // },
  {
    // should be right TTR, quack's saved TTR, closed by
    name: "ticket closed",
    status: (ticket: any) => {
      if (ticket.status === "closed") return EStep.SUCCESS;
      if (ticket.status === "solved") return EStep.SUCCESS;
      if (ticket.insights.items.length === 0) return EStep.IN_PROGRESS;
      return EStep.PENDING;
    },
    component: () => <div></div>,
  },
];

const Item = ({ ticket }: { ticket: any }) => {
  const [isExpanded, setExpanded] = useState(false);
  const ticketId = ticket.id.split("::")[1];

  const expandToggle = () => setExpanded((prev) => !prev);

  return (
    <div className={styles.item}>
      {steps.map((step, i) => {
        return (
          <Step
            ticket={{ ...ticket, platformId: ticketId }}
            isExpanded={isExpanded}
            key={`${ticket.id}-${step.name}`}
            step={step}
            isFirst={i === 0}
          />
        );
      })}
      <button className={styles.expandBtn} onClick={expandToggle}>
        {isExpanded ? (
          <>
            Collapse <MdExpandLess />
          </>
        ) : (
          <>
            Expand <MdExpandMore />
          </>
        )}
      </button>
    </div>
  );
};

const Step = ({
  ticket,
  step,
  isFirst = false,
  isExpanded,
}: {
  ticket: any;
  step: any;
  isFirst?: boolean;
  isExpanded?: boolean;
}) => {
  const status = step.status(ticket);

  const Component = step.component;
  return (
    <>
      <div
        className={cx(styles.step, {
          [styles.success]: status === EStep.SUCCESS,
          [styles.inProgress]: status === EStep.IN_PROGRESS,
          [styles.pending]: status === EStep.PENDING,
        })}
      >
        {isFirst ? null : <StepConnector status={status} />}
        <label>{step.name}</label>
        <div>
          <Component ticket={ticket} isExpanded={isExpanded} />
        </div>
      </div>
    </>
  );
};

const StepConnector = ({ status }: { status: EStep }) => {
  return (
    <div
      className={cx(styles.stepConnector, {
        [styles.success]: status === EStep.SUCCESS,
        [styles.inProgress]: status === EStep.IN_PROGRESS,
        [styles.pending]: status === EStep.PENDING,
      })}
    ></div>
  );
};
