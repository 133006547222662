import React, { Fragment, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Card } from "@geist-ui/core";
import cx from "classnames";
import { useDashboardCounts } from "../../../../api/useVoc";
import { GeistBadge } from "../../../../components/atoms/geistBadge";
import { ETimesFilter } from "../../../../assets/types";
import { ECategories } from "../../voc.types";
import { ChevronDown, ChevronUp } from "@geist-ui/icons";

interface TopicsToCategories {
  [topic: string]: {
    feature_request: number;
    bugs: number;
    general_request: number;
    feedback: number;
    troubleshooting: number;
    bug: number;
  };
}

interface DataStructure {
  mapTopicsToCategories: TopicsToCategories;
  isThereNextPage: boolean;
}
const categories = [
  {
    key: "Information/Education",
    value: "information_or_education",
    colorClass: "information_or_education",
  },
  { key: "Requests", value: "general_request", colorClass: "general_request" },
  {
    key: "Feature request",
    value: "feature_request",
    colorClass: "feature_request",
  },
  { key: "Feedback", value: "feedback", colorClass: "feedback" },
  {
    key: "TroubleShooting",
    value: "troubleshooting",
    colorClass: "troubleshooting",
  },
  { key: "Bug", value: "bug", colorClass: "bug" },
];

export const Bars = ({
  categoriesFilter,
  timeFilter,
}: {
  categoriesFilter: string[];
  timeFilter: ETimesFilter;
}) => {
  const [showMore, setShowMore] = useState(false); // indicates if the show more button was clicked
  const categoriesFilterData =
    categoriesFilter.length > 0
      ? categoriesFilter
      : (Object.values(ECategories) as string[]);
  const categoryValues = categories
    .filter((c) => categoriesFilterData.includes(c.value))
    .map((category) => category.value);
  const categoriesKeysAndColors = categories
    .filter((c) => categoriesFilterData.includes(c.value))
    .map((category) => {
      return {
        key: category.key,
        colorClass: category.colorClass,
        value: category.value,
      };
    });
  const { data, isLoading } = useDashboardCounts(
    categoryValues,
    true,
    timeFilter
  );
  const dataType = data as DataStructure;

  const structedData = useMemo(() => {
    if (!dataType || !dataType?.mapTopicsToCategories)
      return { ticketCount: 0, structuredTickets: [], ticketByTopicCount: [] };
    const ticketCount = Object.values(dataType?.mapTopicsToCategories).reduce(
      (total, topic) => {
        return Object.values(topic).reduce((sum, count) => sum + count, total);
      },
      0
    );

    const ticketByTopicCount = Object.entries(dataType.mapTopicsToCategories)
      .map(([topic, counts]) => ({
        topic,
        count: Object.values(counts).reduce((sum, count) => sum + count, 0),
      }))
      .sort((a, b) => b.count - a.count);

    let structuredTickets = Object.entries(dataType.mapTopicsToCategories).map(
      ([topic, counts]) => ({
        topic,
        tickets: Object.fromEntries(
          Object.entries(counts).filter(([key, value]) => value !== 0)
        ),
      })
    );
    let isBiggestLessThan50Precent =
      ticketCount > 0 && ticketByTopicCount[0].count / ticketCount < 0.5
        ? 2
        : 1;
    if (!showMore) {
      structuredTickets = ticketByTopicCount.slice(0, 5).map((topic) => {
        return {
          topic: topic.topic,
          tickets: Object.fromEntries(
            Object.entries(dataType.mapTopicsToCategories[topic.topic]).filter(
              ([key, value]) => value !== 0
            )
          ),
        };
      });

      return {
        ticketCount,
        structuredTickets,
        ticketByTopicCount: ticketByTopicCount.slice(0, 5),
        isBiggestLessThan50Precent,
      };
    }
    structuredTickets.sort((a, b) => {
      const countA = ticketByTopicCount.findIndex(
        (topic) => topic.topic === a.topic
      );
      const countB = ticketByTopicCount.findIndex(
        (topic) => topic.topic === b.topic
      );
      return countA - countB;
    });
    return {
      ticketCount,
      structuredTickets,
      ticketByTopicCount,
      isBiggestLessThan50Precent,
    };
  }, [dataType, showMore]);

  const load = useMemo(() => {
    return isLoading || !data;
  }, [isLoading, data]);

  return !load ? (
    <Card
      style={{ borderRadius: "12px" }}
      hoverable
      id="tableCard"
      className={styles.dashboardTable}
    >
      <div className={styles.container}>
        <div className={styles.smallerContainer}>
          <div className={styles.leftContainer}>
            {structedData.structuredTickets.map((item, index) => (
              <div key={index} className={styles.row}>
                <div className={styles.category}>
                  <GeistBadge> {item.topic}</GeistBadge>
                </div>
                <div className={styles.bars}>
                  {categoriesKeysAndColors.map((category, index) => {
                    const percentage =
                      structedData.ticketCount === 0
                        ? 0
                        : (Number(
                            structedData.structuredTickets.find(
                              (c) => c?.topic === item?.topic
                            )?.tickets[category?.value] ?? 0
                          ) /
                            Number(structedData.ticketCount)) *
                          100;

                    return item.tickets[category.value] > 0 ? (
                      <Fragment>
                        {
                          <div
                            key={index}
                            className={cx(
                              styles[category.colorClass],
                              styles.bar
                            )}
                            style={{
                              width: `${
                                percentage > 0
                                  ? (
                                      (structedData.isBiggestLessThan50Precent ||
                                        1) * percentage
                                    ).toFixed(0)
                                  : 1 *
                                    (structedData.isBiggestLessThan50Precent ||
                                      1)
                              }%`,
                            }}
                          />
                        }
                      </Fragment>
                    ) : (
                      <></>
                    );
                  })}
                  <span className={styles.percentage}>
                    {structedData.ticketCount > 0
                      ? (
                          ((structedData.ticketByTopicCount.find(
                            (c) => c.topic === item.topic
                          )?.count || 0) /
                            structedData.ticketCount) *
                          100
                        ).toFixed(0)
                      : 0}
                    %
                  </span>{" "}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.rightContainer}>
            {categoriesKeysAndColors.map((category, index) => (
              <div key={index} className={styles.labelItem}>
                <span
                  className={`${styles.colorBox} ${
                    styles[category.colorClass]
                  }`}
                ></span>
                <div>{category.key}</div>
              </div>
            ))}
          </div>
        </div>
        {!showMore && (
          <div className={styles.showMore}>
            <span />
            <button
              onClick={() => {
                setShowMore(!showMore);
              }}
              className={cx({ [styles.showMore]: !showMore })}
            >
              Show {!showMore ? "More" : "Less"}
              <ChevronUp />
            </button>
            <span />
          </div>
        )}
        {showMore && (
          <div className={styles.showMore}>
            <span />
            <button
              onClick={() => {
                setShowMore(!showMore);
              }}
              className={cx({ [styles.showMore]: !showMore })}
            >
              Show Less
              <ChevronDown />
            </button>
            <span />
          </div>
        )}
      </div>
    </Card>
  ) : (
    <div className={cx(styles.loading, styles.dashboardTable)}></div>
  );
};
