import { useMemo } from "react";
import { TDashboardTicketMetrics } from "../../../assets/types";

const useMetrics = (
  metrics: TDashboardTicketMetrics,
  loadingMetrics: boolean
) => {
  const compareTicketCount = useMemo(() => {
    if (
      loadingMetrics ||
      !metrics ||
      !metrics.currentMetrics ||
      !metrics.compareMetrics ||
      metrics.compareMetrics.totalTickets === 0
    )
      return "-";
    const diff =
      metrics.currentMetrics.totalTickets - metrics.compareMetrics.totalTickets;
    return Math.round((diff / metrics.compareMetrics.totalTickets) * 100);
  }, [metrics, loadingMetrics]);

  const compareTtr = useMemo(() => {
    if (
      loadingMetrics ||
      !metrics ||
      !metrics.currentMetrics ||
      !metrics.compareMetrics ||
      metrics.compareMetrics.avgResolutionTime === 0
    )
      return "-";
    const diff =
      metrics.currentMetrics.avgResolutionTime -
      metrics.compareMetrics.avgResolutionTime;
    return Math.round((diff / metrics.compareMetrics.avgResolutionTime) * 100);
  }, [metrics, loadingMetrics]);

  const compareHandtime = useMemo(() => {
    if (
      loadingMetrics ||
      !metrics ||
      !metrics.currentMetrics ||
      !metrics.compareMetrics ||
      metrics.compareMetrics.avgRequesterWaitTime === 0
    )
      return "-";
    const diff =
      metrics.currentMetrics.avgRequesterWaitTime -
      metrics.compareMetrics.avgRequesterWaitTime;
    return Math.round(
      (diff / metrics.compareMetrics.avgRequesterWaitTime) * 100
    );
  }, [metrics, loadingMetrics]);

  const compareBackAndForth = useMemo(() => {
    if (
      loadingMetrics ||
      !metrics ||
      !metrics.currentMetrics ||
      !metrics.compareMetrics ||
      metrics.compareMetrics.avgReplies === 0
    )
      return "-";
    const diff =
      metrics.currentMetrics.avgReplies - metrics.compareMetrics.avgReplies;
    return Math.round((diff / metrics.compareMetrics.avgReplies) * 100);
  }, [metrics, loadingMetrics]);

  return {
    ticketCount: loadingMetrics ? 0 : metrics.currentMetrics.totalTickets,
    compareTicketCount,
    ttr: loadingMetrics ? 0 : metrics.currentMetrics.avgResolutionTime,
    compareTtr,
    handTime: loadingMetrics ? 0 : metrics.currentMetrics.avgRequesterWaitTime,
    compareHandtime,
    backAndForth: loadingMetrics ? 0 : metrics.currentMetrics.avgReplies,
    compareBackAndForth,
  };
};

export default useMetrics;
