import React, { useEffect, useState } from "react";
import { Select, useTheme } from "@geist-ui/core";
import cx from "classnames";

import styles from "./styles.module.scss";
import dayjs from "dayjs";

export const GeistSelect = ({
  options,
  handleChange,
  initialValue,
  style = {},
  disabled = false,
  multiple = false,
}: {
  options: Array<{ value: string; label: string }>;
  handleChange: (value: string | string[]) => void;
  initialValue: { value: string; label: string };
  style?: any;
  disabled?: boolean;
  multiple?: boolean;
}) => {
  const theme = useTheme();

  const [lastRerender, setLastRerender] = useState<number | null>(null);
  const [isLoading, setLoading] = useState(false);

  const stringifiedValue =
    typeof initialValue.value === "string"
      ? initialValue.value
      : JSON.stringify(initialValue.value);

  useEffect(() => {
    setLoading(true);
    setLastRerender(dayjs().valueOf());
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [stringifiedValue]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Select
      onChange={handleChange}
      className={cx(styles.selectTimeFilter, { [styles.disabled]: disabled })}
      initialValue={initialValue.value}
      disabled={disabled}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      multiple={multiple}
      style={style}
    >
      {options.map(({ value, label }) => (
        <Select.Option
          key={value}
          value={value}
          className={styles.Option}
          style={{
            fontSize: "14px",
            color: theme.palette.accents_1,
          }}
        >
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};

interface BigSelectProps {
  filter: string | string[];
  setFilter: (values: string | string[]) => void;
  options: { id: string; name?: string; topic?: string }[];
  isLoading: boolean;
  multiple?: string | undefined;
  maxWidth?: string;
}

export const BigSelect: React.FC<BigSelectProps> = ({
  filter,
  setFilter,
  options,
  isLoading,
  multiple,
  maxWidth,
}: BigSelectProps) => {
  return (
    <Select
      className={styles.bigSelect}
      placeholder={!!multiple ? multiple : "Choose one"}
      multiple={!!multiple}
      value={filter}
      onChange={(values) => setFilter(values)}
      disabled={isLoading || options === undefined}
      style={{
        width: maxWidth ?? "70px",
      }}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      {options?.map((option: { id: string; name?: string; topic?: string }) => (
        <Select.Option
          value={String(option.id)}
          key={option.id}
          style={{
            backgroundColor:
              multiple && Array.isArray(filter) && filter.includes(option.id)
                ? "#d4d3d3"
                : "inherit",
            color:
              multiple && Array.isArray(filter) && filter.includes(option.id)
                ? "black"
                : "inherit",
          }}
        >
          {option.name || option.topic}
        </Select.Option>
      ))}
    </Select>
  );
};
