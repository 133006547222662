import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

import {
  TimeFilter,
  ETimesFilter,
  TTicketsTopicView,
  TTicketInfo,
} from "../../assets/types";
import { useTicketsByTopic } from "../../api/useTicketsByTopic";
import { AssesmentTopicById } from "../components/assesmentTopicById";

export const AssessmentByTopicId = () => {
  const { topicId } = useParams();

  const [timeFilter, setTimeFilter] = useState<{
    value: ETimesFilter;
    label: string;
  }>({
    value: ETimesFilter.TWO_WEEKS,
    label: "2 Weeks",
  });

  const [ticketsByTopic, setTicketsByTopic] = useState<TTicketInfo[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1); // indicates the current page to fetch from
  const [isThereNextPage, setisThereNextPage] = useState<boolean>(false); // indicates if there are more pages to load
  const [showMore, setShowMore] = useState(false); // indicates if the show more button was clicked (if false, it means that the filter was the one that changed)

  const handleChangeTimeFilter = (value: string | string[]) => {
    const tf = TimeFilter.find((tf) => tf.value === value);
    tf && setTimeFilter(tf);
  };

  const { data: tickets } = useTicketsByTopic(
    topicId ?? "Other",
    currentPage,
    timeFilter.value,
    !!topicId
  );

  const listOfTickets = tickets as TTicketsTopicView | undefined;
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    setCurrentPage(1);
    setTicketsByTopic([]);
  }, [timeFilter, topicId]);

  useEffect(() => {
    if (isAuthenticated && listOfTickets && listOfTickets.tickets?.length > 0) {
      const _ticketsByTopicResponse: TTicketInfo[] = listOfTickets.tickets.map(
        (ticket) => {
          return {
            id: ticket.id ?? "",
            owner: ticket.owner ?? "",
            subject: ticket.subject ?? "",
            createdAt: ticket.createdAt ?? "",
            status: ticket.status ?? "",
            updatedAt: ticket.updatedAt ?? "",
            displayId: ticket.displayId ?? "",
            channel: ticket.channel ?? "",
            tags: ticket.tags ?? [],
          };
        }
      );

      setTicketsByTopic((prev) => [...prev, ..._ticketsByTopicResponse]);
      setisThereNextPage(listOfTickets.isThereNextPage);
      setShowMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfTickets, topicId]);

  const handlerChangePage = () => setCurrentPage(currentPage + 1);
  return (
    <AssesmentTopicById
      timeFilter={timeFilter}
      ticketsByTopic={ticketsByTopic}
      topicId={topicId ?? "Other"}
      isThereNextPage={isThereNextPage}
      showMore={showMore}
      handleChangeTimeFilter={handleChangeTimeFilter}
      handlerChangePage={handlerChangePage}
      handlerShowMore={setShowMore}
    />
  );
};
