import React, { useEffect, useState } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export const Toggle = ({
  checked = false,
  setPressed = (v: boolean) => {},
  label = "",
  secondLabel = "",
  disabled = false,
}) => {
  const [innerPressed, setInnerPressed] = useState(checked || false);

  useEffect(() => {
    setInnerPressed(checked);
  }, [checked]);

  const handlePress = () => {
    if (!disabled) {
      if (setPressed) {
        setPressed(!innerPressed);
      }
      setInnerPressed(!innerPressed);
    }
  };

  return (
    <div
      className={cx(styles.container, { [styles.disabledContainer]: disabled })}
    >
      <div
        className={cx(styles.toggleContainer, { [styles.disabled]: disabled })}
        onClick={handlePress}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
            handlePress();
          }
        }}
      >
        <span
          className={cx(styles.toggleBackground, {
            [styles["toggleBackground-disabled"]]: disabled,
            [styles["toggleBackground-isPressed"]]: innerPressed,
          })}
        ></span>
        <span
          className={cx(styles.toggleCircle, {
            [styles["toggleCircle-disabled"]]: disabled,
            [styles["toggleCircle-pressed"]]: innerPressed,
          })}
        ></span>
      </div>
    </div>
  );
};
