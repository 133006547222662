import React, { ReactNode } from "react";

export const Dot = ({
  color = "none",
  children,
}: {
  color?: string;
  children?: ReactNode;
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 4"
        fill={color}
      >
        <circle cx="2" cy="2" r="2" fill="#878787" />
      </svg>
      {children}
    </>
  );
};
