import React, { useEffect, useMemo, useState } from "react";
import { Tabs } from "@geist-ui/core";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  ETimesFilter,
  TDashboardTicketMetrics,
  TimeFilter,
} from "../../assets/types";
import { useTicketMetrics } from "../../api/useAssessment";
import { AssessmentDetails } from "../components/assessmentDetails";
import { GeistBadge } from "../../components/atoms/geistBadge";
import { mixTrackEvent, OpportunitiesEvents } from "../../assets/mixpanel";

import styles from "./styles.module.scss";
import { Opportunities } from "./opportunities";

export const Assessment = () => {
  const [timeFilter, setTimeFilter] = useState<{
    value: ETimesFilter;
    label: string;
  }>({
    value: ETimesFilter.ONE_MONTH,
    label: "1 Month",
  });

  const { user } = useAuth0();
  const { value: opportunitiesFeatureFlag } = useFeatureFlag(
    "opportunities",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { value: betaFeatures } = useFeatureFlag(
    "betaFeatures",
    "nothing:nothing",
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );
  const oppBeta = useMemo(() => {
    return betaFeatures && betaFeatures.includes("opportunities");
  }, [betaFeatures]);

  const [loadInfoCards, setLoadInfoCards] = useState(true);
  const { data, isLoading, isError } = useTicketMetrics(
    timeFilter.value,
    "tickets",
    !!timeFilter.value
  );
  const topicResponse = data as TDashboardTicketMetrics;

  const handleChangeFilter = (value: string | string[]) => {
    const tf = TimeFilter.find((tf) => tf.value === value);
    if (tf !== timeFilter) {
      setLoadInfoCards(true);
      tf && setTimeFilter(tf);
    }
  };

  useEffect(() => {
    if (isLoading || isError || data?.error) {
      setLoadInfoCards(true);
    } else if (loadInfoCards && isLoading === false && data) {
      setLoadInfoCards(false);
    }
  }, [isLoading, loadInfoCards, data, isError]);

  return (
    <Tabs
      hideBorder
      initialValue="dashboard"
      leftSpace={"30px"}
      className={styles.container}
      onChange={(val) =>
        mixTrackEvent(OpportunitiesEvents.MOVE_HOMEPAGE_TAB, {
          tab: val,
        })
      }
    >
      <Tabs.Item label="Dashboard" value="dashboard" className="asf">
        <AssessmentDetails
          metrics={topicResponse}
          timeFilter={timeFilter}
          handleChangeFilter={handleChangeFilter}
          loadingMetrics={isLoading || loadInfoCards}
        />
      </Tabs.Item>
      {opportunitiesFeatureFlag && (
        <Tabs.Item
          label={
            <div className={styles.opportunitiesLabel}>
              Opportunities{" "}
              {/* <span className={cx(styles.opportunitiesCount, "dark_mode")}>
              3
            </span> */}
              {oppBeta && (
                <span className={styles.beta}>
                  <GeistBadge>Beta</GeistBadge>
                </span>
              )}
            </div>
          }
          value={"opportunities"}
        >
          <Opportunities />
        </Tabs.Item>
      )}
    </Tabs>
  );
};
