import { useAuthorizedQuery } from "../../../api";
import { params } from "../../../params";

export const useRecordings = ({
  ticketId,
  pageNumber,
}: {
  ticketId: string;
  pageNumber: number;
}) => {
  const tables =
    pageNumber > 0
      ? ["recordings"]
      : [
          "session",
          "networkEvents",
          "consoleEvents",
          "browserEvents",
          "recordings",
          "browserInfo",
          "localStorageEvents",
        ];

  const tableParams = tables.map((table) => `tables[]=${table}`).join("&");

  return useAuthorizedQuery({
    queryKey: `recordings-${ticketId}-${pageNumber}`,
    enabled: !!ticketId,
    url: `${params.API_URL}/sessions-api/recordings/${ticketId}?${tableParams}&recordingPage=${pageNumber}`,
    method: "GET",
  });
};

export type RecordingDataResult = {
  id: string;
  createdAt: string;
  sessionId: string;
  value: string;
};

export type Network = {
  id: string;
  createdAt: Date;
  sessionId: string;
  value: {
    url: string;
    method: string;
    status: number;
    requestHeaders: Record<string, string>;
    responseHeaders: Record<string, string>;
    requestBody?: string;
    responseBody?: string;
    timestamp: number;
  };
};

export type Console = {
  id: string;
  createdAt: Date;
  sessionId: string;
  value: {
    level: string;
    message: string;
    timestamp: number;
  };
};

export type BrowserEvent = {
  id: string;
  createdAt: Date;
  sessionId: string;
  value: any;
};

export type BrowserInfo = {
  id: string;
  createdAt: Date;
  browserName: string;
  locale: string;
  os: string;
  sessionId: string;
};

export type LocalStorage = {
  id: string;
  createdAt: Date;
  sessionId: string;
  value: any;
};

export type Session = {
  report: {
    id: string;
    createdAt: string;
    sessionId: string;
    startTime: string;
    endTime: string;
    ticketId: string;
  };
  session?: {
    id: string;
    createdAt: string;
    updatedAt: string;
    userEmail: string;
    userId: string;
    owner: string;
  };
  recordings?: RecordingDataResult[];
  networkEvents?: Network[];
  consoleEvents?: Console[];
  browserEvents?: BrowserEvent[];
  browserInfo?: BrowserInfo;
  localStorageEvents?: LocalStorage[];
  nextRecordingPage?: number;
};
