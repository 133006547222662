import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Text } from "@geist-ui/core";

import styles from "./styles.module.scss";

export const BarDataRow = ({
  percentage,
  label,
  placement = 0,
}: {
  percentage: number;
  label: string;
  placement?: number;
}) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(percentage);
    }, 0);
    return () => clearTimeout(timer);
  }, [percentage]);

  return (
    <div className={styles.barDataRowContainer}>
      <Text span style={{ fontSize: "13px" }} className={styles.topicName}>
        {label}
      </Text>
      <div className={styles.barDataContainer}>
        <div
          className={cx(
            styles.per,
            { [styles.green]: placement === 0 },
            { [styles.yellow]: placement === 1 },
            { [styles.red]: placement === 2 }
          )}
          style={{
            width: `${width}%`,
          }}
        ></div>
        <Text h4>
          {!percentage || isNaN(percentage) ? "-" : `${percentage}%`}
        </Text>
      </div>
    </div>
  );
};
