import { toCamelCaseWithFirstLetterCaps } from ".";

const enum TicketSystems {
  ZENDESK = "zendesk",
  SALESFORCE = "salesforce",
  FRESHDESK = "freshdesk",
}

const subdomainTicketSystems = {
  datarails: TicketSystems.ZENDESK,
  carat: TicketSystems.ZENDESK,
  anecdotes: TicketSystems.ZENDESK,
  carbyne: TicketSystems.ZENDESK,
  bizzabo: TicketSystems.SALESFORCE,
  particleformen: TicketSystems.ZENDESK,
  optimove: TicketSystems.ZENDESK,
  duve: TicketSystems.ZENDESK,
  barvaz: TicketSystems.SALESFORCE,
  pontera: TicketSystems.ZENDESK,
  "quack-test": TicketSystems.ZENDESK,
  "quack-test-salesforce": TicketSystems.SALESFORCE,
  "quack-test-freshdesk": TicketSystems.FRESHDESK,
  "quack-zendesk": TicketSystems.ZENDESK,
  actimize: TicketSystems.ZENDESK,
  cheqessentials: TicketSystems.ZENDESK,
  clickcease: TicketSystems.ZENDESK,
  driivz: TicketSystems.ZENDESK,
  gamestory: TicketSystems.ZENDESK,
  yotpo: TicketSystems.SALESFORCE,
  lsports: TicketSystems.FRESHDESK,
};

export const getUrlForTicketSystem = (ticketId: string) => {
  const subdomain = ticketId.split("::")[0];
  const sourceId = ticketId.split("::")[1];
  const ticketSystem =
    subdomainTicketSystems[subdomain as keyof typeof subdomainTicketSystems];

  switch (ticketSystem) {
    case TicketSystems.ZENDESK:
      return `https://${subdomain}.zendesk.com/agent/tickets/${sourceId}`;
    case TicketSystems.SALESFORCE:
      return `https://${subdomain}.my.salesforce.com/${sourceId}`;
    case TicketSystems.FRESHDESK:
      return `https://${subdomain}.freshdesk.com/helpdesk/tickets/${sourceId}`;
    default:
      console.error(`Unsupported ticket system: ${ticketSystem}`);
      return "";
  }
};

export const getTicketSystemName = (ticketId: string) => {
  const subdomain = ticketId.split("::")[0];
  return toCamelCaseWithFirstLetterCaps(
    subdomainTicketSystems[subdomain as keyof typeof subdomainTicketSystems]
  );
};
