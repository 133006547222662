export const OptionsMapper = {
  maxBackAndForth: ["1", "2", "3", "4", "5", "6"],
  length: ["short", "medium", "long"],
  includeLink: ["true", "false"],
  includeOpening: ["true", "false"],
};
export const OptionsInfoMapper = {
  maxBackAndForth: {
    title: "Max back and forth",
    info: "After x back and forth messages Quack will transfer the ticket",
  },
  length: {
    title: "Length",
    info: "Length of the answer",
  },
  includeLink: {
    title: "Include article links",
    info: "Quack will include article links in the answers",
  },
  includeOpening: {
    title: "Include opening",
    info: "Quack will start with 'hi thanks for reaching out'",
  },
};
