import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import { GDashboardTable } from "../../components/geistTableDashboard";
import { EColumnTypes } from "../../components/geistTableDashboard/index.types";
import { useSamples } from "../../api/useSamples";
import { badgeBinary, badgeOptions } from "../samplingById/constants";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TTicketScore } from "../samplingById/samplingById.types";

type TSamples = {
  id: number;
  name: string;
  tenantId: string;
  createdAt: string;
  sampleConfigurationId: number;
  tickets: {
    id: number;
    tenantId: string;
    sampleId: number;
    ticketId: string;
    ticket: {
      ticketScore: TTicketScore[];
      solvedAt: Date;
      agentAssignedId: number;
    };
  }[];
};
export const Sampling = () => {
  const { data, isLoading } = useSamples();

  const structedData = useMemo(() => {
    if (!data) return [];
    let agents: { [key: string]: number } = {};

    const array = data?.map((item: TSamples) => {
      let counterScores = 0;
      let accumulatorScores = 0;
      const tickets = item.tickets;
      tickets.forEach((currTicket) => {
        if (!currTicket.ticket?.ticketScore[0]?.autoScore) return;
        currTicket.ticket.ticketScore.forEach((score) => {
          accumulatorScores += score?.autoScore;
          counterScores++;
        });
        if (currTicket?.ticket?.agentAssignedId) {
          if (!agents[currTicket?.ticket?.agentAssignedId.toString()]) {
            agents[currTicket?.ticket?.agentAssignedId.toString()] = 1;
          } else {
            agents[currTicket?.ticket?.agentAssignedId.toString()] += 1;
          }
        }
      });
      const totalScore =
        counterScores > 0 ? (accumulatorScores / counterScores).toFixed(1) : 0;

      const dynamicBadgeOptions =
        item.tickets &&
        item.tickets.length > 0 &&
        !!item.tickets[0].ticket?.ticketScore[0].reasonForNegativeScore
          ? badgeBinary
          : badgeOptions;
      return {
        id: item.id,
        name: item.name,
        score: totalScore,
        totalTickets: tickets.length,
        agents: Object.keys(agents).length,
        updatedAt: dayjs(item.createdAt).format("DD/MM/YYYY"),
        createdAt: dayjs(item.createdAt).format("DD/MM/YYYY"),
        value: dayjs(item.createdAt, "DD/MM/YYYY").valueOf(),
        dynamicBadgeOptions,
      };
    });

    return array.sort((a: TSamples, b: TSamples) => b.id - a.id);
  }, [data]);
  const samplingColumns = [
    {
      value: "name",
      label: "Sample",
      type: EColumnTypes.DEFAULT,
    },
    {
      value: "score",
      label: "Overall score",
      type: EColumnTypes.BADGE,
      badgeOptions: structedData && structedData[0]?.dynamicBadgeOptions,
    },
    {
      value: "totalTickets",
      label: "Tickets Sampled",
      type: EColumnTypes.DEFAULT,
    },
    {
      value: "agents",
      label: "Agents Sampled",
      type: EColumnTypes.DEFAULT,
    },
    {
      value: "updatedAt",
      label: "Last Update",
      type: EColumnTypes.DEFAULT,
    },
    {
      value: "createdAt",
      label: "Creation Date",
      type: EColumnTypes.DEFAULT,
    },
  ];
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {!isLoading && structedData?.length ? (
        <GDashboardTable
          loading={isLoading}
          dataArray={structedData}
          handleRowClick={(id: string) => {
            navigate(`/sampling/${id}`);
          }}
          selectedTopic={20}
          columns={samplingColumns}
        />
      ) : (
        <GDashboardTable
          loading={isLoading}
          dataArray={[
            {
              id: "0",
              name: "No samples to show",
              score: "",
              totalTickets: "",
              agents: "",
              updatedAt: "",
              createdAt: "",
            },
          ]}
          handleRowClick={(id: string) => {}}
          selectedTopic={20}
          columns={samplingColumns}
        />
      )}
    </div>
  );
};
