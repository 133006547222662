import { useAuthorizedQuery } from ".";
import { ETimesFilter, getFilteredDate } from "../assets/types";
import { params } from "../params";

export const useGetArticlesCount = (enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticlesCount`,
    url: `${params.API_URL}/knowledge-base-handler/knowledge/articles/count`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleCoverage = (enabled: boolean) => {
  const date = getFilteredDate(ETimesFilter.ONE_MONTH);

  return useAuthorizedQuery({
    queryKey: `useGetArticleCoverage`,
    url: `${params.API_URL}/platform/dashboard/tickets-coverage-level?timeFilter=${date}&page=0`,
    method: "GET",
    enabled,
  });
};
