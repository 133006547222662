import { useAuthorizedQuery } from ".";
import { ETimesFilter, getFilteredDate } from "../assets/types";
import { params } from "../params";

export const useAutomationStatus = (
  timeFilter: ETimesFilter,
  enabled: boolean
) => {
  const date = getFilteredDate(timeFilter);

  return useAuthorizedQuery({
    queryKey: `useAutomationStatus-${timeFilter}`,
    url: `${params.API_URL}/platform/dashboard/automation-status?timeFilter=${date}`,
    method: "GET",
    enabled,
  });
};
