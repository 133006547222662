import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export enum ERole {
  USER = "user",
  AGENT = "agent",
}

export enum EMessageType {
  EXAMPLE = "example",
  FOLLOWUP = "followup",
  NONE = "none",
}

export interface IMessage {
  role: ERole;
  type: EMessageType;
  content: string;
}

const MessagesLabels = {
  example: "Example question",
  followup: "Followup question",
};

export const Messages = ({
  messages,
  isAnswerLoading,
  isDraft = false,
}: {
  messages: IMessage[];
  isAnswerLoading: boolean;
  isDraft?: boolean;
}) => {
  return (
    <div className={styles.container}>
      {messages.map((message, index) => {
        const load =
          isAnswerLoading &&
          message.role === "agent" &&
          index === messages.length - 1;
        return (
          <div
            key={index}
            className={cx(styles.messageContainer, {
              [styles.withLabel]: message.type !== EMessageType.NONE,
            })}
          >
            {message.type !== EMessageType.NONE && (
              <div className={styles.label}>{MessagesLabels[message.type]}</div>
            )}
            {message.role === "agent" && (
              <img
                src="https://randomuser.me/api/portraits/women/47.jpg"
                alt="w"
              />
            )}
            <div
              className={cx(
                styles.message,
                { [styles.agent]: message.role === "agent" },
                { [styles.user]: message.role === "user" },
                { [styles.draft]: isDraft && message.role === "agent" },
                {
                  [styles.loading]: load,
                }
              )}
              key={index}
            >
              {load
                ? "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem "
                : message.content}
              {message.role === "agent" && (
                <div
                  className={cx(styles.draft, {
                    [styles.hide]: !isDraft || load,
                  })}
                >
                  Draft
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
