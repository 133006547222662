import { useAuthorizedQuery } from "../index";
import { params } from "../../params";

export const useOrganizations = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizedQuery({
    queryKey: `useGetOrganizations`,
    url: `${params.API_URL}/platform/organizations`,
    // url: "http://localhost:3001/platform/organizations",
    method: "GET",
    enabled: enabled,
  });
};

export const useOrganization = ({
  organizationId,
  enabled,
}: {
  organizationId: string;
  enabled: boolean;
}) => {
  return useAuthorizedQuery({
    queryKey: `useGetOrganization-${organizationId}`,
    url: `${params.API_URL}/platform/organizations/${organizationId}`,
    // url: `http://localhost:3001/platform/organizations/${organizationId}`,
    method: "GET",
    enabled: enabled,
  });
};
