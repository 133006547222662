import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs } from "@geist-ui/core";
import { IoSparkles } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";

import { GDashboardTable } from "../../components/geistTableDashboard";
import { useGetKB, useRateSuggestedArticle } from "../../api/useGetKB";
import { useGetSuggestedArticles } from "../../api/useGetSuggestedArticles";
import {
  EColumnTypes,
  EConventions,
  IColumn,
} from "../../components/geistTableDashboard/index.types";
import { EBadgeMode, GeistBadge } from "../../components/atoms/geistBadge";
import { useFetchingToasts } from "../../hooks/useFetchingToasts";
import { GeistSelect } from "../../components/geistSelect";
import { ETimesFilter, TimeFilter } from "../../assets/types";
import { EPerformanceType } from "../../components/atoms/performenceBar";
import { CoverageLevelType } from "../knowledgeManagement/index.types";

import styles from "./styles.module.scss";
import {
  DisplayedArticle,
  SuggestedArticle,
  WorkedArticle,
} from "./index.types";
import { useGetArticleOpportunitiesByArticlesIds } from "../../api/useWizard/getArticleDetails";
import { useVisits } from "../../api/useVisits";

enum ETab {
  KB_ARTICLES = "kb",
  SUGGESTED_ARTICLES = "sa",
}

export const KnowledgeByTopic = () => {
  const { topicId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [timeFilter, setTimeFilter] = useState<{
    value: ETimesFilter;
    label: string;
  }>({
    value: ETimesFilter.ONE_MONTH,
    label: "1 Month",
  });
  const handleChangeFilter = (value: string | string[]) => {
    setArticlesArray([]);
    setPage(0);
    const tf = TimeFilter.find((tf) => tf.value === value);
    tf && setTimeFilter(tf);
  };

  const [page, setPage] = useState(0);
  const [articlesArray, setArticlesArray] = useState<DisplayedArticle[]>([]);

  useEffect(() => setArticlesArray([]), [topicId]);

  const {
    data: kbData,
    isLoading: kbLoading,
    isError: isKbError,
  } = useGetKB(page, topicId ?? "", timeFilter.value, Boolean(topicId));
  const { articles, isNextPage: rawIsNextPage } = (kbData ?? {
    articles: [],
    isNextPage: undefined,
  }) as {
    articles: WorkedArticle[];
    isNextPage: boolean;
  };

  const articleIds = articlesArray.map((a) => a.articleId);

  const { data: opportunitiesByArticles, refetch: refetchOpportunities } =
    useGetArticleOpportunitiesByArticlesIds({
      enabled: false,
      articleIds: articleIds,
    });

  const articleIdToOpportunitiesMapper = useMemo(() => {
    const mapper = new Map<string, number>();

    if (opportunitiesByArticles?.length > 0) {
      opportunitiesByArticles?.forEach((opportunity: any) => {
        opportunity.transaction?.commonTransactionEval?.relatedArticlesIds?.forEach(
          (articleId: any) => {
            if (mapper.has(articleId)) {
              mapper.set(articleId, mapper.get(articleId)! + 1);
            } else {
              mapper.set(articleId, 1);
            }
          }
        );
      });
    }

    return mapper;
  }, [opportunitiesByArticles]);

  const { data: visitsData } = useVisits(true);

  const isNextPage = rawIsNextPage === undefined ? false : rawIsNextPage;

  useEffect(() => {
    refetchOpportunities();
  }, [JSON.stringify(articleIds)]);

  const urlToVisits = useMemo(() => {
    const mapper = new Map<string, number>();
    if (visitsData) {
      Object.keys(visitsData?.countWithoutDuplicatiosValues).map((key) => {
        const sections = key.split("#")[0].split("/");
        const cleanedKey = sections[sections.length - 1];
        if (mapper.has(cleanedKey)) {
          mapper.set(
            cleanedKey,
            mapper.get(cleanedKey)! +
              visitsData.countWithoutDuplicatiosValues[key]
          );
        } else {
          mapper.set(
            cleanedKey,
            visitsData?.countWithoutDuplicatiosValues[key]
          );
        }
      });
    }

    return mapper;
  }, [visitsData]);

  useEffect(() => {
    if (articles && articles.length > 0) {
      const _articles = articles.map((article) => ({
        articleId: article.articleId,
        title: article.title,
        //  article.url.split("#")[0].split("/")[
        //   article.url.split("#")[0].split("/").length - 1
        // ],
        owner: article.owner,
        status: article.status,
        url: article.url,
        author: article.author,
        createdAt: article.createdAt,
        updatedAt: article.updatedAt,
        tickets: article.currentUsage.toString(),
        visit: article.viewsWithoutDuplications ?? 0,
        opportunities: 0,
        trend: article.compareUsage
          ? (article.currentUsage / article.compareUsage).toString()
          : "0",
        coverage: article.coverage.length
          ? [
              {
                percentage:
                  (article.coverage.filter(
                    (c) => c === CoverageLevelType.PARTIAL
                  ).length *
                    100) /
                  article.coverage.length,
                performance: EPerformanceType.YELLOW,
              },
              {
                percentage:
                  (article.coverage.filter((c) => c === CoverageLevelType.FULL)
                    .length *
                    100) /
                  article.coverage.length,
                performance: EPerformanceType.GREEN,
              },
            ]
          : [],
      }));
      setArticlesArray((prev) => [...prev, ..._articles]);
    }
  }, [articles]);

  useFetchingToasts({ error: isKbError || kbData?.error, emptyState: false });

  const { value: suggestedArticlesTableValue } = useFeatureFlag(
    "suggestedArticlesTable",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { data: saData, isLoading: saLoading } = useGetSuggestedArticles(
    topicId ?? "",
    !!topicId
  );

  const suggArticles = useMemo(() => {
    if (saData && saData.length) {
      return saData.map((article: SuggestedArticle) => ({
        id: article.id,
        title: article.title,
        tickets: article.coveredTickets.length,
        isNew: !article.updatedArticleId ? "NEW" : "UPDATED",
        updatedArticleId: article.updatedArticleId,
      }));
    }
    return [{ title: "No articles found" }];
  }, [saData]);

  const [suggestedArticleId, setSuggestedArticleId] = useState<string | null>(
    null
  );
  const { mutateAsync: rateSuggestedArticle } = useRateSuggestedArticle(
    suggestedArticleId ?? ""
  );
  const handleRateSuggestedArticle = useCallback(
    async ({ value, articleId }: { value: number; articleId: string }) => {
      if (articleId === suggestedArticleId) {
        await rateSuggestedArticle({
          reviewer: user?.email ?? "",
          rank: value,
        });
      } else {
        setSuggestedArticleId(articleId);
        createToast("Oops! Something's wrong. Please try again.", {
          type: "error",
          timeout: 5000,
        });
      }
    },
    [
      suggestedArticleId,
      setSuggestedArticleId,
      rateSuggestedArticle,
      user?.email,
    ]
  );

  const suggestedColumns = useMemo(
    () => [
      { label: "Article", value: "title", type: EColumnTypes.NAME },
      {
        label: "Related Tickets",
        value: "tickets",
        type: EColumnTypes.DEFAULT,
      },
      {
        label: "",
        value: "isNew",
        type: EColumnTypes.BADGE,
        badgeOptions: {
          NEW: EBadgeMode.GREEN,
          UPDATED: EBadgeMode.YELLOW,
        },
      },
      {
        label: "Rating",
        value: "rank",
        type: EColumnTypes.STARS_RATING,
        onRate: ({
          value,
          articleId,
        }: {
          value: number;
          articleId: string;
        }) => {
          handleRateSuggestedArticle({ value, articleId: articleId });
        },
        onHover: (id: string) => {
          setSuggestedArticleId(id);
        },
      },
    ],
    [handleRateSuggestedArticle]
  );

  const kbColumns: IColumn[] = useMemo(() => {
    return [
      { label: "Article", value: "title", type: EColumnTypes.NAME },
      {
        label: "Status",
        value: "status",
        type: EColumnTypes.BADGE,
        badgeOptions: {
          Published: EBadgeMode.GREEN,
          Drafts: EBadgeMode.DEFAULT,
        },
        changeConvention: EConventions.FIRST_LETTER_CAMEL_CASE,
      },
      { label: "Visits", value: "visit", type: EColumnTypes.DEFAULT },

      { label: "Coverage", value: "coverage", type: EColumnTypes.PERFORMANCE },
      {
        label: "Related Tickets",
        value: "tickets",
        type: EColumnTypes.DEFAULT,
      },
      { label: "Trend", value: "trend", type: EColumnTypes.TREND },
      {
        label: "Opportunities",
        value: "opportunities",
        type: EColumnTypes.DEFAULT,
      },
      { label: "Author", value: "author", type: EColumnTypes.DEFAULT },

      { label: "Last Updated", value: "updatedAt", type: EColumnTypes.DATE },
      {
        label: "See Article",
        value: "url",
        type: EColumnTypes.BUTTON,
        seeOptions: (id: string) => {
          const article = articlesArray.find(
            (article) => article.articleId === id
          );
          article?.url && window.open(article.url, "_blank");
        },
      },
    ];
  }, [articlesArray]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.selectContainer}>
        <GeistSelect
          options={TimeFilter}
          handleChange={handleChangeFilter}
          initialValue={timeFilter}
        />
      </div>
      <Tabs initialValue="kb" className={styles.tabs}>
        <Tabs.Item label="Knowledge Base" value={ETab.KB_ARTICLES}>
          <GDashboardTable
            dataArray={(articlesArray as any).map((a: any) => {
              const sections = a.url.split("#")[0].split("/");

              const cleanedArticleId = sections[sections.length - 1];
              return {
                ...a,
                visits: urlToVisits.get(cleanedArticleId) ?? 0,
                opportunities:
                  articleIdToOpportunitiesMapper.get(a.articleId) ?? 0,
              };
            })}
            columns={kbColumns}
            handleRowClick={(id) =>
              navigate(`/knowledge-management/${topicId}/article/${id}`)
            }
            selectedTopic={0}
            disableSelected
            loading={Boolean(
              (!articlesArray?.length && kbData === undefined) ||
                (kbLoading && !articlesArray?.length)
            )}
            idName="articleId"
            nextPage={isNextPage || (articles?.length > 0 && !kbData)}
            onNextPage={() => setPage(page + 1)}
            loadMore={Boolean(articlesArray?.length > 0 && kbLoading)}
            firstColumnWidth="20%"
          />
        </Tabs.Item>
        {suggestedArticlesTableValue && (
          <Tabs.Item
            label={
              <>
                <IoSparkles />
                Suggested Articles
                <GeistBadge mode={EBadgeMode.YELLOW}>
                  {Array.isArray(saData) ? saData.length : 0}
                </GeistBadge>
              </>
            }
            value={ETab.SUGGESTED_ARTICLES}
            disabled={saLoading || kbLoading}
          >
            <GDashboardTable
              dataArray={!saLoading ? suggArticles : []}
              columns={suggestedColumns}
              handleRowClick={(updatedArticleId) =>
                updatedArticleId
                  ? navigate(
                      `/knowledge-management/${topicId}/wizard/${updatedArticleId}`
                    )
                  : () => {}
              }
              selectedTopic={0}
              disableSelected
              loading={saLoading}
              idName="updatedArticleId"
            />
          </Tabs.Item>
        )}
      </Tabs>
    </div>
  );
};
