export enum ActivationEvents {
  PUBLISH_COMMON_QUESTION = "publish_common_question",
  PUBLISH_AUTO_RESPONSE = "publish_auto_response",
  RESIZE_SIDEBAR = "resize_sidebar",
  OPEN_INFO_DRAWER = "open_info_drawer",
  OPEN_TEXT_EDITOR = "open_text_editor",
  ASK_FOLLOW_UP_QUESTION = "ask_follow_up_question",
  SEND_TRY_AGAIN = "send_try_again",
  OPEN_CONFIGURATION_TOOLS = "open_configuration_tools",
  SET_CONFIGURATION_TOOLS = "set_configuration_tools",
  OPEN_SOURCE_DETAILS_DRAWER = "open_source_details_drawer",
  CHANGE_COMMON_QUESTION = "change_common_question",
  UNDO_DRAFT_COMMON_QUESTION_AUTO_CONFIG = "undo_draft_common_question_auto_config",
  UPDATE_DRAFT_COMMON_QUESTION_AUTO_CONFIG = "update_draft_common_question_auto_config",
}
