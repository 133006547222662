import React from "react";
import { CssBaseline, GeistProvider, Page, Themes } from "@geist-ui/core";
import "../../theme.scss";

export const GeistContainer = ({ children }: { children: React.ReactNode }) => {
  const myDarkTheme = Themes.createFromDark({
    type: "myDarkTheme",
  });
  const myTheme = Themes.create(myDarkTheme, {
    type: "myTheme",
    palette: {
      background: "#121212",
      accents_1: "#EDEDED",
      accents_3: "#8F8F8F",
      errorLight: "#FF6166",
      successLight: "#52A462",
    },
  });
  return (
    <GeistProvider themeType="myTheme" themes={[myTheme]}>
      <CssBaseline />
      <Page
        style={{
          background: "#121212",
          margin: 0,
          width: "100%",
          height: "fit-content",
          padding: "0",
        }}
      >
        {children}
      </Page>
    </GeistProvider>
  );
};
