import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useSearchArticle = (search: string, enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useSearchArticle-${useSearchArticle.toString()}`,
    url: `${params.API_URL}/knowledge-base-handler/articles?searchString=${search}`,
    method: "GET",
    enabled,
  });
};
