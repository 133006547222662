import { ReactNode } from "react";

export type TAccuracyAnswer = {
  id: number;
  commonQuestionId: number;
  createdAt: string;
  updatedAt: string;
  version: number;
  question: string;
  answer: string;
  score: number;
  isFollowUpQuestion: boolean;
  sourceArticles: any[];
  sourceTickets: number[];
};

export type TAccuracyCommonQuestion = {
  id: number;
  name: string;
  tenant: string;
  topicId: number;
  autoResponseEnabled: boolean;
  readyAutoResponse: boolean;
  type: string;
  coverageLevel: any;
  _count: {
    tickets: number;
  };
  answers: TAccuracyAnswer[];
};

export type TAccuracyTicket = {
  id: string;
  updatedAt: string;
  url: string;
  displayId: string | null;
};

export type TAccuracyDataType = {
  commonQuestion: TAccuracyCommonQuestion;
  tickets: TAccuracyTicket[];
  articlesIds: any[];
};

export type TArticle = {
  articleId: string;
  title: string;
  owner: string;
  status: string;
  url: string;
  createdAt: string;
  updatedAt: string;
};
export enum ELengthAnswer {
  SHORT = "SHORT",
  MEDIUM = "MEDIUM",
  LONG = "LONG",
}
export enum EPersonalCustom {
  BASIC = "BASIC",
  MELLOW = "MELLOW",
  HYPER = "HYPER",
}
export enum EToneOfVoice {
  EMPATHETIC = "EMPATHETIC",
  TECHNICAL = "TECHNICAL",
  SUPPORTIVE = "SUPPORTIVE",
  ENCOURAGE = "ENCOURAGE",
}
export type TOptionsAutoConfig = {
  key: string;
  label: string | ReactNode;
  isActive?: boolean;
  isBoolean?: boolean;
  isSelected?: boolean;
  subOptions?: {
    key: string;
    label: string | ReactNode;
    isActive?: boolean;
    isBoolean?: boolean;
    isSelected?: boolean;
  }[];
};

export type TAccuracyConfig = {
  id: number;
  tenantId: string;
  personalCustom: EPersonalCustom;
  toneOfVoice: EToneOfVoice;
  includeArticle: boolean;
  lengthAnswer: ELengthAnswer;
  commonQuestionId: number;
  customInstructions?: string;
  updatedAt: string;
  isDraft: boolean;
};
