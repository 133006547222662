import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import Markdown from "react-markdown";
//@ts-ignore
import { Converter } from "showdown";
import { Message, ToolInvocation } from "ai";

import styles from "./styles.module.scss";
import { IoSparkles } from "react-icons/io5";
import { Loading, Textarea } from "@geist-ui/core";
import { Info, ThumbsDown } from "@geist-ui/icons";
import { Pencil1Icon } from "@radix-ui/react-icons";

interface IMessage {
  role: "user" | "assistant";
  content: string;
}

enum Tools {
  SKIP_IT = "skip_it",
  TALK_TO_AN_AGENT = "talk_to_an_agent",
}

export const Messages = ({
  messages,
  isLoading,
  handleEditMessage,
}: {
  messages: Message[];
  handleEditMessage: (message: string) => void;
  isLoading: boolean;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // handle scrolling down on messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <>
      {messages.length === 0 && <div>Analyzing...</div>}
      {messages.map((m, i) => {
        return (
          <MessageComponent
            role={m.role === "user" ? "user" : "assistant"}
            content={m.content}
            tools={m.toolInvocations}
            isLast={i + 1 === messages.length}
            handleEditMessage={handleEditMessage}
          />
        );
      })}

      {isLoading && messages.length % 2 !== 0 && (
        <MessageComponent
          role={"assistant"}
          content={""}
          isLoading
          handleEditMessage={handleEditMessage}
        />
      )}

      <div ref={messagesEndRef} />
    </>
  );
};

const AgentWrapper = ({
  children,
  hideIcon = false,
}: {
  children: ReactElement;
  hideIcon?: boolean;
}) => {
  return (
    <div className={styles.assistantWrapper}>
      {!hideIcon && (
        <div className={styles.sparklesIcon}>
          <IoSparkles />
        </div>
      )}
      {children}
    </div>
  );
};
const convertor = new Converter({
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  openLinksInNewWindow: true,
  smoothLivePreview: true,
  disableForced4SpacesIndentedSublists: true,
});

const convertMarkdownToHTML = (markdown: string) => {
  if (markdown) {
    // return markdown;
    return convertor?.makeHtml(markdown);
  } else {
    return "";
  }
};

const MessageComponent = ({
  role,
  content,
  tools,
  handleEditMessage,
  isLoading = false,
  isLast = false,
}: IMessage & {
  isLoading?: boolean;
  handleEditMessage: (m: string) => void;
  tools?: ToolInvocation[];
  isLast?: boolean;
}) => {
  const [isHover, setHover] = useState(false);
  const [value, setValue] = useState(content);
  const [isEdit, setEdit] = useState(false);
  const Wrapper = role === "assistant" ? AgentWrapper : Fragment;

  useEffect(() => {
    setValue(content);
  }, [content]);

  const firstTool = tools?.[0];

  const isSkip =
    firstTool?.state === "result" && firstTool?.result === Tools.SKIP_IT;

  const isEscalated =
    firstTool?.state === "result" &&
    firstTool.result === Tools.TALK_TO_AN_AGENT;

  const props = role === "assistant" ? { hideIcon: isSkip || isEscalated } : {};

  return (
    <Wrapper {...props}>
      <div
        className={cx(styles.message, styles[role], {
          [styles.loading]: isLoading,
          [styles.systemMessage]: isSkip || isEscalated,
          [styles.editMode]: isEdit,
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onBlur={() => {}}
      >
        {(isHover || isEdit) && role === "assistant" && isLast && (
          <div
            className={cx(styles.btnContainer, {
              [styles.editablt]: isEdit,
            })}
          >
            <button
              type="button"
              className={cx(styles.btn)}
              onClick={() => {
                setEdit((prev) => !prev);
              }}
            >
              {isEdit ? "Cancel" : <Pencil1Icon />}
            </button>
            {isEdit && (
              <button
                className={cx(styles.btn)}
                onClick={() => {
                  handleEditMessage(value);
                  setEdit(false);
                }}
              >
                Save
              </button>
            )}
          </div>
        )}
        {isLoading ? (
          <Loading width={2.5} />
        ) : (
          <>
            {isSkip && (
              <div className={styles.system}>
                <Info />
                Out of the policy, Quack Agent will skip this question
              </div>
            )}
            {isEscalated && (
              <div className={styles.system}>
                <Info />
                Quack will escalate it to an agent
              </div>
            )}
            {isEdit ? (
              <TextField
                value={content}
                index={0}
                handleBlur={() => {
                  setTimeout(() => {
                    setEdit(false);
                  }, 300);
                }}
                handleFocus={() => {}}
                setExternalValue={setValue}
                preventSelectAll
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: convertMarkdownToHTML(content),
                }}
              ></div>
            )}
            {/* <Markdown disallowedElements={["ul"]} unwrapDisallowed>
              {content}
            </Markdown> */}
          </>
        )}
      </div>
    </Wrapper>
  );
};

// ol/ul - margin top and bottom 0
// li - margin 0 and line height like the font size

const TextField = ({
  index,
  value,
  handleBlur,
  handleFocus,
  setExternalValue,
  disabled = false,
  preventSelectAll = false,
}: {
  index: number;
  value: string;
  setExternalValue?: (v: string) => void;
  handleBlur: (index: number, value: string) => void;
  handleFocus: (index: number) => void;
  disabled?: boolean;
  preventSelectAll?: boolean;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [v, setValue] = useState(value);
  const [height, setHeight] = useState("unset");

  useEffect(() => {
    setValue(value);

    setTimeout(() => {
      if (textAreaRef.current) {
        setHeight(`${textAreaRef.current.scrollHeight}px`);
      }
    }, 300);
  }, [value]);

  return (
    <Textarea
      ref={textAreaRef}
      onChange={(e) => {
        const inputValue = e.target.value;
        setValue(inputValue);

        if (setExternalValue) {
          setExternalValue(inputValue);
        }
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
      }}
      onBlur={(e) => {
        handleBlur(index, e.target.value);
      }}
      onFocus={(e) => {
        handleFocus(index);
        if (!preventSelectAll) {
          e.target.select();
        }
      }}
      value={v}
      disabled={disabled}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      style={{
        resize: "none",
        width: "100%",
        overflow: "hidden",
        height: height,
        border: "0",
      }}
    />
  );
};
