import styles from "./styles.module.scss";
import cx from "classnames";
import React, { useEffect, useState } from "react";

const validateEmail = (email: string): boolean => {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
};

export const AddMember = ({
  onClick,
  isLoading,
}: {
  onClick: Function;
  isLoading: boolean;
}) => {
  const [email, setEmail] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const labels = {
    invite: "Invite member",
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && validateEmail(email)) {
      onClick(email);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setEmail("");
    }
  }, [isLoading]);

  return (
    <div className={cx(styles.add_member_container)}>
      <input
        type="text"
        id="member_email"
        name="member_email"
        placeholder="Add member by email"
        value={email}
        autoFocus
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <button
        disabled={isLoading || !validateEmail(email)}
        onClick={() => onClick(email)}
      >
        {isLoading ? "loading.." : labels.invite}
      </button>
    </div>
  );
};
