import { useAuthorizedQuery } from "..";
import { ETimesFilter, getFilteredDate } from "../../assets/types";
import { params } from "../../params";
import { ECategories } from "../../routes/vocOfCustomer/voc.types";

export const useVoc = (
  categoryName: ECategories,
  timeFilter: ETimesFilter,
  page: number = 0,
  enabled: boolean = true
) => {
  const date = getFilteredDate(timeFilter);
  return useAuthorizedQuery({
    queryKey: `voice-of-customer-${categoryName}-${timeFilter}-${page}`,
    url: `${params.API_URL}/platform/voice-of-customer/tickets?categoryName=${categoryName}&timeFilter=${date}&page=${page}`,
    method: "GET",
    enabled,
  });
};
export const useDashboardCounts = (
  categories: string[],
  isTakeAll: boolean,
  timeFilter: ETimesFilter,
  enabled: boolean = true
) => {
  const date = getFilteredDate(timeFilter);
  const filterCategories = categories?.join("&categories[]=");
  return useAuthorizedQuery({
    queryKey: `voice-of-customer-categories-count-${isTakeAll}-${categories}-${timeFilter}`,
    url: `${params.API_URL}/platform/voice-of-customer/categories/count?isTakeAll=${isTakeAll}&timeFilter=${date}&categories[]=${filterCategories}`,
    method: "GET",
    enabled,
  });
};
