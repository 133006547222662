import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

export const CircularPieChart = ({
  number,
  label,
  percentages,
}: {
  number: number;
  label: string;
  percentages: { label: string; percentage: number }[];
}) => {
  let cumulativePercentage = 15;
  const gap = 3;

  return (
    <div className={styles.circleDiv}>
      <svg viewBox="0 0 36 36" className={styles.circleCont}>
        <path
          className={styles.backgroundCircle}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {percentages.map(({ percentage }, index) => {
          const adjustedPercentage = percentage - gap;
          const strokeDasharray = `${adjustedPercentage} ${
            100 - adjustedPercentage
          }`;
          const strokeDashoffset = 100 - cumulativePercentage;
          cumulativePercentage += percentage;

          const colorClass = `color${index + 1}`;

          return (
            <path
              key={index}
              className={cx(styles.circle, styles[colorClass])}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={!isNaN(strokeDashoffset) ? strokeDashoffset : 0}
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          );
        })}
      </svg>
      <div className={styles.detailsContainer}>
        <span className={styles.number}>{number}</span>
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
};
