import React, { useState } from "react";
import classnames from "classnames";
import styles from "../styles.module.scss";
import { Star } from "@geist-ui/icons";

export const StarsRating = ({
  initialValue = 0,
  onRate,
}: {
  initialValue: number;
  onRate: (value: number) => void;
}) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    value: number
  ) => {
    event.stopPropagation();
    setSelectedValue(value);
    if (onRate) {
      onRate(value);
    }
  };

  const handleMouseEnter = (value: number) => {
    setHoverValue(value);
  };

  const handleMouseLeave = () => {
    setHoverValue(null);
  };

  const getStarClass = (index: number) => {
    if (hoverValue !== null) {
      return index <= hoverValue ? styles.hoveredStar : styles.unselectedStar;
    }
    return index <= selectedValue ? styles.selectedStar : styles.unselectedStar;
  };

  return (
    <div className={styles.starsRating}>
      {[1, 2, 3, 4, 5].map((star) => (
        <div
          key={star}
          className={classnames(styles.star, getStarClass(star))}
          onClick={(event) => handleClick(event, star)}
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
        >
          <Star />
        </div>
      ))}
    </div>
  );
};
