import { Edit3, Layers, Link2, List, User, Volume } from "@geist-ui/icons";
import { TOptionsAutoConfig } from "./configurationTools.types";

export const configurationOptions = [
  {
    key: "include_links",
    label: (
      <>
        <Link2 />
        Include article links
      </>
    ),
    isBoolean: true,
  },
  {
    key: "edit",
    label: (
      <>
        <Edit3 />
        Edit Manually
      </>
    ),
  },
  {
    key: "source_details",
    label: (
      <>
        <Layers />
        Source Details & Accuracy
      </>
    ),
  },
];

export const optionsJustSourceDetails: TOptionsAutoConfig[] = [
  {
    key: "length",
    label: (
      <>
        <List />
        Length
      </>
    ),
    subOptions: [
      {
        key: "short",
        label: "Short",
      },
      {
        key: "medium",
        label: "Medium",
      },
      {
        key: "long",
        label: "Long",
      },
    ],
  },
  {
    key: "tone_of_voice",
    label: (
      <>
        <Volume />
        Tone of voice
      </>
    ),
    subOptions: [
      {
        key: "empathetic",
        label: "🤝 Empathetic Tone",
      },
      {
        key: "technical",
        label: "🛠️  Technical Tone",
      },
      {
        key: "supportive",
        label: "🤗 Supportive Tone",
      },
      {
        key: "encourage",
        label: "🤩 Encourage Feedback",
      },
    ],
  },
  {
    key: "personalization",
    label: (
      <>
        <User />
        Personalization
      </>
    ),
    subOptions: [
      {
        key: "basic",
        label: "Basic",
      },
      {
        key: "mellow",
        label: "Mellow",
      },
      {
        key: "hyper",
        label: "Hyper",
      },
    ],
  },
  {
    key: "source_details",
    label: (
      <>
        <Layers />
        Source Details & Accuracy
      </>
    ),
  },
];
