import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Tooltip } from "@geist-ui/core";

import styles from "./styles.module.scss";

export interface ICommonQuestion {
  name: string;
  enabled: boolean;
  potential: string;
}

export const LoadingCommonQuestion = () => {
  return <div className={styles.skeletonCommonQuestion}>mock mock</div>;
};

export const SampleTicket = ({
  state,
  index,
  isExpanded,
  isExapndedImmediat,
  onClick,
  active,
}: {
  state: {
    ticketId: string;
    displayId: string | null;
    subject: string;
    score: number;
  };
  index: number;
  isExpanded: boolean;
  isExapndedImmediat: boolean;
  onClick: (index: number) => void;
  active?: boolean;
}) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsEllipsis(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [textRef, isExpanded]);

  return (
    <div
      className={cx(styles.container, { [styles.active]: active })}
      onClick={() => onClick(index)}
    >
      <div className={styles.left}>
        {isExpanded ? (
          <div className={styles.label}>
            #
            {isEllipsis ? (
              <Tooltip text={state?.ticketId} placement="topStart">
                {state?.displayId ?? state?.ticketId?.split("::")[1]}
              </Tooltip>
            ) : (
              state?.displayId ?? state?.ticketId?.split("::")[1]
            )}
          </div>
        ) : (
          <div className={cx(styles.label, styles.subLabel)}>
            #{state?.displayId ?? state?.ticketId?.split("::")[1]}
          </div>
        )}
        {isExpanded && (
          <div
            ref={textRef}
            className={cx(styles.potential, styles.ellipsis)}
            style={{
              opacity: isExapndedImmediat ? "1" : "0",
              fontSize: isExapndedImmediat ? "12px" : "0px",
            }}
          >
            {state.subject}
          </div>
        )}
      </div>
      <div className={styles.potential}>{state.score}</div>
    </div>
  );
};
