import React from "react";

export const Logo = (props: any) => (
  <svg
    width="456"
    height="514"
    viewBox="0 0 456 514"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.290088 117.94C0.290088 117.94 61.0101 118.94 79.0801 118.94C83.6401 118.94 75.1701 197.32 75.1701 197.32C75.1701 197.32 0.220088 192.14 0.290088 117.94Z"
      fill="url(#paint0_linear_701_1058)"
    />
    <path
      d="M399.92 439.31C395.98 443.74 391.83 448.06 387.45 452.26C303.54 532.68 170.38 534.35 84.69 455.83C65.09 437.87 49.48 417.29 37.88 395.17C2.02004 326.81 35.98 242.7 109.44 219C129.18 212.63 152.19 207.84 178.68 206.11C195.22 205.03 211.41 207.92 226.94 211.38C248.33 247.56 287.74 271.83 332.81 271.83C333.91 271.83 335.02 271.81 336.11 271.78C380.55 270.61 419.12 245.85 439.73 209.55C470.29 285.54 457.01 375.06 399.92 439.31Z"
      fill="#FED942"
    />
    <path
      d="M189.37 0.330078C116.7 0.330078 57.79 59.2501 57.79 131.93C57.79 204.59 116.7 263.51 189.37 263.51C262.04 263.51 320.95 204.59 320.95 131.93C320.95 59.2501 262.04 0.330078 189.37 0.330078ZM141.66 129.56C125.98 129.56 113.27 116.85 113.27 101.17C113.27 85.4901 125.98 72.7801 141.66 72.7801C157.34 72.7801 170.05 85.4901 170.05 101.17C170.05 116.85 157.33 129.56 141.66 129.56Z"
      fill="#FBBF2A"
    />
    <path
      d="M436.92 286.41C430.04 327.38 410.79 366.7 379.16 398.32C298.32 479.16 167.25 479.16 86.4101 398.32C75.8401 387.75 66.6601 376.35 58.8901 364.29C61.1601 366.78 63.5001 369.23 65.9101 371.64C153.6 459.33 295.79 459.33 383.48 371.64C408.34 346.77 426.16 317.54 436.92 286.41Z"
      fill="#FF9101"
    />
    <defs>
      <linearGradient
        id="paint0_linear_701_1058"
        x1="0.289788"
        y1="157.635"
        x2="80.4243"
        y2="157.635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE842D" />
        <stop offset="1" stopColor="#FF3852" />
      </linearGradient>
    </defs>
  </svg>
);
