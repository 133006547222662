import { useAuthorizedMutation, useAuthorizedQuery } from ".";
import { ETimesFilter, getFilteredDate } from "../assets/types";
import { params } from "../params";

export const useGetKB = (
  page: number,
  topicId: string,
  timeFilter: ETimesFilter,
  enabled: boolean
) => {
  const date = getFilteredDate(timeFilter);
  return useAuthorizedQuery({
    queryKey: `useGetArticlesByIds-${page}-${topicId}-${timeFilter}`,
    url: `${params.API_URL}/knowledge-base-handler/knowledge/tenants/${topicId}/articles?timeFilter=${date}&page=${page}`,
    method: "GET",
    enabled,
  });
};

export const useGenerateDraftArticleForTopic = (topicId: string) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/knowledge-base-handler/suggested-article?topicId=${topicId}`,
    method: "POST",
  });
};

export const useRateSuggestedArticle = (articleId: string) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/knowledge-base-handler/suggested-article/${articleId}/ranking`,
    method: "PUT",
  });
};
