import { useEffect, useState } from "react";
import cx from "classnames";

import {
  Integration,
  IntegrationSetupStatus,
  IntegrationType,
} from "../../../../accountSetup/types";
import {
  useAddIntegration,
  useLoginZendesk,
} from "../../../../accountSetup/api/useIntegrations";
import Nango from "@nangohq/frontend";

import styles from "./styles.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";

const nango = new Nango({ publicKey: "7d3dd047-cdc9-4f26-ae76-a1a75565600a" });

export const Other = ({
  token,
  meta,
  integrations,
  refetch,
}: {
  token: string;
  meta?: any[];
  integrations?: Integration[];
  refetch: () => void;
}) => {
  const { user } = useAuth0();
  const { data: redirectZendesk } = useLoginZendesk();

  const { mutate: addIntegration } = useAddIntegration();

  const handleSave = (tempMeta: any, type: IntegrationType) => {
    addIntegration(
      {
        name: "OTHER",
        type: type,
        status: IntegrationSetupStatus.COMPLETED,
        metadata: {
          ...tempMeta,
          provider: "nango",
        },
      },
      {
        onSuccess: async () => {
          refetch();
          // setStep(1);
          createToast(`Successfully integrated to Slack`, {
            type: "success",
            timeout: 3000,
          });
        },
      }
    );
  };
  const handleChoose = async (app: string, type: IntegrationType) => {
    nango
      .auth(app, user?.owner)
      .then((result: { providerConfigKey: string; connectionId: string }) => {
        // do something
        // setData({ ...result, token: "true" });
        handleSave(
          {
            integrationType: result.providerConfigKey,
            integrationId: result.connectionId,
          },
          type
        );
        createToast(`Slack integration created!`, {
          type: "success",
          timeout: 3000, // in 3 seconds
        });
      })
      .catch((err: { message: string; type: string }) => {
        console.log({ err });

        createToast(`Oops something went wrong`, {
          type: "error",
          timeout: 3000, // in 3 seconds
        });

        // handle error
      });
  };

  useEffect(() => {
    if (redirectZendesk) {
      const { url } = redirectZendesk;
      window.location.href = url;
    }
  }, [redirectZendesk]);

  return (
    <div className={cx(styles.container)}>
      <>
        <main>
          <IntegrationButton
            text="Confluence"
            imgSrc="https://seeklogo.com/images/C/confluence-logo-D9B07137C2-seeklogo.com.png"
            onClick={() => {
              handleChoose("confluence", IntegrationType.KNOWLEDGE_BASE);
            }}
            isActive={meta?.some(
              (item: any) => item.integrationType === "confluence"
            )}
          />
          <IntegrationButton
            text="Jira"
            imgSrc="https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png"
            onClick={() => {
              handleChoose("jira", IntegrationType.PRODUCT_ANALYTICS);
            }}
            isActive={meta?.some(
              (item: any) => item.integrationType === "jira"
            )}
          />
          {/* <IntegrationButton
            text="Salesforce"
            imgSrc="/salesforce.png"
            onClick={() => {
              handleChoose("salesforce");
            }}
            isActive={meta?.integrationType === "salesforce"}
          /> */}
        </main>
      </>
    </div>
  );
};

const IntegrationButton = ({
  text,
  imgSrc,
  isActive = false,
  onClick,
}: {
  text: string;
  imgSrc: string;
  isActive?: boolean;
  onClick: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);

  const loadingToggle = () => setLoading((prev) => !prev);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        loadingToggle();
      }, 2000);
    }
  }, [isLoading]);

  return (
    <button
      className={cx(styles.integrationButton, { [styles.active]: isActive })}
      onClick={() => {
        loadingToggle();
        onClick();
      }}
    >
      {isLoading ? (
        "loading.."
      ) : (
        <>
          <img src={imgSrc} alt="integration" />
          {text}
        </>
      )}
    </button>
  );
};
