import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { ETicketStatus } from "../../assets/types";
export const TicketInfo = ({
  ticketNumber,
  subjectTicket,
  statusTicket,
}: {
  ticketNumber: string;
  subjectTicket: string;
  statusTicket: ETicketStatus | undefined;
}) => {
  const statusCssClass = useMemo(() => {
    const statusMap = {
      [ETicketStatus.OPEN]: styles.open,
      [ETicketStatus.SOLVED]: styles.solved,
      [ETicketStatus.CLOSED]: styles.closed,
    };
    return statusTicket ? statusMap[statusTicket] || "" : "";
  }, [statusTicket]);

  return (
    <div className={styles.main}>
      <div className={styles.statusBar}>
        #{ticketNumber}
        <span className={cx(styles.badge, statusCssClass)}>{statusTicket}</span>
      </div>
      <div className={styles.footer}>{subjectTicket}</div>
    </div>
  );
};
