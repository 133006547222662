import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useSimilarTickets = (enabled: boolean, topicId: number) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/releases/${topicId}/similar-tickets`,
    queryKey: `get-similar-tickets-${topicId}`,
    method: "GET",
    enabled,
  });
};
