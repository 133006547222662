import { graphql } from "../../../gql/gql";
import { useAuthorizedMutation, useGraphQLAuthorizedQuery } from "../../../api";
import { params } from "../../../params";

const getMembersByOwnerQueryDocument = graphql(/* GraphQL */ `
  query listMembersByOwner {
    listUsers {
      nextToken
      items {
        id
        email
        isManager
      }
    }
  }
`);

export const useMembers = (owner: string, enabled: boolean) => {
  return useGraphQLAuthorizedQuery({
    queryKey: ["getMembers", owner],
    url: `${params.GRAPHQL_URL}/graphql/`,
    document: getMembersByOwnerQueryDocument,
    variables: { owner },
    enabled,
  });
};

export const useCreateMember = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth/`,
  });
};

export const useDeleteMember = (email: string) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/auth/${email}/`,
    method: "DELETE",
  });
};
