import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useParams } from "react-router-dom";
import { createToast } from "vercel-toast";

import { Header } from "../../components/header";
import { ReviewPage } from "../../components/reviewPage";
import { Loader } from "../../components/loader";

import styles from "./styles.module.scss";
import { Session, useRecordings } from "./api/useSession";

dayjs.extend(duration);

export const Report = () => {
  const { id } = useParams();
  const [session, setSession] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const { data: sessionData, isLoading } = useRecordings({
    ticketId: id ?? "",
    pageNumber,
  });

  useEffect(() => {
    let network: any[] = [];
    let localStorage: any[] = [];
    let recordings: any[] = [];
    let events: any[] = [];
    let console: any[] = [];
    let browserInfo: any = {};

    const data = sessionData as Session;
    if (data?.nextRecordingPage) {
      data?.nextRecordingPage === 1 &&
        createToast("Long video loading, please wait...", {
          type: "dark",
          timeout: 5000,
        });
      setPageNumber(data.nextRecordingPage);
    }

    if (data && data?.session) {
      if (data.networkEvents?.length) {
        network = data.networkEvents.map((item: any) => item?.value).flat();
      }

      if (data.localStorageEvents?.length) {
        localStorage = data.localStorageEvents
          .map((item: any) => item?.value)
          .flat();
      }

      if (data.recordings?.length) {
        recordings = data.recordings
          // eslint-disable-next-line array-callback-return
          .map((item: any) => {
            try {
              return JSON.parse(item?.value);
            } catch (e) {
              createToast("Error displaying recordings", {
                type: "error",
              });
            }
          })
          .flat();
      }

      if (data.browserEvents?.length) {
        events = data.browserEvents.map((item: any) => item?.value).flat();
      }

      if (data.consoleEvents?.length) {
        console = data.consoleEvents.map((item: any) => item?.value).flat();
      }

      if (data.browserInfo) {
        browserInfo = data.browserInfo;
      }
    }

    setSession((prev: any) => ({
      network: [...(prev?.network || []), ...network],
      localStorage: [...(prev?.localStorage || []), ...localStorage],
      recordings: [...(prev?.recordings || []), ...recordings],
      events: [...(prev?.events || []), ...events],
      console: [...(prev?.console || []), ...console],
      browserInfo: { ...prev?.browserInfo, ...browserInfo },
    }));
  }, [sessionData]);

  const loading = useMemo(() => {
    const { recordings } = session as any;
    return isLoading && (!recordings || !recordings?.length);
  }, [isLoading, session]);

  return (
    <div className={styles.record_container}>
      <div className={styles.record_main}>
        <Header id={id} name={""} description={""} isloading={loading} />
        {loading ? (
          <Loader />
        ) : (
          <ReviewPage
            data={session}
            loading={false}
            handleLoadChunk={() => {}}
          />
        )}
      </div>
    </div>
  );
};
