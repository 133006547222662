import { useAuthorizedQuery } from ".";
import { ETimesFilter } from "../assets/types";
import { params } from "../params";

export const useTicketsByTopic = (
  topic: string,
  page: number,
  timeFilter: ETimesFilter,
  enabled: boolean
) => {
  return useAuthorizedQuery({
    queryKey: `tickets-${topic}-${page}-${timeFilter}`,
    url: `${params.API_URL}/platform/dashboard/topics/${topic}?page=${page}&timeFilter=${timeFilter}`,
    method: "GET",
    enabled,
  });
};
