import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  // todo should catch error and handle it
  useEffect(() => {
    if (error) {
      alert(error);
    } else {
      if (isAuthenticated) {
        navigate("/");
      } else {
        loginWithRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  return <div></div>;
}
