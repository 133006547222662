const isStg =
  window.location.href.includes("staging-app") ||
  window.location.href.includes("localhost");

const isEU = window.location.href.includes(`app-eu.`);

export const params = isStg
  ? {
      PARAGON_PROJECT_ID: "56315a1f-fa16-496d-8f22-df442e9592e1",
      AUTH0_DOMAIN: "dev-jqkpam3mofa5uwym.us.auth0.com",
      CLIENT_ID: "6KMh4Oaj1JggwAvwSKS3sLhGg0IZCcq2",
      API_URL: "https://stage-api.thequack.ai",
      GRAPHQL_URL: "https://stage-api.thequack.ai",
      TRACK_TOKEN: "e2cdf9bd5d148905b74f4e1f8ea28091",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/4bvaghLHx0OnvNVUN-DoBA",
      AI_URL: "https://ai.quack.cx", // currnetly we have just production
      // AI_URL: "http://localhost:3001",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
    }
  : isEU
  ? {
      PARAGON_PROJECT_ID: "---",
      AUTH0_DOMAIN: "quackai.eu.auth0.com",
      CLIENT_ID: "RRzTiMeLTtTFplgBsucD7eVAHQeMgQN6", // auth0
      API_URL: "https://eu.api.thequack.ai",
      GRAPHQL_URL: "https://eu.api.thequack.ai",
      TRACK_TOKEN: "bc2657e23891e11a179adee09eb9e7e1",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/zffXPKX7FkWTZhOtjSYUxA",
      AI_URL: "https://ai-eu.quack.cx",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
    }
  : {
      PARAGON_PROJECT_ID: "47c156e9-7918-4760-8ba5-1cbdbb59af6d",
      AUTH0_DOMAIN: "quackai.us.auth0.com",
      CLIENT_ID: "QG8XLnC9IgGszZGNg22XqPZRah8W00EE",
      API_URL: "https://api.thequack.ai",
      GRAPHQL_URL: "https://api.thequack.ai",
      TRACK_TOKEN: "bc2657e23891e11a179adee09eb9e7e1",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/zffXPKX7FkWTZhOtjSYUxA",
      AI_URL: "https://ai.quack.cx",
      // AI_URL: "http://localhost:3001",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
    };
