import React, { ButtonHTMLAttributes, ReactNode } from "react";
import cx from "classnames";

import { Typography } from "../typography";

import styles from "./styles.module.scss";

type TSize = "S" | "M";
type TTheme =
  | "primary"
  | "secondary"
  | "outline_secondary"
  | "outline_primary"
  | "success";

export const Button = ({
  children,
  size = "S",
  theme = "secondary",
  comingSoon = false,
  previousStyle = "",
  ...props
}: {
  size?: TSize;
  theme?: TTheme;
  children: ReactNode;
  comingSoon?: boolean;
  previousStyle?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Typography theme="disabled" type="button">
      <button
        type="button"
        {...props}
        className={cx(styles.container, {
          [styles.primary]: theme === "primary",
          [styles.comingSoon]: comingSoon,
          [styles.outline_primary]: theme === "outline_primary",
          [styles.outline_secondary]: theme === "outline_secondary",
          [styles.previousStyle]: previousStyle === "outline_secondary",
          [styles.success]: theme === "success",
          [styles.M]: size === "M",
          [styles.S]: size === "S",
        })}
      >
        {children}
      </button>
    </Typography>
  );
};
