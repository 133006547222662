import React, { useState } from "react";

import { InstructionsProvider } from "./context/instructions";
import styles from "./styles.module.scss";
import { Chat } from "./chat";
import { RigthPanel } from "./rightPanel";
import { ReleaseProvider } from "./context/release";

export const Simulator = () => {
  const [topic, setTopic] = useState<string | null>(null);
  const [isTraining, setTraining] = useState(true);

  return (
    <ReleaseProvider>
      <InstructionsProvider>
        <div className={styles.container}>
          <div className={styles.chatContainer}>
            <Chat {...{ topic, setTopic, isTraining, setTraining }} />
          </div>
          <div className={styles.configurationPanelContainer}>
            <RigthPanel {...{ topic, isTraining }} />
          </div>
        </div>
      </InstructionsProvider>
    </ReleaseProvider>
  );
};
