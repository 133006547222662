import React, { useMemo } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export const Tag = ({
  isEnabled,
  isPublish,
  isExpanded,
}: {
  isEnabled: boolean;
  isPublish: boolean;
  isExpanded: boolean;
}) => {
  const isPublishSign = useMemo(
    () => !isEnabled && isPublish,
    [isEnabled, isPublish]
  );
  return (
    <div
      className={cx(styles.tag, {
        [styles.enabled]: isEnabled,
        [styles.published]: isPublishSign,
        [styles.disabled]: !isEnabled,
        [styles.collapsed]: !isExpanded,
      })}
    >
      {isExpanded ? (
        <>{isPublishSign ? "Published" : isEnabled ? "Enabled" : "Disabled"}</>
      ) : (
        <>
          {isPublishSign ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill=" var(--ds_gray_1000)" />
            </svg>
          ) : isEnabled ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="url(#paint0_linear_811_842)" />
              <defs>
                <linearGradient
                  id="paint0_linear_811_842"
                  x1="0"
                  y1="0"
                  x2="7.09486"
                  y2="1.88999"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D33373" />
                  <stop offset="1" stopColor="#4078D5" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="transperant" />
              <defs>
                <linearGradient
                  id="paint0_linear_811_842"
                  x1="0"
                  y1="0"
                  x2="7.09486"
                  y2="1.88999"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D33373" />
                  <stop offset="1" stopColor="#4078D5" />
                </linearGradient>
              </defs>
            </svg>
          )}
        </>
      )}
    </div>
  );
};
