import { useAuthorizedQuery } from ".";
import { ETimesFilter, getFilteredDate } from "../assets/types";
import { params } from "../params";

export const useTicketMetrics = (
  timeFilter: ETimesFilter,
  groupBy: "topics" | "tickets",
  enabled: boolean
) => {
  const date = getFilteredDate(timeFilter);

  return useAuthorizedQuery({
    queryKey: `useTicketMetrics-${timeFilter}-${groupBy}`,
    url: `${params.API_URL}/platform/dashboard/tickets/metrics?timeFilter=${date}&rawData=false&groupBy=${groupBy}`,
    method: "GET",
    enabled,
  });
};
