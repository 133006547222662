import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useGetSuggestedArticles = (topicId: string, enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useGetSuggestedArticles-${topicId}`,
    url: `${params.API_URL}/knowledge-base-handler/knowledge/tenants/${topicId}/suggested-articles`,
    method: "GET",
    enabled,
  });
};
