import React, { useMemo, useState } from "react";

import styles from "./styles.module.scss";
import { GDashboardTable } from "../../components/geistTableDashboard";
import { EColumnTypes } from "../../components/geistTableDashboard/index.types";
import { useVoc } from "../../api/useVoc";
import cx from "classnames";

import { ECategories, IVocTicket } from "./voc.types";
import { ETimesFilter } from "../../assets/types";
import { BigSelect } from "../../components/geistSelect";
import { Tabs } from "@geist-ui/core";
import { Bars } from "./components/bars";
const timeOptions = [
  { id: ETimesFilter.ONE_WEEK, name: "Last 7 days" },
  { id: ETimesFilter.TWO_WEEKS, name: "Last 14 days" },
  { id: ETimesFilter.ONE_MONTH, name: "Last 1 month" },
];

const columns = [
  {
    value: "sourceID",
    label: "id",
    type: EColumnTypes.DEFAULT,
  },
  {
    value: "ticket",
    label: "Ticket",
    type: EColumnTypes.DEFAULT,
  },
  {
    value: "topic",
    label: "Topic",
    type: EColumnTypes.DEFAULT,
  },
  { label: "TTR", value: "TTR", type: EColumnTypes.TIME },
  {
    label: "Handling time",
    value: "handlingTime",
    type: EColumnTypes.TIME,
  },
  {
    value: "ticketId",
    label: "See ticket",
    type: EColumnTypes.BUTTON,
  },
];

export const VoiceOfCustomer = () => {
  const [timeFilter, setTimeFilter] = useState<ETimesFilter>(
    ETimesFilter.ONE_WEEK
  );

  const [categoryDownBarChosen, setCategoryChosen] = useState<ECategories>(
    ECategories.Bug
  );

  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);

  const [page, setPage] = useState(0);
  const { data, isLoading } = useVoc(categoryDownBarChosen, timeFilter, page);
  const handleSetPage = () => {
    setPage(page + 1);
  };
  const structedData = useMemo(() => {
    if (data?.tickets) {
      return data.tickets.map((ticket: IVocTicket, index: number) => {
        return {
          id: index,
          ticketId: ticket.id,
          sourceID: ticket.displayId ?? ticket.sourceID,
          handlingTime: ticket.ticketMetrics?.requesterWaitTimeCalendar ?? 0,
          ticket: ticket.subject,
          topic: ticket.topic?.topic ?? ticket.topic?.name,
          TTR: ticket.ticketMetrics?.fullResolutionTimeCalendar ?? 0,
          seeTicket: ticket.id,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        Voc of all domains on
        <BigSelect
          filter={categoriesFilter}
          setFilter={(value) => {
            setCategoriesFilter(value as ECategories[]);
            setPage(0);
          }}
          options={Object.keys(ECategories).map((key) => ({
            id: ECategories[key as keyof typeof ECategories],
            name: key.split("_").join(" "),
          }))}
          isLoading={false}
          multiple={"All Categories"}
          maxWidth="70px"
        />
        {"Over the"}
        <BigSelect
          filter={timeFilter}
          setFilter={(value) => {
            setTimeFilter(value as ETimesFilter);
            setPage(0);
          }}
          options={timeOptions}
          isLoading={false}
          maxWidth="100px"
        />
      </div>
      <Bars categoriesFilter={categoriesFilter} timeFilter={timeFilter} />

      <Tabs
        value={categoryDownBarChosen}
        className={styles.categoriesTabs}
        onChange={(value) => setCategoryChosen(value as ECategories)}
      >
        <Tabs.Item label="Bugs" value={ECategories.Bug}>
          <>
            {!isLoading && structedData ? (
              <GDashboardTable
                dataArray={structedData}
                nextPage={data?.isThereNextPage}
                onNextPage={handleSetPage}
                handleRowClick={() => {}}
                selectedTopic={20}
                columns={columns}
              />
            ) : (
              <div className={cx(styles.loading, styles.categoriesTabs)}></div>
            )}
          </>
        </Tabs.Item>
        <Tabs.Item label="Feature Requests" value={ECategories.Feature_Request}>
          {!isLoading && structedData ? (
            <GDashboardTable
              dataArray={structedData}
              nextPage={data?.isThereNextPage}
              onNextPage={handleSetPage}
              handleRowClick={() => {}}
              selectedTopic={20}
              columns={columns}
            />
          ) : (
            <div className={cx(styles.loading, styles.categoriesTabs)}></div>
          )}
        </Tabs.Item>
        <Tabs.Item label="Requests" value={ECategories.Requests}>
          {!isLoading && structedData ? (
            <GDashboardTable
              dataArray={structedData}
              nextPage={data?.isThereNextPage}
              onNextPage={handleSetPage}
              handleRowClick={() => {}}
              selectedTopic={20}
              columns={columns}
            />
          ) : (
            <div className={cx(styles.loading, styles.categoriesTabs)}></div>
          )}
        </Tabs.Item>
      </Tabs>
    </div>
  );
};
