import { Dict } from "mixpanel-browser";
import { ActivationEvents } from "./activation";
import { KnowledgeEvents } from "./knowledge";
import { GeneralEvents } from "./general";
import { ReportEvents } from "./report";
import { OpportunitiesEvents } from "./opportunities";

type MixpanelEvents =
  | ActivationEvents
  | KnowledgeEvents
  | GeneralEvents
  | ReportEvents
  | OpportunitiesEvents;

declare global {
  interface Window {
    analytics: any;
    track_properties: any;
  }
}

export const mixSetUserProps = (setOnce: boolean, properties: Dict) => {
  window.analytics.identify(properties.id, properties);
};

export const mixRegisterSuperProps = (properties: Dict) =>
  (window.track_properties = {
    ...(window.track_properties || {}),
    ...properties,
  });
export const mixUnregisterSuperProps = (properties: string[]) => {
  properties.forEach((property) => {
    window.track_properties = {
      ...(window.track_properties || {}),
      [property]: null,
    };
  });
};

export const mixTrackEvent = (event: MixpanelEvents, properties?: Dict) =>
  window.analytics.track(event, {
    ...(window.track_properties || {}),
    ...(properties || {}),
  });

export {
  ActivationEvents,
  KnowledgeEvents,
  GeneralEvents,
  ReportEvents,
  OpportunitiesEvents,
};
