import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import cx from "classnames";
import { useFeatureFlag } from "configcat-react";

import { Redirect } from "../redirect";
import logoImg from "../../assets/logo.png";
import { PopoverMenu } from "../popup";
import {
  ESessionStorage,
  fetchFromSessionStorage,
} from "../../assets/storage/sessionStorage";
import { GeneralEvents, mixTrackEvent } from "../../assets/mixpanel";
import { GeistBadge } from "../atoms/geistBadge";

import styles from "./styles.module.scss";
import { ENavigate, PagesIcons } from "./sidebar.types";

const unprotected = ["/installation-successfull", "/welcome"];

const reports = [
  ENavigate.HOME,
  // ENavigate.ASSESSMENT,
  ENavigate.SIMULATOR,
  ENavigate.KNOWLEDGE,
  ENavigate.SAMPLING,
];

const tooltipMapper: { [x: string]: string } = {
  [ENavigate.HOME]: "Dashboard",
  [ENavigate.ASSESSMENT]: "Topics",
  [ENavigate.ACTIVATION]: "Activation",
  [ENavigate.SIMULATOR]: "Simulator",
  [ENavigate.KNOWLEDGE]: "Knowledge management",
  [ENavigate.SAMPLING]: "Quality",
  [ENavigate.PERFORMANCE]: "Agents",
  [ENavigate.VOC]: "Categories",
  [ENavigate.CUSTOMERS]: "Customers",
  [ENavigate.SESSIONS_REPORTS]: "Sessions",
};

export const Sidebar = () => {
  const { logout, isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstTopic, setFirstTopic] = useState<string>("");

  const { value: sidebarValues } = useFeatureFlag("sidebar", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });
  const { value: beta } = useFeatureFlag("betaFeatures", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const agents = [
    ENavigate.ASSESSMENT,
    ...(sidebarValues.split(",") as ENavigate[]),
  ];
  const explorer = agents.filter(
    (x) =>
      ![ENavigate.KNOWLEDGE, ENavigate.SAMPLING, ENavigate.SIMULATOR].includes(
        x
      )
  );
  // const agents = sidebarValues.split(",") as ENavigate[];
  const betaPages = beta.split(",") as ENavigate[];

  const handleClick = (page: ENavigate = ENavigate.HOME) => {
    mixTrackEvent(GeneralEvents.MOVE_PAGE_FROM_SIDEBAR, {
      oldPage: location.pathname.split("/")[1],
      newPage: page,
    });
    switch (page) {
      case ENavigate.ACTIVATION:
        navigate(`/${page}/${firstTopic}`);
        break;
      default:
        navigate(`/${page}`);
        break;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const _firstTopic = fetchFromSessionStorage(ESessionStorage.FIRST_TOPIC);

      if (_firstTopic) {
        setFirstTopic(_firstTopic);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (unprotected.includes(window.location.pathname)) return null;

  if (isLoading)
    return (
      <div className={cx(styles.dark_mode, styles.container)}>
        <div className={styles.loading} />
      </div>
    );

  return (
    <div className={cx(styles.dark_mode, styles.container)}>
      {isAuthenticated ? (
        <>
          <div className={styles.topContainer}>
            <div className={styles.imageContainer}>
              <img
                src={logoImg}
                alt="Quack Logo"
                onClick={() => handleClick()}
              />
            </div>
            <div className={styles.pagesContainer}>
              {reports.map((page: ENavigate) => {
                return (
                  // <Tooltip
                  //   key={page}
                  //   leaveDelay={0}
                  //   text={tooltipMapper[page]}
                  //   placement="right"
                  //   type="dark"
                  //   hideArrow
                  //   padding={0.75}
                  //   font={0.75}
                  // >
                  <div
                    key={page}
                    className={cx(styles.pageContainer, {
                      [styles.active]: page.includes(
                        location.pathname.split("/")[1]
                      ),
                    })}
                    onClick={() => handleClick(page)}
                  >
                    {PagesIcons[page]}
                    <span>{tooltipMapper[page]}</span>
                    {betaPages.includes(page) && (
                      <span className={styles.beta}>
                        <GeistBadge smaller>Beta</GeistBadge>
                      </span>
                    )}
                  </div>
                  //</Tooltip>
                );
              })}
              <hr />
              {explorer.map((page: ENavigate) => {
                if (tooltipMapper[page]) {
                  return (
                    // <Tooltip
                    //   key={page}
                    //   leaveDelay={0}
                    //   text={tooltipMapper[page]}
                    //   placement="right"
                    //   hideArrow
                    //   type="dark"
                    //   padding={0.75}
                    //   font={0.75}
                    // >
                    <div
                      key={page}
                      className={cx(styles.pageContainer, {
                        [styles.active]: page.includes(
                          location.pathname.split("/")[1]
                        ),
                      })}
                      onClick={() => handleClick(page)}
                    >
                      {PagesIcons[page]}
                      <span>{tooltipMapper[page]}</span>
                      {betaPages.includes(page) && (
                        <span className={styles.beta}>
                          <GeistBadge smaller>Beta</GeistBadge>
                        </span>
                      )}
                    </div>
                    // </Tooltip>
                  );
                }

                return <></>;
              })}
            </div>
          </div>
          <div className={styles.pagesContainer}>
            <PopoverMenu
              options={[{ key: "logout", label: "Logout" }]}
              handleClick={(key) =>
                key === "logout" &&
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              openHover
              handleTriggerClick={() => handleClick(ENavigate.SETTINGS)}
            >
              <div
                className={cx(styles.pageContainer, {
                  [styles.active]:
                    location.pathname.split("/")[1] === ENavigate.SETTINGS,
                })}
              >
                {PagesIcons[ENavigate.SETTINGS]}
                <span>Settings</span>
              </div>
            </PopoverMenu>
          </div>
        </>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div className={styles.imageContainer}>
              <img
                src={logoImg}
                alt="Quack Logo"
                onClick={() => handleClick()}
              />
            </div>
          </div>
          <Redirect to="/login" />
        </>
      )}
    </div>
  );
};
