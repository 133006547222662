import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

export const Chart = ({
  data,
  width = 100,
  height = 50,
}: {
  data: { month: number; count: number }[];
  width?: number;
  height?: number;
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={data} width={width} height={height}>
        <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
