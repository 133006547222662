import React from "react";
import cx from "classnames";
import { Card, Text, Tooltip } from "@geist-ui/core";
import { IoInformationCircleSharp } from "react-icons/io5";
import { ArrowRight } from "@geist-ui/icons";

import { Sun } from "../../../../components/atoms/sun";

import styles from "./styles.module.scss";

export const DashboardCard = ({
  smallHead,
  bigHead,
  tooltip,
  grow,
  children,
  expand,
  loading,
  loadingClass = "",
  maxHeight,
  maxWidthPer,
  addSun = false,
  bigHeadFont = false,
  removeMargin = false,
}: {
  smallHead?: string;
  bigHead?: string;
  tooltip?: string;
  grow?: number;
  children?: React.ReactNode;
  expand?: () => void;
  loading?: boolean;
  loadingClass?: string;
  maxHeight?: number;
  maxWidthPer?: number;
  addSun?: boolean;
  bigHeadFont?: boolean;
  removeMargin?: boolean;
}) => {
  return (
    <Card
      className={cx(
        styles.dashboardCard,
        { [styles.bottom]: expand },
        { [styles.skeleton]: loading },
        { [styles[loadingClass]]: loadingClass }
      )}
      hoverable
      style={{
        flexGrow: grow || 1,
        borderRadius: "12px",
        maxHeight: maxHeight ? `${maxHeight}px` : "auto",
        maxWidth: maxWidthPer ? `${maxWidthPer}%` : "auto",
      }}
    >
      <div className={styles.content}>
        <div
          className={cx(styles.top, { [styles.removeMargin]: removeMargin })}
        >
          <div className={styles.headline}>
            <div className={styles.smallHeadContainer}>
              {smallHead && <Text h5>{smallHead}</Text>}
              {tooltip && !loading && (
                <Tooltip text={tooltip} style={{ height: "14px" }}>
                  <IoInformationCircleSharp
                    className={styles.informationFill}
                  />
                </Tooltip>
              )}
            </div>
            {bigHead && (
              <Text
                h2
                className={cx({
                  [styles.bigHeadFont]: bigHeadFont,
                })}
              >
                {bigHead}
              </Text>
            )}
          </div>
        </div>
        {children}
      </div>
      {expand && !loading && (
        <div className={styles.bottom}>
          <div className={styles.explore} onClick={expand}>
            Explore
            <ArrowRight />
          </div>
        </div>
      )}
      {addSun && (
        <div className={styles.sunContainer}>
          <Sun />
        </div>
      )}
    </Card>
  );
};
