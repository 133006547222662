import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useGetTopics = (enabled: boolean = true) => {
  return useAuthorizedQuery({
    queryKey: "topics",
    url: `${params.API_URL}/platform/dashboard/topics`,
    method: "GET",
    enabled,
  });
};
