import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useAccuracySources = (
  commonQuestionId: number,
  enabled: boolean
) => {
  return useAuthorizedQuery({
    queryKey: `useAccuracySources-${commonQuestionId}`,
    url: `${params.API_URL}/platform/auto-activate/common-question-accuracy/${commonQuestionId}`,
    method: "GET",
    enabled,
  });
};

export const useGetArticlesByIds = (articleIds: string[], enabled: boolean) => {
  const paramsArray = articleIds.map((id) => `ids[]=${id}`).join("&");
  return useAuthorizedQuery({
    queryKey: `useGetArticlesByIds-${useGetArticlesByIds.toString()}`,
    url: `${params.API_URL}/knowledge-base-handler/knowledge/articles?${paramsArray}`,
    method: "GET",
    enabled,
  });
};
