import { EBadgeMode } from "../../components/atoms/geistBadge";

export type TReportDisplay = {
  id: string;
  ticketId: string;
  createdAt: string;
  startTime: string;
  endTime: string;
  sessionId: string;
  session: {
    id: string;
    createdAt: string;
    updatedAt: string;
    userEmail: string;
    userId: string;
    owner: string;
    browserInfo: {
      id: string;
      createdAt: string;
      browserName: string;
      locale: string;
      os: string;
      sessionId: string;
    };
  };
  networkEvents: Array<{
    id: string;
    createdAt: string;
    sessionId: string;
    value: {
      meta: {
        length: number;
      };
      request: {
        uri: string;
        body: object;
        time: number;
        type: string;
        verb: string;
        headers: {
          Accept: string;
          "X-CSRFTOKEN": string;
          "Content-Type": string;
        };
      };
      response: {
        body: object;
        time: number;
        status: number;
        headers: string;
      };
      networkType: string;
    };
  }>;
  agentAssigned?: {
    id: number;
    externalId: string;
    email: string;
    name: string;
    tenantId: string;
    groupId: string;
  };
  status: string;
};

export const badgeOptions = {
  0: EBadgeMode.GREEN,
  1: EBadgeMode.RED,
  2: EBadgeMode.RED,
  3: EBadgeMode.RED,
  4: EBadgeMode.RED,
  5: EBadgeMode.RED,
  6: EBadgeMode.RED,
  7: EBadgeMode.RED,
  8: EBadgeMode.RED,
  9: EBadgeMode.RED,
  10: EBadgeMode.RED,
  11: EBadgeMode.RED,
  12: EBadgeMode.RED,
  13: EBadgeMode.RED,
  14: EBadgeMode.RED,
  15: EBadgeMode.RED,
  16: EBadgeMode.RED,
  17: EBadgeMode.RED,
  18: EBadgeMode.RED,
  19: EBadgeMode.RED,
  20: EBadgeMode.RED,
  21: EBadgeMode.RED,
  22: EBadgeMode.RED,
  23: EBadgeMode.RED,
  24: EBadgeMode.RED,
  25: EBadgeMode.RED,
  26: EBadgeMode.RED,
  27: EBadgeMode.RED,
  28: EBadgeMode.RED,
  29: EBadgeMode.RED,
  30: EBadgeMode.RED,
};
