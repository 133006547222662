import React from "react";
import { GiBrain } from "react-icons/gi";
import { Tooltip } from "@geist-ui/core";

import styles from "./styles.module.scss";
import {
  TAccuracyConfig,
  ELengthAnswer,
  EToneOfVoice,
  EPersonalCustom,
} from "./configurationTools.types";

export const BrainTool = ({ config }: { config: TAccuracyConfig }) => {
  const lengthLabels = {
    [ELengthAnswer.SHORT]: "Short",
    [ELengthAnswer.MEDIUM]: "Medium",
    [ELengthAnswer.LONG]: "Long",
  };

  const toneOfVoiceLabels = {
    [EToneOfVoice.EMPATHETIC]: "🤝 Empathetic Tone",
    [EToneOfVoice.TECHNICAL]: "🛠️ Technical Tone",
    [EToneOfVoice.SUPPORTIVE]: "🤗 Supportive Tone",
    [EToneOfVoice.ENCOURAGE]: "🤩 Encourage Feedback",
  };

  const personalCustomLabels = {
    [EPersonalCustom.BASIC]: "Basic",
    [EPersonalCustom.MELLOW]: "Mellow",
    [EPersonalCustom.HYPER]: "Hyper",
  };

  const effectiveConfig = config;

  return (
    <Tooltip
      text={
        <div className={styles.configDisplay}>
          <div className={styles.configItem}>
            Length: <span>{lengthLabels[effectiveConfig.lengthAnswer]}</span>
          </div>
          <div className={styles.configItem}>
            Tone of Voice:{" "}
            <span>{toneOfVoiceLabels[effectiveConfig.toneOfVoice]}</span>
          </div>
          <div className={styles.configItem}>
            Personalization:{" "}
            <span>{personalCustomLabels[effectiveConfig.personalCustom]}</span>
          </div>
          {effectiveConfig.customInstructions && (
            <div className={styles.configItem}>
              Custom Instructions:{" "}
              <span>{effectiveConfig.customInstructions}</span>
            </div>
          )}
        </div>
      }
      placement="right"
    >
      <button className={styles.brain}>
        <GiBrain />
      </button>
    </Tooltip>
  );
};
