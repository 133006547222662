import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { Button, Checkbox, Popover, Tabs, Text } from "@geist-ui/core";

import { ThumbsDown, ThumbsUp } from "@geist-ui/icons";
import { Instructions } from "../instructions";
import { ReleaseDrawer } from "../release";
import { useReleases } from "../context/release";
import {
  ManualInstruction,
  ReleaseConversationFeedback,
  TTopic,
} from "../types";
import { useGetTopics } from "../../../api/useGetTopics";
import { useInstructions } from "../context/instructions";
import { Collapsable } from "../../../components/collapsable";

type ExtendedManualInstruction = ManualInstruction & { topicId: number };

export const RigthPanel = ({
  topic,
  isTraining,
}: {
  topic: string | null;
  isTraining: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { releaseConversations, simulators, chosenSimulatorId } = useReleases();

  const { data: topics }: { data: TTopic[] | undefined } = useGetTopics();

  const feedbackSummary = useMemo(() => {
    const summary: { good: number; bad: number; topics: number[] } = {
      good: 0,
      bad: 0,
      topics: [],
    };
    releaseConversations?.forEach((conversation) => {
      if (conversation.feedback === "POSITIVE") {
        if (!summary.topics.includes(conversation.topicId!)) {
          summary.topics.push(conversation.topicId!);
        }
        summary.good += 1;
      } else if (conversation.feedback === "NEGATIVE") {
        summary.bad += 1;
      }
    });
    return summary;
  }, [releaseConversations]);

  const FeedbackPopover: React.FC = () => (
    <div style={{ width: "400px" }}>
      <Tabs initialValue="good" className={styles.tabs}>
        <Tabs.Item
          label={
            <>
              <ThumbsUp /> Good
            </>
          }
          value={"good"}
        >
          <div className={styles.conversationContainer}>
            {releaseConversations.filter(
              (r) => r.feedback === ReleaseConversationFeedback.POSITIVE
            ).length === 0 && (
              <div className={styles.emptyConversations}>
                There is no conversations with Good Feedback.
              </div>
            )}
            {releaseConversations
              .filter(
                (r) => r.feedback === ReleaseConversationFeedback.POSITIVE
              )
              .map((r) => {
                const topic = topics?.find(
                  (t: { id: number }) => r.topicId === t.id
                );

                return (
                  <div className={styles.conversation}>
                    <span>
                      {
                        r.releaseComments?.sort((a, b) => a.id - b.id)[0]
                          .comment
                      }
                    </span>
                    <label>{topic?.topic ?? topic?.name}</label>
                  </div>
                );
              })}
          </div>
        </Tabs.Item>
        <Tabs.Item
          label={
            <>
              <ThumbsDown /> Bad
            </>
          }
          value="bad"
        >
          <div className={styles.conversationContainer}>
            {releaseConversations.filter(
              (r) => r.feedback === ReleaseConversationFeedback.NEGATIVE
            ).length === 0 && (
              <div className={styles.emptyConversations}>
                There is no conversations with Bad Feedback.
              </div>
            )}
            {releaseConversations
              .filter(
                (r) => r.feedback === ReleaseConversationFeedback.NEGATIVE
              )
              .map((r) => {
                const topic = topics?.find(
                  (t: { id: number }) => r.topicId === t.id
                );
                return (
                  <div className={styles.conversation}>
                    <span>{r.releaseComments[0].comment}</span>
                    <label>{topic?.topic ?? topic?.name}</label>
                  </div>
                );
              })}
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );

  const { manualInstructions, handleSimulatorInstructions, instructions } =
    useInstructions();

  const [topicsToExclude, setTopicsToExclude] = useState<string[]>([]);
  const [topicsNotEngaged, setTopicsNotEngaged] = useState<string[]>([]);

  const publishedRelease = useMemo(() => {
    if (chosenSimulatorId && simulators) {
      const simulator = simulators?.find((s) => s.id === chosenSimulatorId);
      return simulator?.releases.filter(
        (release) => release.id === simulator.publishedReleaseId
      )?.[0];
    }

    return null;
  }, [simulators, chosenSimulatorId]);

  // if there is a published release, present the staged / skiped by it

  const instructionsWithTopicId = useMemo(() => {
    return manualInstructions.map((instruction) => {
      const topic = topics?.find(
        (topic) =>
          topic.topic.toLowerCase() === instruction.topic?.toLowerCase()
      );
      return {
        ...instruction,
        topicId: topic?.id ?? null,
      };
    });
  }, [manualInstructions, topics]);

  const groupedInstructions = useMemo(() => {
    return instructionsWithTopicId.reduce<
      Record<string, ExtendedManualInstruction[]>
    >((acc, instruction) => {
      const topic: string = instruction.topic!;
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push(instruction as any);
      return acc;
    }, {});
  }, [instructionsWithTopicId]);

  const missingTopics = useMemo(() => {
    return feedbackSummary.topics.filter((topicId) => {
      const isMissing = !Object.keys(groupedInstructions).some((key) => {
        return groupedInstructions[key][0].topicId === topicId;
      });
      return isMissing;
    });
  }, [feedbackSummary.topics, groupedInstructions]);

  const handleExcludeInclude = (topic: string) => {
    if (topicsToExclude.includes(topic)) {
      setTopicsToExclude((prev) => prev.filter((x) => x !== topic));
    } else {
      setTopicsToExclude((prev) => [...prev, topic]);
    }
  };

  const [updatedGroupedInstructions] = useMemo(() => {
    const newGroupedInstructions = { ...groupedInstructions };
    const array: ExtendedManualInstruction[] = [];

    missingTopics.forEach((topicId) => {
      const topic = topics?.find((t) => t.id === topicId);
      if (topic) {
        const instruction: ExtendedManualInstruction = {
          topic: topic.topic,
          topicId: topicId,
          type: "manual",
          // its a magic - we want to present the user that there is not instructions here, but we want to add instruction because thats how the ai decide rather he should escalate or not
          content: "Think carefully before answering",
          subTopic: null,
        };
        array.push(instruction);
        newGroupedInstructions[topic?.topic] = [instruction];
      }
    });

    return [newGroupedInstructions, array];
  }, [missingTopics, topics, groupedInstructions]);

  const formattedReleasePolicy = useMemo(() => {
    const parsedPolicy = JSON.parse(publishedRelease?.releasePolicy || "[]");
    return parsedPolicy.reduce((acc: any, item: any) => {
      if (item.type !== "general") {
        if (!acc[item.topic]) {
          acc[item.topic] = [];
        }
        acc[item.topic].push(item);
      }
      return acc;
    }, {});
  }, [publishedRelease?.releasePolicy]);

  useEffect(() => {
    if (topics) {
      setTopicsNotEngaged(
        topics
          ?.filter((t) => {
            return !updatedGroupedInstructions[t.topic];
          })
          .map((t) => t.topic)
      );
      setTopicsToExclude(
        topics
          ?.filter((t) => {
            return !updatedGroupedInstructions[t.topic];
          })
          .map((t) => t.topic)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics, JSON.stringify(updatedGroupedInstructions)]);

  useEffect(() => {
    const generalInstructions = instructions.filter(
      (x) => x.type === "general"
    );

    let engagedInstructionsToRelease: ManualInstruction[] = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(updatedGroupedInstructions).map((topic) => {
      if (!topicsToExclude.includes(topic)) {
        engagedInstructionsToRelease = [
          ...engagedInstructionsToRelease,
          ...updatedGroupedInstructions[topic],
        ];
      }
    });

    const topicsToInclude = topics
      ?.filter((t: { topic: string }) => !topicsToExclude.includes(t.topic))
      .map((t: { topic: string }) => t.topic);

    let notEngagedInstructionsToRelease: ManualInstruction[] = [];
    // eslint-disable-next-line array-callback-return
    topicsNotEngaged.map((topic: string) => {
      if (topicsToInclude?.includes(topic)) {
        notEngagedInstructionsToRelease.push({
          content: "Think carefully before answering",
          topic: topic,
          subTopic: null,
          type: "manual",
        });
      }
    });

    handleSimulatorInstructions([
      ...generalInstructions,
      ...engagedInstructionsToRelease,
      ...notEngagedInstructionsToRelease,
    ]);
  }, [JSON.stringify(topicsToExclude)]);

  return (
    <div className={styles.topContainer}>
      <div className={styles.topbar}>
        <Popover
          content={FeedbackPopover as any}
          hideArrow
          offset={-4}
          placement="bottom"
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <FeedabckPanel type="good" count={feedbackSummary.good} />
            <FeedabckPanel type="bad" count={feedbackSummary.bad} />
          </div>
        </Popover>

        <Button
          type="secondary"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={() => setOpen(true)}
          scale={0.5}
          padding={0}
          font={1.2}
          width={0.8}
        >
          Create Release
        </Button>
      </div>
      <ReleaseDrawer
        isOpen={isOpen}
        setOpen={(v) => setOpen(v)}
        {...{
          updatedGroupedInstructions,
          topicsNotEngaged,
          topicsToExclude,
          handleExcludeInclude,
          formattedReleasePolicy,
          topics,
        }}
      />
      {isTraining ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Instructions topic={topic} />
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          <Text h6>Simulating...</Text>
          <Text small>
            To make changes to the AI Brain, switch back to Training mode.
          </Text>

          <div style={{ marginTop: "150px" }}>
            <Collapsable
              initialCollapsedValue={true}
              isBackgroundColor
              title={
                <div className={styles.customCollapseContianer}>
                  <b>Controller</b>
                  <span>Set which topics to activate during simulation</span>
                </div>
              }
            >
              <div className={styles.choiceList}>
                {Object.keys(updatedGroupedInstructions).map((key) => {
                  return (
                    <div style={{ height: "60px" }}>
                      <Checkbox
                        scale={2}
                        checked={
                          !topicsToExclude.includes(
                            updatedGroupedInstructions[key]?.[0]?.topic
                          )
                        }
                        onChange={(e) => {
                          handleExcludeInclude(key);
                        }}
                      >
                        <div className={styles.choiceItem}>
                          <span>{key}</span>
                          <label className={styles.engaged}>Engaged</label>
                        </div>
                      </Checkbox>
                    </div>
                  );
                })}
                {topicsNotEngaged.map((t) => {
                  return (
                    <div style={{ height: "60px" }}>
                      <Checkbox
                        scale={2}
                        checked={!topicsToExclude.includes(t)}
                        onChange={(e) => {
                          handleExcludeInclude(t);
                        }}
                      >
                        <div className={styles.choiceItem}>
                          <span>{t}</span>
                          <label className={styles.not_engaged}>
                            Not Engaged
                          </label>
                        </div>
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </Collapsable>
          </div>
        </div>
      )}
    </div>
  );
};

const FeedabckPanel = ({
  type,
  count,
}: {
  type: "good" | "bad";
  count: number;
}) => {
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    setChanged(true);
    setTimeout(() => {
      setChanged(false);
    }, 600);
  }, [count]);

  return (
    <div
      className={cx(styles.feedbackContainer, {
        [styles.animationPositive]: type === "good" && isChanged,
        [styles.animationNegative]: type === "bad" && isChanged,
      })}
    >
      {type === "good" ? <ThumbsUp /> : <ThumbsDown />}
      {count} {type}
    </div>
  );
};
