import React, { useMemo } from "react";

import { GDashboardTable } from "../../../components/geistTableDashboard";
import { EColumnTypes } from "../../../components/geistTableDashboard/index.types";
import { ETimesFilter } from "../../../assets/types";
import { useFetchingToasts } from "../../../hooks/useFetchingToasts";
import { usePerformance } from "../../../api/usePerformance";
import { TPerformanceByCommonQuestion } from "../index.type";

import styles from "./styles.module.scss";

const topicPerformanceColumns = [
  {
    value: "commonQuestionName",
    label: "Expression",
    type: EColumnTypes.NAME,
  },
  {
    value: "topicName",
    label: "Topics",
    type: EColumnTypes.BADGE,
  },
  { label: "Tickets", value: "ticketsCount", type: EColumnTypes.DEFAULT },
  { label: "Avg. TTR", value: "avgResolutionTime", type: EColumnTypes.TIME },
  {
    label: "Avg. Response Time",
    value: "avgResponseTime",
    type: EColumnTypes.TIME,
  },
  {
    value: "avgEvaluationScore",
    label: "Evaluation",
    type: EColumnTypes.EVALUATION,
    dataOptions: {
      round: true,
    },
  },
  {
    value: "recentEvaluationScores",
    label: "Recent Evaluations",
    type: EColumnTypes.TRACK,
  },
  {
    value: "accuracy",
    label: "AI Accuracy",
    type: EColumnTypes.ACCURACY,
    columnOptions: {
      columnBorder: true,
    },
  },
];

export const Topics = ({
  timeFilter,
  topics,
}: {
  timeFilter: ETimesFilter;
  topics: string[];
}) => {
  const { data, isLoading, error } = usePerformance({
    enabled: true,
    timeFilter,
    topics,
    groupBy: "common-question",
  });

  const performanceByCommonQuestion = useMemo(
    () =>
      data && Array.isArray(data)
        ? (data.map((item: any) => ({
            ...item,
            recentEvaluationScores: item.recentEvaluationScores.map(
              (score: { id: string; adjustedScore: number }) =>
                score.adjustedScore
            ),
          })) as TPerformanceByCommonQuestion[])
        : [],
    [data]
  );

  useFetchingToasts({
    error: !!error || data?.error,
    emptyState:
      data !== undefined &&
      !isLoading &&
      performanceByCommonQuestion.length === 0,
  });

  return (
    <div className={styles.container}>
      {performanceByCommonQuestion && (
        <GDashboardTable
          loading={isLoading}
          dataArray={
            performanceByCommonQuestion as unknown as Record<string, string>[]
          }
          idName="commonQuestionId"
          handleRowClick={(id: string) => {}}
          selectedTopic={20}
          columns={topicPerformanceColumns}
          headerBorder
          disableSelected
        />
      )}
    </div>
  );
};
