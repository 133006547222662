import dayjs from "dayjs";

export type TFormTypes = "text" | "textarea" | "list";
export interface IBlock {
  type: TFormTypes;
  order: number;
  placeholder: string;
  _key: string;
  key: string;
  label: string;
  is_meta_title?: boolean;
  is_meta_description?: boolean;
}

export type TAssessmentGeneral = {
  topic: string;
  openTickets: number;
  currentAllTickets: number;
  enabled: boolean;
  comparePeriodTickets: {
    tickets: number;
    avgTtr: number;
    avgHanTime: number;
    agentsInv: number;
    avgBackAndForth: number;
  };
  currentPeriodTickets: {
    tickets: number;
    avgTtr: number;
    avgHanTime: number;
    agentsInv: number;
    avgBackAndForth: number;
  };
};

export type TDashboardTicketMetrics = {
  currentMetrics: TTicketMetrics;
  compareMetrics: TTicketMetrics;
};

export type TTicketMetrics = {
  totalTickets: number;
  avgReplies: number;
  avgResolutionTime: number;
  avgRequesterWaitTime: number;
};

export type TTopicMetrics = {
  topicId: number;
  topicName: string;
  ticketCount: number;
  ticketsSolved: number;
  compareTicketCount: number;
  avgReplies: number;
  avgResolutionTime: number;
  avgRequesterWaitTime: number;
};

export type TTicketsTopicView = {
  tickets: TTicketInfo[];
  isThereNextPage: boolean;
};

export type TTicketInfo = {
  id: string;
  displayId: string;
  owner: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  channel: string;
  tags: string[];
};

export type TTicketView = {
  id: string;
  owner: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  description: string;
  topic: string;
  displayId: string | null;
};

export type TTopics = { id: string; topic: string };

export enum ETrendSentiment {
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

export enum ETimesFilter {
  ONE_DAY = "1d",
  ONE_WEEK = "1w",
  TWO_WEEKS = "2w",
  ONE_MONTH = "1m",
  TWO_MONTHS = "2m",
}

export const TimeFilter = [
  { value: ETimesFilter.ONE_DAY, label: "1 Day" },
  { value: ETimesFilter.ONE_WEEK, label: "1 Week" },
  { value: ETimesFilter.TWO_WEEKS, label: "2 Weeks" },
  { value: ETimesFilter.ONE_MONTH, label: "1 Month" },
  { value: ETimesFilter.TWO_MONTHS, label: "2 Months" },
];

export const getFilteredDate = (timeFilter: ETimesFilter): string => {
  let subtractValue;
  switch (timeFilter) {
    case ETimesFilter.ONE_DAY:
      subtractValue = 1;
      break;
    case ETimesFilter.ONE_WEEK:
      subtractValue = 7;
      break;
    case ETimesFilter.TWO_WEEKS:
      subtractValue = 14;
      break;
    case ETimesFilter.ONE_MONTH:
      subtractValue = 30;
      break;
    case ETimesFilter.TWO_MONTHS:
      subtractValue = 60;
      break;
    default:
      subtractValue = 0;
  }
  return dayjs()
    .subtract(subtractValue, "day")
    .format("YYYY-MM-DDTHH:mm:ss.sss");
};

export enum ETicketStatus {
  OPEN = "open",
  CLOSED = "closed",
  SOLVED = "solved",
}

export const TicketStatus = [
  { value: ETicketStatus.OPEN, label: "open" },
  { value: ETicketStatus.CLOSED, label: "closed" },
  { value: ETicketStatus.SOLVED, label: "solved" },
];

export type TMetrics = {
  tickets: number;
  avgTtr: number;
  avgHanTime: number;
  agentsInv: number;
  avgBackAndForth: number;
};

export enum EAllTicketsStatus {
  OPEN = "Open",
  CLOSED = "Closed",
  SOLVED = "Solved",
  DELETE = "Deleted",
  PENDING = "Pending",
}
