import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganizations } from "../../api/useOrganizations";
import { Table, TextField, IconButton, Skeleton } from "@radix-ui/themes";
import {
  MagnifyingGlassIcon,
  ExternalLinkIcon,
  TimerIcon,
} from "@radix-ui/react-icons";
import { Chart } from "../customer/trend";
import styles from "./styles.module.scss";

export const Customers = () => {
  const { data, isLoading } = useOrganizations({ enabled: true });

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((customer: any) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div
      className="container mx-auto p-4"
      style={{
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div className="mb-4" style={{ width: "30%" }}>
        <TextField.Root
          placeholder="Search by customer name"
          value={searchTerm}
          onChange={handleSearchChange}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      </div>
      {isLoading && (
        <Table.Root variant="surface">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>#Tickets</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Trend</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Avg. TTR</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>
                Avg. handling time
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Avg. QA Score</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>User engaged</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array.from({ length: 10 }).map((_, index) => (
              <Table.Row key={index} className={styles.row}>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>organization name</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>2000</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>trend</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>Avg ttr</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>Avg. handling time</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>0 / 5</Skeleton>
                </Table.Cell>
                <Table.Cell
                  style={{
                    verticalAlign: "bottom",
                  }}
                >
                  <Skeleton>user engaged </Skeleton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      )}
      {data && (
        <Table.Root variant="surface">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>#Tickets</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Trend</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Avg. TTR</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>
                Avg. handling time
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Avg. QA Score</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>User engaged</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredData
              .sort((a: any, b: any) => b.ticketCount - a.ticketCount)
              ?.map((customer: any) => {
                return <Row key={customer.id} organization={customer} />;
              })}
          </Table.Body>
        </Table.Root>
      )}
    </div>
  );
};

const Row = ({ organization }: { organization: any }) => {
  const navigate = useNavigate();

  const avgFullResolutionTimeBusiness =
    (organization?.tickets?.reduce((acc: any, ticket: any) => {
      if (ticket.ticketMetrics?.fullResolutionTimeBusiness) {
        return acc + ticket.ticketMetrics.fullResolutionTimeBusiness;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);
  const avgHandlingTimeBusiness =
    (organization?.tickets?.reduce((acc: any, ticket: any) => {
      if (ticket.ticketMetrics?.requesterWaitTimeBusiness) {
        return acc + ticket.ticketMetrics.requesterWaitTimeBusiness;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);

  const avgReplies =
    (organization?.tickets?.reduce((acc: any, ticket: any) => {
      if (ticket.ticketMetrics?.replies) {
        return acc + ticket.ticketMetrics.replies;
      }
      return acc;
    }, 0) || 0) / (organization?.tickets?.length || 1);

  const formatTime = (timeInMinutes: number) => {
    const days = Math.floor(timeInMinutes / (60 * 24));
    const hours = Math.floor((timeInMinutes % (60 * 24)) / 60);
    const minutes = Math.floor(timeInMinutes % 60);

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    return parts.join(" ");
  };

  const avgFullResolutionTimeBusinessString = formatTime(
    avgFullResolutionTimeBusiness
  );

  const avgHandlingTimeBussinessString = formatTime(avgHandlingTimeBusiness);

  const closedTickets =
    organization?.tickets.filter((ticket: any) =>
      ["closed", "solved", "deleted"].includes(ticket.status)
    ).length || 0;

  const agentAssignedCount =
    organization?.tickets.reduce((acc: any, ticket: any) => {
      if (ticket.agentAssignedId) {
        acc[ticket.agentAssignedId] = (acc[ticket.agentAssignedId] || 0) + 1;
      }
      return acc;
    }, {}) || {};

  const ticketsByCategoryAndTopic =
    organization?.tickets.reduce((acc: any, ticket: any) => {
      const category = ticket.category?.name || "other";
      const topic = ticket.topic?.name || "other";
      if (!acc[category]) {
        acc[category] = {};
      }
      if (!acc[category][topic]) {
        acc[category][topic] = [];
      }
      acc[category][topic].push(ticket);
      return acc;
    }, {}) || {};

  const ticketsByTenantUserId =
    organization?.tickets.reduce((acc: any, ticket: any) => {
      const tenantUserId = ticket.tenantUserId || "unknown";
      acc[tenantUserId] = (acc[tenantUserId] || 0) + 1;
      return acc;
    }, {}) || {};

  const totalUsers = Object.keys(ticketsByTenantUserId).length;

  const ticketsByMonth = Object.entries(
    organization?.tickets.reduce((acc: any, ticket: any) => {
      const month = new Date(ticket.createdAt).getMonth() + 1;
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {}) || {}
  ).map(([month, count]) => ({ month: Number(month), count: count as number }));

  const avgQAScore = useMemo(() => {
    if (!organization?.tickets || organization.tickets.length === 0) return 0;
    const { totalQAScore, ticketCount } = organization.tickets.reduce(
      (acc: any, ticket: any) => {
        if (ticket.ticketScore.length > 0) {
          const ticketQAScore = ticket.ticketScore.reduce(
            (scoreAcc: any, score: any) => {
              return scoreAcc + score.autoScore;
            },
            0
          );
          acc.totalQAScore += ticketQAScore;
          acc.ticketCount += 1;
        }
        return acc;
      },
      { totalQAScore: 0, ticketCount: 0 }
    );
    return ticketCount > 0 ? totalQAScore / ticketCount / 5 : 0;
  }, [organization?.tickets]);

  return (
    <Table.Row
      className={styles.row}
      onClick={() => navigate(`/customers/${organization.id}`)}
    >
      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
      >
        {organization.name}
      </Table.Cell>
      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
        justify="center"
      >
        {closedTickets} / {organization.ticketCount}
      </Table.Cell>
      <Table.Cell>
        <Chart data={ticketsByMonth} width={60} height={30} />
      </Table.Cell>
      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
      >
        {avgFullResolutionTimeBusinessString}
      </Table.Cell>
      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
      >
        {avgHandlingTimeBussinessString}
      </Table.Cell>
      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
      >
        {avgQAScore.toFixed(2)} / 5
      </Table.Cell>

      <Table.Cell
        style={{
          verticalAlign: "bottom",
        }}
      >
        {totalUsers} users
      </Table.Cell>
    </Table.Row>
  );
};
