import React, { ReactNode, Fragment } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export const PageHeadline = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <h1
      className={cx(styles.pageHeadline, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </h1>
  );
};

export const Headline = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <h3
      className={cx(styles.headLine, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </h3>
  );
};

export const TableBody = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.tableBody, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

export const TableLink = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.tableLink, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

export const TableButton = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.tableButton, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

export const TableTag = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.tableTag, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

export const Button = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.button, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

export const Tab = ({
  theme,
  children,
}: {
  theme: TTheme;
  children: ReactNode;
}) => {
  return (
    <label
      className={cx(styles.tab, {
        [styles["fontColor-primary"]]: theme === "primary",
        [styles["fontColor-secondary"]]: theme === "secondary",
        [styles["fontColor-disabled"]]:
          theme !== "secondary" && theme !== "primary",
      })}
    >
      {children}
    </label>
  );
};

type TTheme = "primary" | "secondary" | "disabled";
interface ITypography {
  type:
    | "page_headline"
    | "headline"
    | "table_button"
    | "table_link"
    | "table_body"
    | "table_tag"
    | "tab"
    | "button";
  theme: TTheme;
  children: ReactNode;
}
export const Typography = ({ type, theme, children }: ITypography) => {
  switch (type) {
    case "page_headline":
      return <PageHeadline theme={theme}>{children}</PageHeadline>;
    case "headline":
      return <Headline theme={theme}>{children}</Headline>;
    case "table_body":
      return <TableBody theme={theme}>{children}</TableBody>;
    case "table_button":
      return <TableButton theme={theme}>{children}</TableButton>;
    case "table_link":
      return <TableLink theme={theme}>{children}</TableLink>;
    case "tab":
      return <Tab theme={theme}>{children}</Tab>;
    case "button":
      return <Button theme={theme}>{children}</Button>;
    case "table_tag":
      return <TableTag theme={theme}>{children}</TableTag>;
    default:
      return <Fragment />;
  }
};
