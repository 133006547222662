import React from "react";

export const Sun = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="434"
      height="145"
      viewBox="0 0 434 145"
      fill="none"
    >
      <g filter="url(#filter0_f_748_17673)">
        <circle cx="248" cy="248" r="84" fill="#FED942" fillOpacity="0.3" />
      </g>
      <defs>
        <filter
          id="filter0_f_748_17673"
          x="0"
          y="0"
          width="496"
          height="496"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="82"
            result="effect1_foregroundBlur_748_17673"
          />
        </filter>
      </defs>
    </svg>
  );
};
