import React, { useMemo } from "react";

import { GDashboardTable } from "../../../components/geistTableDashboard";
import { EColumnTypes } from "../../../components/geistTableDashboard/index.types";
import { ETimesFilter } from "../../../assets/types";
import { usePerformance } from "../../../api/usePerformance";
import { useFetchingToasts } from "../../../hooks/useFetchingToasts";
import { TPerformanceByAgent } from "../index.type";

import styles from "./styles.module.scss";

const agentPerformanceColumns = [
  {
    value: "agentName",
    label: "Agent",
    type: EColumnTypes.DEFAULT,
  },
  {
    value: "ticketsCount",
    label: "Tickets",
    type: EColumnTypes.DEFAULT,
  },
  { label: "Avg. TTR", value: "avgResolutionTime", type: EColumnTypes.TIME },
  {
    label: "Avg. Response Time",
    value: "avgResponseTime",
    type: EColumnTypes.TIME,
  },
  {
    value: "avgEvaluationScore",
    label: "Evaluation",
    type: EColumnTypes.EVALUATION,
    dataOptions: {
      round: true,
    },
  },
  {
    value: "recentEvaluationScores",
    label: "Recent Evaluations",
    type: EColumnTypes.TRACK,
  },
];

export const Agents = ({
  timeFilter,
  topics,
}: {
  timeFilter: ETimesFilter;
  topics: string[];
}) => {
  const { data, isLoading, error } = usePerformance({
    enabled: true,
    timeFilter,
    topics,
    groupBy: "agent",
  });

  const performanceByAgent = useMemo(
    () =>
      data && Array.isArray(data)
        ? (data.map((item: any) => ({
            ...item,
            recentEvaluationScores: item.recentEvaluationScores.map(
              (score: { id: string; adjustedScore: number }) =>
                score.adjustedScore
            ),
          })) as TPerformanceByAgent[])
        : [],
    [data]
  );

  useFetchingToasts({
    error: !!error || data?.error,
    emptyState:
      data !== undefined && !isLoading && performanceByAgent.length === 0,
  });

  return (
    <div className={styles.container}>
      {performanceByAgent && (
        <GDashboardTable
          loading={isLoading}
          dataArray={performanceByAgent as unknown as Record<string, string>[]}
          idName="commonQuestionId"
          handleRowClick={(id: string) => {}}
          selectedTopic={20}
          columns={agentPerformanceColumns}
          headerBorder
          disableSelected
        />
      )}
    </div>
  );
};
