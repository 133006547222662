import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { IconType } from "react-icons/lib";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  AiOutlineAppstoreAdd,
  AiOutlineUsergroupAdd,
  AiFillSetting,
} from "react-icons/ai";

interface Tab {
  title: string;
  icon: IconType;
  enabled: boolean;
}

export const TabPanel = ({
  onClick,
  selectedTabIndex,
}: {
  onClick: Function;
  selectedTabIndex: number;
}) => {
  const flags = useFlags();

  const tabs: Tab[] = [
    { title: "Integrations", icon: AiOutlineAppstoreAdd, enabled: true },
    { title: "Members", icon: AiOutlineUsergroupAdd, enabled: true },
    {
      title: "Copilot config",
      icon: AiFillSetting,
      enabled: flags.copilotSettings,
    },
  ];

  const onSelectTab = (index: number) => {
    onClick(index);
  };
  return (
    <div className={cx(styles.dark_mode, styles.container)}>
      {tabs
        .filter((t) => t.enabled)
        .map((item, index) => {
          return (
            <div
              className={cx(styles.tab, {
                [styles.selected]: index === selectedTabIndex,
              })}
              key={index}
              onClick={() => onSelectTab(index)}
            >
              <item.icon />
              <div className={styles.title}>{item.title}</div>
            </div>
          );
        })}
    </div>
  );
};
