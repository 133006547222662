import { EBadgeMode } from "../../components/atoms/geistBadge";

export const badgeOptions = {
  "1": EBadgeMode.RED,
  "1.0": EBadgeMode.RED,
  "1.1": EBadgeMode.RED,
  "1.2": EBadgeMode.RED,
  "1.3": EBadgeMode.RED,
  "1.4": EBadgeMode.RED,
  "1.5": EBadgeMode.RED,
  "1.6": EBadgeMode.RED,
  "1.7": EBadgeMode.RED,
  "1.8": EBadgeMode.RED,
  "1.9": EBadgeMode.RED,
  "2.0": EBadgeMode.RED,
  "2": EBadgeMode.RED,
  "2.1": EBadgeMode.YELLOW,
  "2.2": EBadgeMode.YELLOW,
  "2.3": EBadgeMode.YELLOW,
  "2.4": EBadgeMode.YELLOW,
  "2.5": EBadgeMode.YELLOW,
  "2.6": EBadgeMode.YELLOW,
  "2.7": EBadgeMode.YELLOW,
  "2.8": EBadgeMode.YELLOW,
  "2.9": EBadgeMode.YELLOW,
  "3.0": EBadgeMode.YELLOW,
  "3": EBadgeMode.YELLOW,
  "3.1": EBadgeMode.YELLOW,
  "3.2": EBadgeMode.YELLOW,
  "3.3": EBadgeMode.YELLOW,
  "3.4": EBadgeMode.YELLOW,
  "3.5": EBadgeMode.YELLOW,
  "3.6": EBadgeMode.GREEN,
  "3.7": EBadgeMode.GREEN,
  "3.8": EBadgeMode.GREEN,
  "3.9": EBadgeMode.GREEN,
  "4.0": EBadgeMode.GREEN,
  "4": EBadgeMode.GREEN,
  "4.1": EBadgeMode.GREEN,
  "4.2": EBadgeMode.GREEN,
  "4.3": EBadgeMode.GREEN,
  "4.4": EBadgeMode.GREEN,
  "4.5": EBadgeMode.GREEN,
  "4.6": EBadgeMode.GREEN,
  "4.7": EBadgeMode.GREEN,
  "4.8": EBadgeMode.GREEN,
  "4.9": EBadgeMode.GREEN,
  "5.0": EBadgeMode.GREEN,
  "5": EBadgeMode.GREEN,
};
export const badgeBinary = {
  "0": EBadgeMode.RED,
  "0.0": EBadgeMode.RED,
  "0.1": EBadgeMode.RED,
  "0.2": EBadgeMode.RED,
  "0.3": EBadgeMode.RED,
  "0.4": EBadgeMode.RED,
  "0.5": EBadgeMode.YELLOW,
  "0.6": EBadgeMode.YELLOW,
  "0.7": EBadgeMode.YELLOW,
  "0.8": EBadgeMode.GREEN,
  "0.9": EBadgeMode.GREEN,
  "1.0": EBadgeMode.GREEN,
  "1": EBadgeMode.GREEN,
};
