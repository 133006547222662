import { useEffect, useState } from "react";
import cx from "classnames";
import { paragon } from "@useparagon/connect";
import { createToast } from "vercel-toast";
import Nango from "@nangohq/frontend";

import {
  Integration,
  IntegrationSetupStatus,
  IntegrationType,
} from "../../../../accountSetup/types";
import { useAddIntegration } from "../../../../accountSetup/api/useIntegrations";
import styles from "./styles.module.scss";
import { useAuth0 } from "@auth0/auth0-react";

const nango = new Nango({ publicKey: "7d3dd047-cdc9-4f26-ae76-a1a75565600a" });

export const Chat = ({
  token,
  meta,
  integration,
  refetch,
}: {
  token: string;
  meta?: any;
  integration?: Integration;
  refetch: () => void;
}) => {
  const [step] = useState(0);
  const { user } = useAuth0();
  const [isLoading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const [integratedChannels, setIntegratedChannels] = useState<string[]>([]);
  const { mutate: addIntegration } = useAddIntegration();

  const handleSave = (tempMeta: any) => {
    addIntegration(
      {
        name: "Messaging platform",
        type: IntegrationType.CHAT,
        status: IntegrationSetupStatus.COMPLETED,
        metadata: {
          ...tempMeta,
          channels: integratedChannels,
          provider: "nango",
        },
      },
      {
        onSuccess: async () => {
          refetch();
          // setStep(1);
          createToast(`Successfully integrated to Slack`, {
            type: "success",
            timeout: 3000,
          });
        },
      }
    );
  };

  const fetchConversations = async (
    arr: any[],
    cursor: string
  ): Promise<any[]> => {
    const data: any = await paragon.request(
      "slack",
      `/conversations.list?types=public_channel,private_channel&cursor=${cursor}`,
      {
        method: "GET",
        body: undefined,
        headers: undefined,
      }
    );

    if (data.ok) {
      if (data.response_metadata?.next_cursor) {
        return await fetchConversations(
          [...arr, ...data.channels],
          data.response_metadata?.next_cursor
        );
      } else {
        return [...arr, ...data.channels];
      }
    } else {
      return arr;
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    const data: any = await paragon.request(
      "slack",
      "/conversations.list?types=public_channel,private_channel",
      {
        method: "GET",
        body: undefined,
        headers: undefined,
      }
    );

    if (data.ok) {
      if (data.response_metadata?.next_cursor) {
        const array: any[] = await fetchConversations(
          data.channels,
          data.response_metadata?.next_cursor
        );

        setChannels(
          (array as any).sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1;
            } else {
              return 1;
            }
          })
        );
      }
    } else {
      // handle error
    }
    setLoading(false);
  };

  const handleChoose = async (ticketSystem: string) => {
    // await paragon.authenticate(params.PARAGON_PROJECT_ID, token ?? "");
    // paragon.connect(ticketSystem, {
    //   onSuccess: async (e) => {
    //     console.log("success", { e });
    //     // should send api call to add integration
    //     setTempMeta(e);
    //     const data: any = await paragon.request(
    //       "slack",
    //       "/conversations.list?types=public_channel,private_channel",
    //       {
    //         method: "GET",
    //         body: undefined,
    //         headers: undefined,
    //       }
    //     );

    //     if (data.ok) {
    //       setChannels(data.channels);
    //     } else {
    //       // handle error
    //     }
    //     setStep(1);
    //     createToast(`Successfully connected to ${ticketSystem}`, {
    //       type: "success",
    //       timeout: 3000,
    //     });
    //   },

    //   onError: (err) => {
    //     console.log("error", { err });

    //     createToast(err.message, {
    //       type: "error",
    //       timeout: 3000,
    //     });
    //   },
    //   onUninstall: (e) => {
    //     console.log("uninstall", { e });
    //     // should send api to delete the integration
    //     deleteIntegration(
    //       {},
    //       {
    //         onSuccess: () => {
    //           refetch();
    //           createToast("sorry to see you leave", {
    //             type: "dark",
    //             timeout: 3000,
    //           });
    //         },
    //       }
    //     );
    //   },
    //   onWorkflowChange: (e) => {
    //     console.log("workflow change", { e });
    //   },
    //   onOpen: (e) => {
    //     console.log("open", { e });
    //   },
    //   onClose: (e) => {
    //     console.log("close", { e });
    //     if (e) {
    //       setStep(1);
    //     }
    //   },
    // });
    nango
      .auth("slack", user?.owner)
      .then((result: { providerConfigKey: string; connectionId: string }) => {
        // do something

        // setData({ ...result, token: "true" });
        handleSave({
          integrationType: result.providerConfigKey,
          integrationId: result.connectionId,
        });
        // createToast(`Slack integration created!`, {
        //   type: "success",
        //   timeout: 3000, // in 3 seconds
        // });
      })
      .catch((err: { message: string; type: string }) => {
        console.log({ err });
        // handle error
      });
  };

  return (
    <div className={cx(styles.container)}>
      {step === 0 && (
        <>
          <main>
            <IntegrationButton
              text="Slack"
              imgSrc="/slack.png"
              onClick={() => {
                handleChoose("slack");
              }}
              isActive={meta?.integrationType === "slack"}
            />
          </main>
        </>
      )}
      {step === 1 && (
        <>
          <div className={styles.header}>
            <h4>Choose channels to integrate</h4>
            <button onClick={handleRefresh} disabled={isLoading}>
              {isLoading ? "Loading.." : "Refresh"}
            </button>
          </div>

          <div className={styles.channelListContainer}>
            {channels.map((c: { id: string; name: string }) => {
              return (
                <div
                  key={c.id}
                  className={cx(styles.channelItem, {
                    [styles.selected]: integratedChannels.includes(c.id),
                  })}
                  onClick={() => {
                    if (integratedChannels.includes(c.id)) {
                      setIntegratedChannels(
                        integratedChannels.filter((n) => n !== c.id)
                      );
                    } else {
                      setIntegratedChannels([...integratedChannels, c.id]);
                    }
                  }}
                >
                  {c.name}
                </div>
              );
            })}
          </div>
          <button onClick={handleSave}>Save</button>
        </>
      )}
    </div>
  );
};

const IntegrationButton = ({
  text,
  imgSrc,
  isActive = false,
  onClick,
}: {
  text: string;
  imgSrc: string;
  isActive?: boolean;
  onClick: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);

  const loadingToggle = () => setLoading((prev) => !prev);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        loadingToggle();
      }, 2000);
    }
  }, [isLoading]);

  return (
    <button
      className={cx(styles.integrationButton, { [styles.active]: isActive })}
      onClick={() => {
        loadingToggle();
        onClick();
      }}
    >
      {isLoading ? (
        "loading.."
      ) : (
        <>
          <img src={imgSrc} alt="integrate" />
          {text}
        </>
      )}
    </button>
  );
};
